import { createAction, props } from '@ngrx/store';
import {
  AppStoreDataModel,
  IConversation,
  IReminderInformation
} from '../../data/models';
import { StoreActionResultModel } from '@shopiroller/core';

/******************* Load App Store Data  ***********************/
export const loadAppStoreData = createAction(
  '[App Store] Load App Store Data',
  props<{ appId: string }>()
);

export const loadAppStoreDataSuccess = createAction(
  '[App Store]  Load App Store Data Success',
  props<{
    response: {
      appId: string;
      featuresData: AppStoreDataModel;
    };
  }>()
);

export const loadAppStoreDataFailure = createAction(
  '[App Store]  Load App Store Data Failure',
  props<{ error: any }>()
);

/******************* Update Cart Reminder  ***********************/
export const updateCartReminder = createAction(
  '[App Store] Update Cart Reminder ',
  props<{ appId: string; payload: IReminderInformation }>()
);

export const updateCartReminderSuccess = createAction(
  '[App Store]   Update Cart Reminder Success',
  props<{
    payload: {
      updatedCartReminder: any;
      result: StoreActionResultModel;
    };
  }>()
);

export const updateCartReminderFailure = createAction(
  '[App Store]   Update Cart Reminder Failure',
  props<{
    payload: {
      result: StoreActionResultModel;
    };
  }>()
);

/******************* activate  Whatsapp  ***********************/
export const activateWhatsapp = createAction(
  '[App Store] activate  Whatsapp',
  props<{ appId: string; payload: IConversation }>()
);

export const activateWhatsappSuccess = createAction(
  '[App Store]  activate  Whatsapp Success',
  props<{
    payload: {
      updatedWhatsapp: any;
      result: StoreActionResultModel;
    };
  }>()
);

export const activateWhatsappFailure = createAction(
  '[App Store]  activate  Whatsapp Failure',
  props<{
    payload: {
      result: StoreActionResultModel;
    };
  }>()
);

/******************* update  Whatsapp  ***********************/
export const updateWhatsapp = createAction(
  '[App Store] Update  Whatsapp',
  props<{ appId: string; conversationId: string; payload: any }>()
);

export const updateWhatsappSuccess = createAction(
  '[App Store]  Update  Whatsapp Success',
  props<{
    payload: {
      updatedWhatsapp: any;
      result: StoreActionResultModel;
    };
  }>()
);

export const updateWhatsappFailure = createAction(
  '[App Store]  Update  Whatsapp Failure',
  props<{
    payload: {
      result: StoreActionResultModel;
    };
  }>()
);
