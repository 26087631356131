import {
  ICountryPhoneCodeModel,
  IStoreInformationModel,
  StoreInformationModel
} from '../../data/models';
import { END_POINTS_URL } from '@shopiroller/data';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { REST_REQUEST_RESULT } from '@shopiroller/data';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  constructor(private http: HttpClient) {}

  public getStoreInfo(appId: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/store/info`;
    return this.http.get(URL).pipe(
      map((result: any) => {
        return result.data as StoreInformationModel;
      })
    );
  }

  public updateStoreInfo(
    appId: string,
    data: IStoreInformationModel
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/store/info`;
    return this.http.put(URL, data, { observe: 'response' }).pipe(
      map((result: any) => {
        if (
          result.status === 200 ||
          result.status === 201 ||
          result.status === 204
        ) {
          return REST_REQUEST_RESULT.SUCCESSFUL;
        }
        return REST_REQUEST_RESULT.FAILED;
      })
    );
  }

  public getCountryPhoneCodes(): Observable<ICountryPhoneCodeModel[]> {
    return this.http.get<ICountryPhoneCodeModel[]>(
      '../../../../../assets/country-phone-codes.json'
    );
  }
}
