import { Routes } from '@angular/router';
import {
  SubscriptionFeaturesEnum,
  PackageSubscriptionGuard
} from '@shopiroller/store/subscription';
import { FeatureGuard } from '@shopiroller/core';
import { featuresList } from '@shopiroller/data';

export const SALES_CHANNEL_ROUTES: Routes = [
  {
    path: 'list',
    loadComponent: async () =>
      (
        await import(
          '../features/sales-channel-list/sales-channel-list.component'
        )
      ).SalesChannelListComponent
  },
  {
    path: 'mobile',
    data: {
      subscriptionFeature:
        SubscriptionFeaturesEnum.saleschannel_mobileapp_active
    },
    canActivate: [PackageSubscriptionGuard],
    loadChildren: (): any =>
      import('../features/mobile/routes/mobile-sale-channel.routes').then(
        (m) => m.MOBILE_SALE_CHANNEL_ROUTES
      )
  },
  {
    path: 'shopilink',
    data: {
      subscriptionFeature:
        SubscriptionFeaturesEnum.saleschannel_shopilink_active
    },
    canActivate: [PackageSubscriptionGuard],
    loadChildren: (): any =>
      import('../features/shopilink/routes/shopilink.routes').then(
        (m) => m.SHOPILINK_SALE_CHANNEL_ROUTES
      )
  },
  {
    path: 'web',
    canActivate: [FeatureGuard],
    data: {
      feature: featuresList.webSaleChannel
    },
    loadChildren: (): any =>
      import('../features/web/routes/web.routes').then(
        (m) => m.WEB_SALE_CHANNEL_ROUTES
      )
  },
  {
    path: 'google',
    canActivate: [FeatureGuard],
    data: {
      feature: featuresList.googleShopping
    },
    loadChildren: (): any =>
      import('../features/google/routes/google-sale-channel.routes').then(
        (m) => m.GOOGLE_SALE_CHANNEL_ROUTES
      )
  },
  {
    path: 'magenty',
    canActivate: [FeatureGuard],
    data: {
      feature: featuresList.magenty
    },
    loadChildren: (): any =>
      import('../features/magenty/routes/magenty.routes').then(
        (m) => m.MAGENTY_SALE_CHANNEL_ROUTES
      )
  },
  {
    path: 'easy-collection',
    canActivate: [FeatureGuard],
    data: {
      feature: featuresList.easyCollection
    },
    loadChildren: (): any =>
      import('../features/easy-collection/routes/easy-collection.routes').then(
        (m) => m.EASY_COLLECTION_ROUTES
      )
  },
  {
    path: '**',
    redirectTo: 'list',
    pathMatch: 'full'
  }
];
