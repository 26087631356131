import { Routes } from '@angular/router';

export const CATEGORY_ROUTES: Routes = [
  {
    path: 'list',
    loadComponent: async () =>
      (await import('../features/categories/categories.component'))
        .CategoriesComponent
  },
  {
    path: ':parentCategoryId',
    loadComponent: async () =>
      (await import('../features/categories/categories.component'))
        .CategoriesComponent
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'list'
  }
];
