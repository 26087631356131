import { END_POINTS_URL } from '@shopiroller/data';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { IAddVariationGroupToProduct } from '../../variant/models/variant.model';
import { IProductPreview } from '../data/models/product-preview.model';
import { ICopyProduct } from '../data/models/copy-product.model';
import { ChangeProductPublishmentStatusModel } from '../data/models/change-product-publishment-status.model';
import { MultiLanguageSlugSuggestionModel } from '../../../data';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  constructor(private http: HttpClient) {}

  public getSingleProduct(appId: string, productId: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/products/${productId}`;
    return this.http.get<any[]>(URL).pipe(
      map((product) => {
        return product;
      })
    );
  }

  public getProductsList(appId: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/products?orderBy=OrderByDescending&orderByOption=OrderIndex`;
    return this.http.get(URL).pipe(
      map((result: any) => {
        return result.data;
      })
    );
  }

  public filterProducts(
    appId: string,
    searchedTitle: string = '',
    iso2LanguageName: string = '',
    currentPage: number,
    orderByType: 'OrderBy' | 'OrderByDescending' = 'OrderByDescending',
    orderByOption:
      | 'CalculatedPrice'
      | 'Title'
      | 'CategoryId'
      | 'BrandId'
      | 'CreateDate' = 'CreateDate',
    pageSize = 10,
    ...additionalFilters: string[]
  ): Observable<any> {
    let url = `${END_POINTS_URL.V2.APPS_URL}/${appId}/products?orderBy=${orderByType}&orderByOption=${orderByOption}&iso2LanguageName=${iso2LanguageName}&page=${currentPage}`;
    if (searchedTitle.trim() !== '') {
      url += `&title=${searchedTitle}`;
    }

    if (pageSize != 0) {
      url += `&perPage=${pageSize}`;
    }
    if (additionalFilters.length > 0) {
      url = url + '&' + additionalFilters.join('&');
    }
    return this.http.get<any>(url);
  }

  public addNewProduct(appId: string, newProduct: any): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/products`;
    return this.http.post(URL, newProduct).pipe(
      map((product) => {
        return product;
      })
    );
  }

  public addNewProductWithVariants(
    appId: string,
    newProduct: any
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/products/insertProductCombineVariant`;
    return this.http.post(URL, newProduct).pipe(
      map((product) => {
        return product;
      })
    );
  }

  public updateProduct(
    appId: string,
    productId: string,
    productForUpdate: any
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/products/${productId}`;
    return this.http.put(URL, productForUpdate).pipe(
      map(() => {
        return true;
      })
    );
  }

  public updateProductWithVariations(
    appId: string,
    payload: any
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/products/updateProductCombineVariant`;
    return this.http.put(URL, payload).pipe(
      map(() => {
        return true;
      })
    );
  }

  public deleteProduct(appId: string, productId: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/products/${productId}`;
    return this.http.delete(URL, { observe: 'response' }).pipe(
      map((result: any) => {
        return result.status === 200 ||
          result.status === 201 ||
          result.status === 204
          ? true
          : false;
      })
    );
  }

  public addVariationGroupToProduct(payload: IAddVariationGroupToProduct) {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${payload.appId}/products/${payload.productId}/variation-groups/${payload.variationGroupId}`;

    return this.http.post(URL, {}, { observe: 'response' }).pipe(
      map((product) => {
        return product;
      })
    );
  }

  public addVariationToProduct(
    appId: string,
    productId: string,
    payload: any
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/products/${productId}/variants`;

    return this.http.post(URL, payload).pipe(
      map((product) => {
        return product;
      })
    );
  }

  public getVariantsOfProduct(
    appId: string,
    productId: string
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/products/${productId}/variants`;

    return this.http.get(URL);
  }

  public deleteVariantFromProduct(
    appId: string,
    payload: any
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/products/${payload.id}/variants/${payload.variationGroupId}/${payload.variationId}`;
    return this.http.delete(URL);
  }

  public deleteVariationGroupFromProduct(
    appId: string,
    payload: any
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/products/${payload.id}/variation-groups/${payload.variationGroupId}`;
    return this.http.delete(URL);
  }

  public exportProducts(appId: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/products/exports`;
    return this.http.get(URL, {
      headers: {
        'content-type': 'application/json'
      }
    });
  }

  public exportProductsAsBlob(appId: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/products/export-products`;
    return this.http.get(URL, { responseType: 'blob' });
  }

  public importProducts(appId: string, file: any): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/products/import-products`;
    return this.http.post(URL, file);
  }

  public generateSlugSuggestion(
    appId: string,
    payload: MultiLanguageSlugSuggestionModel
  ): Observable<any> {
    const url = `${END_POINTS_URL.V2.APPS_URL}/${appId}/products/slugs`;
    return this.http.post(url, payload).pipe(map((result: any) => result.data));
  }

  public copy(payload: ICopyProduct): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${payload.appId}/products/${payload.productId}/copy`;
    return this.http.post(URL, payload).pipe(map((result: any) => result.data));
  }

  public preview(payload: IProductPreview): Observable<any> {
    const url = `${END_POINTS_URL.V2.APPS_URL}/${payload.appId}/products/${payload.productId}/preview`;
    return this.http.get(url).pipe(map((result: any) => result.data));
  }

  public changePublishmentStatus(
    payload: ChangeProductPublishmentStatusModel
  ): Observable<any> {
    const url = `${END_POINTS_URL.V2.APPS_URL}/${payload.appId}/products/${payload.productId}`;
    return this.http.patch(url, {
      isPublished: payload.isPublished
    });
  }
}
