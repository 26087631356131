import { AvatarComponent } from './avatar.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RandomSymbolColorDirectiveModule } from '@shopiroller/directives';

@NgModule({
  declarations: [AvatarComponent],
  imports: [CommonModule,RandomSymbolColorDirectiveModule],
  exports: [AvatarComponent],
})
export class AvatarModule {}
