import { Pipe, PipeTransform } from '@angular/core';

import { environment } from 'src/environments/environment';

@Pipe({
  name: 'topLevelDomain',
  standalone: true
})
export class TopLevelDomainPipe implements PipeTransform {
  private _TOP_LEVEL_DOMAIN = environment.panelBaseURL;
  transform(value: string): string {
    return value.includes(this._TOP_LEVEL_DOMAIN)
      ? `https://${value}`
      : value[value.length - 1] == '.'
      ? `https://${value}${this._TOP_LEVEL_DOMAIN}`
      : `https://${value}.${this._TOP_LEVEL_DOMAIN}`;
  }
}
