import { createAction, props } from '@ngrx/store';

import { ClientListModel } from '../../data/models/store.model';
import { GetClientListModel } from '../../data/models';

/******************* Load Client List Data  ***********************/
export const loadClientList = createAction(
  '[Client] load Client List',
  props<{ payload: GetClientListModel }>()
);

export const loadClientListSuccess = createAction(
  '[Client] load Client List Success',
  props<{ response: ClientListModel }>()
);

export const loadClientListFailure = createAction(
  '[Client] load Client List  Failure',
  props<{ error: any }>()
);
