import { GenericState, GenericStoreStatus } from '@shopiroller/core';
import { createReducer, on } from '@ngrx/store';
import {
  changeActivityOfSaleChannel,
  changeActivityOfSaleChannelFailure,
  changeActivityOfSaleChannelSuccess,
  connectMagentySaleChannel,
  connectMagentySaleChannelFailure,
  connectMagentySaleChannelSuccess,
  loadEasyCollectionSaleChannelData,
  loadEasyCollectionSaleChannelDataFailure,
  loadEasyCollectionSaleChannelDataSuccess,
  loadMagentySaleChannelData,
  loadMagentySaleChannelDataFailure,
  loadMagentySaleChannelDataSuccess,
  loadMobileChannelCategoryDisplayModel,
  loadMobileChannelCategoryDisplayModelFailure,
  loadMobileChannelCategoryDisplayModelSuccess,
  loadMobileSaleChannelData,
  loadMobileSaleChannelDataFailure,
  loadMobileSaleChannelDataSuccess,
  loadShopilinkSaleChannelData,
  loadShopilinkSaleChannelDataFailure,
  loadShopilinkSaleChannelDataSuccess,
  loadWebSaleChannelData,
  loadWebSaleChannelDataFailure,
  loadWebSaleChannelDataSuccess,
  loadWebsettingData,
  loadWebsettingDataFailure,
  loadWebsettingDataSuccess,
  updateMobileSaleChannel,
  updateMobileSaleChannelFailure,
  updateMobileSaleChannelSuccess,
  updateSaleChannel,
  updateSaleChannelSuccess
} from './sales-channel.actions';

export const salesChannelFeatureKey = 'feature-salesChannel';

export class salesChannelFeatureState implements GenericState<any> {
  appId!: string;
  web: any;
  mobile: any;
  shopilink: any;
  google: any;
  magenty: any;
  easyCollection: any;
  status: GenericStoreStatus | any = 'pending';
  error: any = null;
}

const initialState: salesChannelFeatureState = {
  appId: '',
  web: null,
  mobile: null,
  shopilink: null,
  google: null,
  magenty: null,
  easyCollection: null,
  error: null,
  status: 'pending'
};

export const salesChannelFeatureReducer = createReducer(
  initialState,
  on(loadWebSaleChannelData, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(loadWebSaleChannelDataSuccess, (state, { response }) => {
    return {
      ...state,
      appId: response.appId,
      web: response.webSaleChannelData,
      status: 'success',
      error: null
    };
  }),
  on(loadWebSaleChannelDataFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(loadMobileSaleChannelData, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(loadMobileSaleChannelDataSuccess, (state, { response }) => {
    if (!response || response.mobileSaleChannelData == null) {
      return state;
    }
    return {
      ...state,
      appId: response.appId,
      mobile: response.mobileSaleChannelData,
      status: 'success',
      error: null
    };
  }),
  on(loadMobileSaleChannelDataFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(loadShopilinkSaleChannelData, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(loadShopilinkSaleChannelDataSuccess, (state, { response }) => {
    return {
      ...state,
      appId: response.appId,
      shopilink: response.shopilinkSaleChannelData,
      status: 'success',
      error: null
    };
  }),
  on(loadShopilinkSaleChannelDataFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(loadWebsettingData, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(loadWebsettingDataSuccess, (state, { response }) => {
    return {
      ...state,
      appId: response.appId,
      webSettings: response,
      status: 'success',
      error: null
    };
  }),
  on(loadWebsettingDataFailure, (state, { error }) => ({
    ...state,
    webSettings: null,
    status: 'error',
    error: error
  })),
  on(changeActivityOfSaleChannel, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(changeActivityOfSaleChannelSuccess, (state, { payload }) => {
    let webChannelInfo = state.web;
    let mobileChannelInfo = Object.assign([], state.mobile);
    let shopilinkChannelInfo = Object.assign([], state.shopilink);
    let easyCollectionChannelInfo = Object.assign([], state.easyCollection);
    if (payload.channelType == 'Web') {
      webChannelInfo = payload.updatedChannelInfo;
    }
    if (payload.channelType == 'Mobile') {
      const mobileSalesChannelIndex = mobileChannelInfo.findIndex(
        (x) => x.platformType == 'None'
      );
      mobileChannelInfo[mobileSalesChannelIndex] = payload.updatedChannelInfo;
    }
    if (payload.channelType == 'Shopilink') {
      const shopilinkSalesChannelIndex = shopilinkChannelInfo.findIndex(
        (x) => x.platformType == 'None'
      );
      shopilinkChannelInfo[shopilinkSalesChannelIndex] =
        payload.updatedChannelInfo;
    }
    if (payload.channelType == 'Collection') {
      easyCollectionChannelInfo = payload.updatedChannelInfo;
    }
    return {
      ...state,
      web: webChannelInfo,
      mobile: mobileChannelInfo,
      shopilink: shopilinkChannelInfo,
      easyCollection: easyCollectionChannelInfo,
      status: 'success',
      error: null
    };
  }),
  on(changeActivityOfSaleChannelFailure, (state, { payload }) => {
    return {
      ...state,
      status: 'error',
      error: payload.result.message
    };
  }),
  on(updateSaleChannel, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(updateSaleChannelSuccess, (state, { payload }) => {
    return {
      ...state,
      status: 'success',
      error: null
    };
  }),
  on(changeActivityOfSaleChannelFailure, (state, { payload }) => {
    return {
      ...state,
      status: 'error',
      error: payload.result.message
    };
  }),
  on(updateMobileSaleChannel, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(updateMobileSaleChannelSuccess, (state, { payload }) => {
    return {
      ...state,
      status: 'success',
      error: null
    };
  }),
  on(updateMobileSaleChannelFailure, (state, { payload }) => {
    return {
      ...state,
      status: 'error',
      error: payload.result.message
    };
  }),
  on(loadMobileChannelCategoryDisplayModel, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(loadMobileChannelCategoryDisplayModelSuccess, (state, { payload }) => {
    const mobileChannelInfo = Object.assign([], state.mobile);
    const mobileSalesChannelIndex = mobileChannelInfo.findIndex(
      (x) => x.platformType == 'None'
    );
    mobileChannelInfo[mobileSalesChannelIndex] = {
      ...mobileChannelInfo[mobileSalesChannelIndex],
      categoryDisplayType: payload.categoryDisplayModel
    };
    return {
      ...state,
      mobile: mobileChannelInfo,
      status: 'success',
      error: null
    };
  }),
  on(loadMobileChannelCategoryDisplayModelFailure, (state, { payload }) => {
    return {
      ...state,
      status: 'error',
      error: payload.result.message
    };
  }),
  on(loadShopilinkSaleChannelData, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(loadShopilinkSaleChannelDataSuccess, (state, { response }) => {
    if (!response) state;
    return {
      ...state,
      appId: response.appId,
      shopilink: response.shopilinkSaleChannelData,
      status: 'success',
      error: null
    };
  }),
  on(loadShopilinkSaleChannelDataFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(loadShopilinkSaleChannelData, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(loadShopilinkSaleChannelDataSuccess, (state, { response }) => {
    return {
      ...state,
      appId: response.appId,
      shopilink: response.shopilinkSaleChannelData,
      status: 'success',
      error: null
    };
  }),
  on(loadShopilinkSaleChannelDataFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(connectMagentySaleChannel, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(connectMagentySaleChannelSuccess, (state, { payload }) => {
    return {
      ...state,
      appId: payload.response.appId,
      magenty: payload.response.magentyData,
      status: 'success',
      error: null
    };
  }),
  on(connectMagentySaleChannelFailure, (state, { payload }) => ({
    ...state,
    status: 'error',
    error: payload?.result?.message
  })),
  on(loadMagentySaleChannelData, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(loadMagentySaleChannelDataSuccess, (state, { response }) => {
    return {
      ...state,
      appId: response.appId,
      magenty: response.channelData,
      status: 'success',
      error: null
    };
  }),
  on(loadMagentySaleChannelDataFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(loadEasyCollectionSaleChannelData, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(loadEasyCollectionSaleChannelDataSuccess, (state, { response }) => {
    return {
      ...state,
      appId: response.appId,
      easyCollection: response.channelData,
      status: 'success',
      error: null
    };
  }),
  on(loadEasyCollectionSaleChannelDataFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  }))
);
