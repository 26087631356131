<div
  [ngClass]="
    imageContainerCssClasses == '' ? 'symbol mr-4' : imageContainerCssClasses
  "
>
  <img
    *ngIf="imageIsEmpty(productImage); else showImageSection"
    [ngClass]="imageCssClasses"
    class="w-auto"
    src="../../../../../assets/media/bg/default-no-image.png"
    alt="Shopiroller"
  />
  <ng-template #showImageSection>
    <img
      src="{{ productImage.t }}"
      [ngClass]="imageCssClasses"
      class="w-auto"
      alt="Shopiroller"
    />
  </ng-template>
</div>
