import { Routes } from '@angular/router';

export const CLIENT_ROUTES: Routes = [
  {
    path: 'list',
    loadComponent: async () =>
      (await import('../features/client-list/client-list.component'))
        .ClientListComponent
  },
  {
    path: 'client-detail/:clientId',
    loadComponent: async () =>
      (await import('../features/client-detail/client-detail.component'))
        .ClientDetailComponent
  },
  {
    path: '**',
    redirectTo: 'list',
    pathMatch: 'full'
  }
];
