import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

 import { Injectable } from '@angular/core';
import { ISalesChannelActiveness } from '@shopiroller/store/sales-channel';

@Injectable({
  providedIn: 'root',
})
export class DataBusService {
  //TODO:this part should be handled by NGRX in the future.
  public userFullName$: Observable<string>;
  public userFullNameSubject: BehaviorSubject<string>;

  public salesChannelActiveness$: Observable<any>;
  public salesChannelActivenessSubject: BehaviorSubject<ISalesChannelActiveness>;

  public currentAppUnreadOrdersCount$: Observable<number>;
  public currentAppUnreadOrdersCountSubject: BehaviorSubject<number>;

  constructor() {
    this.userFullNameSubject = new BehaviorSubject<string>('');
    this.userFullName$ = this.userFullNameSubject.asObservable();
    this.salesChannelActivenessSubject =
      new BehaviorSubject<ISalesChannelActiveness>({
        mobile: false,
        shopilink: false,
        web: false,
      });
    this.salesChannelActiveness$ =
      this.salesChannelActivenessSubject.asObservable();

    this.currentAppUnreadOrdersCountSubject = new BehaviorSubject<number>(0);
    this.currentAppUnreadOrdersCount$ =
      this.currentAppUnreadOrdersCountSubject.asObservable();
  }

  public updateUserFullName(userFullName: string): void {
    this.userFullNameSubject.next(userFullName);
  }

  public updateSalesChannel(salesChannel: ISalesChannelActiveness): void {
    this.salesChannelActivenessSubject.next(salesChannel);
  }

  public updateUnreadOrdersCount(count: number): void {
    this.currentAppUnreadOrdersCountSubject.next(count);
  }
}
