import {
  IWebSettingModel,
  WebSettingPartialUpdateModel
} from '../../features/web/data/models/web-setting-cu-model';

import { END_POINTS_URL } from '@shopiroller/data';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class WebSettingService {
  private readonly WEB_SETTINGS_BASE_URL = `${END_POINTS_URL.V2.WEB_SETTINGS}`;
  constructor(private httpClient: HttpClient) {}

  public getWebSettings(appId: string): Observable<IWebSettingModel> {
    const URL = `${this.WEB_SETTINGS_BASE_URL}/${appId}`;
    return this.httpClient.get<IWebSettingModel>(URL);
  }

  public addWebSettings(
    appId: string,
    payload: IWebSettingModel
  ): Observable<any> {
    const URL = `${this.WEB_SETTINGS_BASE_URL}/${appId}`;
    return this.httpClient.post(URL, payload);
  }

  public updateWebSettings(
    appId: string,
    payload: IWebSettingModel
  ): Observable<any> {
    const URL = `${this.WEB_SETTINGS_BASE_URL}/${appId}`;
    return this.httpClient.put(URL, payload);
  }

  public updateWebSettingsPartially(
    appId: string,
    payload: WebSettingPartialUpdateModel
  ): Observable<any> {
    const URL = `${this.WEB_SETTINGS_BASE_URL}/${appId}`;
    return this.httpClient.patch(URL, payload);
  }


  public suggestDomain(storeName: string): Observable<string> {
    const URL = `${this.WEB_SETTINGS_BASE_URL}/suggestion/domains/${storeName}`;
    return this.httpClient.get(URL).pipe(
      map((result: any) => {
        return result.data as string;
      })
    );
  }
}
