import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { END_POINTS_URL } from '@shopiroller/data';

@Injectable({
  providedIn: 'root'
})
export class MagentyService {
  constructor(private _http: HttpClient) {}

  connect(appId: string, payload: any): Observable<any> {
    const URL = `${END_POINTS_URL.V2.INTEGRATION_URL}/apps/${appId}/magenty/connect`;
    return this._http.post(URL, payload);
  }
}
