import { Routes } from '@angular/router';

export const APPS_STORE: Routes = [
  {
    path: 'app-store',
    loadChildren: async () =>
      (await import('../features/app-store/routes/app-store.routes'))
        .APP_STORE_ROUTES
  },
  {
    path: 'my-apps',
    loadChildren: async () =>
      (await import('../features/my-apps/routes/my-apps.routes')).MY_APPS_ROUTES
  },
  {
    path: '**',
    redirectTo: 'app-store'
  }
];
