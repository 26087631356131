import { AppConfigurationService } from '@shopiroller/core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GoogleAuthService {
  // public fields
  public gapiSetup = false; // marks if the gapi library has been loaded
  public authInstance: gapi.auth2.GoogleAuth;
  public error: string;
  public user: gapi.auth2.GoogleUser;

  constructor(private appConfigurationService: AppConfigurationService) {}

  public async initGoogleAuth(): Promise<void> {
    const pload = new Promise((resolve) => {
      gapi.load('auth2', resolve);
    });

    // When the first promise resolves, it means we have gapi loaded
    // and that we can call gapi.init
    const client_id =
      this.appConfigurationService.configurations['googleAuthApi'].clientId;
    return pload.then(async () => {
      await gapi.auth2.init({ client_id: client_id }).then((auth) => {
        this.gapiSetup = true;
        this.authInstance = auth;
      });
    });
  }

  public async authenticate(): Promise<gapi.auth2.GoogleUser> {
    if (!this.gapiSetup) {
      await this.initGoogleAuth();
    }
    return await this.authInstance.signIn();
  }

  public async checkIfUserAuthenticated(): Promise<boolean> {
    // Initialize gapi if not done yet
    if (!this.gapiSetup) {
      await this.initGoogleAuth();
    }
    return this.authInstance.isSignedIn.get();
  }
}
