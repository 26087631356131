import { Routes } from '@angular/router';

export const WEB_SALE_CHANNEL_ROUTES: Routes = [
  {
    path: '',
    loadComponent: async () =>
      (
        await import(
          '../features/sales-channel-web-index/sales-channel-web-index.component'
        )
      ).SalesChannelWebIndexComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    loadComponent: async () =>
      (
        await import(
          '../features/sales-channel-web-index/sales-channel-web-index.component'
        )
      ).SalesChannelWebIndexComponent
  }
];
