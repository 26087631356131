import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Store } from '@ngrx/store';
import { selectAppWebhookActivness } from '@shopiroller/stores';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
 
@Injectable({
  providedIn: 'root'
})
export class WebhookGuard implements CanActivate {
  constructor(private _store: Store, private _router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this._store.select(selectAppWebhookActivness).pipe(
      map((isActive) => {
        const dashboardUrl = '/store/dashboard';
        if (isActive === null || !isActive) {
          this._router.navigateByUrl(dashboardUrl);
          return false;
        }
        return true;
      })
    );
  }
}
