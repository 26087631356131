import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

import { ImageUploaderService } from '../image-uploader/image-uploader.service';
import { environment } from 'src/environments/environment';
import { AppSettingService } from '@shopiroller/core';

@Component({
  selector: 'app-custom-editor[id][languageIso2]',
  templateUrl: './custom-editor.component.html',
  styleUrls: ['./custom-editor.component.scss']
})
export class CustomEditorComponent {
  @Input()
  id!: string;

  @Input()
  languageIso2!: string;

  @Input()
  public editorContent!: string | null;

  @Output()
  public contentChanged = new EventEmitter<any>();

  public isLoading = false;

  private _appId: string;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _imageUploaderService: ImageUploaderService,
    private _appSettingService: AppSettingService
  ) {
    this._appId = this._appSettingService.getCurrentAppId();
  }

  public async handleTextChange(event: any) {
    try {
      const operations = event.delta.ops;
      for (let i = 0; i < operations.length; i++) {
        const op = operations[i];
        const opType = Object.keys(op)[0] as any;
        if (opType == 'insert' && op['insert'].hasOwnProperty('image')) {
          this.isLoading = true;
          const uploadedImageURL = await this.uploadImage(op['insert'].image);
          this._cdr.detectChanges();
          const newContent = event.htmlValue.replace(
            op['insert'].image,
            uploadedImageURL
          );
          this.editorContent = newContent;
        }
      }
      this.contentChanged.emit({
        id: this.id,
        isO2: this.languageIso2,
        content: this.editorContent
      });
    } catch (error) {
      this.editorContent = this.editorContent + '<br/><br/>';
    } finally {
      this.isLoading = false;
      this._cdr.detectChanges();
    }
  }

  async uploadImage(dataURL: string): Promise<string> {
    const ImageData: FormData = new FormData();
    let fileType = dataURL.substring(
      dataURL.indexOf(':') + 1,
      dataURL.lastIndexOf(';')
    );
    let fileExtension = fileType.split('/');
    const fileName = `file${new Date().getTime()}.${fileExtension[1]}`;

    const res: Response = await fetch(dataURL);
    const blob: Blob = await res.blob();
    const file = new File([blob], fileName, { type: fileType });

    ImageData.append('file', file);

    const containerName = environment.storageContainerName;
    const uploadImageResult = await this._imageUploaderService
      .uploadedImage(this._appId, containerName, ImageData)
      .toPromise();

    return environment.fileCDNBaseURL + uploadImageResult.absolutePath;
  }
}
