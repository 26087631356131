import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

import { END_POINTS_URL } from '@shopiroller/data';
import { HttpClient } from '@angular/common/http';
import { IAddLanguage } from '@shopiroller/i18n';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public preSelectedLanguages$: Observable<any>;
  private preSelectedLanguagesSubject: BehaviorSubject<any>;

  constructor(private http: HttpClient) {
    this.preSelectedLanguagesSubject = new BehaviorSubject<any>(null);
    this.preSelectedLanguages$ =
      this.preSelectedLanguagesSubject.asObservable();
  }

  public setPreSelectedLanguages(lang: any): void {
    this.preSelectedLanguagesSubject.next(lang);
  }

  public getLanguageList(): Observable<any> {
    const url = `${END_POINTS_URL.V2_1.APPS_URL}/getLanguages`;
    return this.http.get(url).pipe(
      map((result: any) => {
        result.data = result.data.map((lang) => {
          return {
            isO2: lang.value.toLowerCase(),
            displayName: lang.title,
            id: lang.id
          };
        });

        return result;
      })
    );
  }

  public getLanguageListOfApp(appId: string): Observable<any> {
    const url = `${END_POINTS_URL.V2_1.APPS_URL}/getAppLanguages/${appId}`;
    return this.http.get(url).pipe(
      map((result: any) => {
        const defaultLanguage = result.data.find((x) => x.isDefault == true);
        const finalResult = Object.assign({}, result);
        finalResult.data = {};
        finalResult.data.defaultLanguage =
          defaultLanguage == undefined
            ? null
            : this._transformLanguageItem(defaultLanguage);
        finalResult.data.supportedLanguages = result.data.map((lang) =>
          this._transformLanguageItem(lang)
        );
        return finalResult;
      })
    );
  }

  public addNewLanguage(appId: string, data: IAddLanguage): Observable<any> {
    const url = `${END_POINTS_URL.V2_1.APPS_URL}/addLanguage/${appId}`;
    return this.http.post(url, data);
  }

  public deleteLanguageFromApp(
    appId: string,
    languageId: number
  ): Observable<any> {
    const url = `${END_POINTS_URL.V2_1.APPS_URL}/deleteLanguages/${appId}/${languageId}`;
    return this.http.delete(url);
  }

  public changeDefaultLanguageOfApp(
    appId: string,
    newDefaultLanguageId: number
  ): Observable<any> {
    const url = `${END_POINTS_URL.V2_1.APPS_URL}/changeDefaultLanguage/${appId}?defaultLanguageId=${newDefaultLanguageId}`;
    return this.http.put(url, {});
  }

  public getDefaultLanguageOfApp(appId: string): Observable<any> {
    const url = `${END_POINTS_URL.V2_1.APPS_URL}/getAppLanguages/${appId}`;
    return this.http.get(url).pipe(
      map((result: any) => {
        const defaultLanguage = result.data.find((x) => x.isDefault == true);
        return {
          displayName: defaultLanguage.name,
          id: defaultLanguage.languageId,
          isO2: defaultLanguage.code.toLowerCase(),
          isDefault: defaultLanguage.isDefault
        };
      })
    );
  }

  private _transformLanguageItem(lang): any {
    return {
      displayName: lang.name,
      id: lang.languageId,
      isO2: lang.code.toLowerCase(),
      isDefault: lang.isDefault
    };
  }
}
