import { GenericState, GenericStoreStatus } from '@shopiroller/core';
import { createReducer, on } from '@ngrx/store';
import {
  addNewRegion,
  addNewRegionFailure,
  addNewRegionSuccess,
  addSlide,
  addSlideFailure,
  addSlideSuccess,
  addSlider,
  addSliderFailure,
  addSliderSuccess,
  deleteRegion,
  deleteRegionFailure,
  deleteRegionSuccess,
  deleteSlide,
  deleteSlideFailure,
  deleteSlideSuccess,
  loadListOfSliders,
  loadListOfSlidersFailure,
  loadListOfSlidersSuccess,
  loadStoreSettingInformation,
  loadStoreSettingInformationFailure,
  loadStoreSettingInformationSuccess,
  updateExisitingRegion,
  updateExisitingSlide,
  updateExisitingSlideFailure,
  updateExisitingSlideSuccess,
  updateExistingRegionFailure,
  updateExistingRegionSuccess,
  updatePaymentInformation,
  updatePaymentInformationFailure,
  updatePaymentInformationSuccess,
  updateShippingInformation,
  updateShippingInformationFailure,
  updateShippingInformationSuccess,
  updateStoreInformation,
  updateStoreInformationFailure,
  updateStoreInformationSuccess
} from './store-settings.actions';
import { StoreSettingsModel } from '../../data/models/store-settings.model';

export const storeSettingsFeatureKey = 'feature-store-settings';

export class storeSettingsFeatureState implements GenericState<any> {
  appId: string;
  data: StoreSettingsModel;
  status: GenericStoreStatus = 'pending';
  error: any = null;
}

const initialState: storeSettingsFeatureState = {
  appId: '',
  data: {
    info: null,
    countries: null,
    languages: null,
    currencies: null,
    sectors: null,
    shipping: null,
    regions: null,
    sliders: null
  },
  error: null,
  status: 'pending'
};

export const StoreSettingsFeatureReducer = createReducer(
  initialState,
  on(loadStoreSettingInformation, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(loadStoreSettingInformationSuccess, (state, { response }) => {
    return {
      ...state,
      appId: response.appId,
      data: response.data,
      status: 'success',
      error: null
    };
  }),
  on(loadStoreSettingInformationFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(updateStoreInformation, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(updateStoreInformationSuccess, (state, { payload }) => ({
    // todo: update state
    ...state,
    status: 'success'
  })),
  on(updateStoreInformationFailure, (state, { payload }) => ({
    ...state,
    status: 'error',
    error: payload.result.message
  })),
  on(updatePaymentInformation, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(updatePaymentInformationSuccess, (state) => ({
    ...state,
    status: 'success'
  })),
  on(updatePaymentInformationFailure, (state, { payload }) => ({
    ...state,
    status: 'error',
    error: payload.result.message
  })),
  on(updateShippingInformation, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(updateShippingInformationSuccess, (state, { payload }) => {
    const currentAppData = Object.assign({}, state.data);
    if (currentAppData) {
      currentAppData.shipping = payload.updatedShippingInformation;
    }
    return {
      ...state,
      data: currentAppData,
      status: 'success'
    };
  }),
  on(updateShippingInformationFailure, (state, { payload }) => ({
    ...state,
    status: 'error',
    error: payload.result.message
  })),
  on(addNewRegion, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(addNewRegionSuccess, (state, { payload }) => {
    const currentAppData = Object.assign({}, state.data);
    if (currentAppData) {
      const regions = [...currentAppData.regions];
      regions.push(payload.addedRegion);
      currentAppData.regions = regions;
    }
    return {
      ...state,
      data: currentAppData,
      status: 'success'
    };
  }),
  on(addNewRegionFailure, (state, { payload }) => ({
    ...state,
    status: 'error',
    error: payload.result.message
  })),
  on(updateExisitingRegion, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(updateExistingRegionSuccess, (state, { payload }) => {
    const currentAppData = Object.assign({}, state.data);
    if (currentAppData) {
      const regions = [...currentAppData.regions];
      const updatedItemIndex = currentAppData.regions.findIndex(
        (x) => x.id == payload.updatedRegion.id
      );
      regions[updatedItemIndex] = payload.updatedRegion;
      currentAppData.regions = regions;
    }
    return {
      ...state,
      data: currentAppData,
      status: 'success'
    };
  }),
  on(updateExistingRegionFailure, (state, { payload }) => ({
    ...state,
    status: 'error',
    error: payload.result.message
  })),
  on(deleteRegion, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(deleteRegionSuccess, (state, { payload }) => {
    const currentAppData = Object.assign({}, state.data);
    if (currentAppData) {
      currentAppData.regions = currentAppData.regions.filter(
        (x) => x.id !== payload.deletedRegionId
      );
    }
    return {
      ...state,
      data: currentAppData,
      status: 'success'
    };
  }),
  on(deleteRegionFailure, (state, { payload }) => ({
    ...state,
    status: 'error',
    error: payload.result.message
  })),
  on(loadListOfSliders, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(loadListOfSlidersSuccess, (state, { sliders }) => {
    const currentAppData = Object.assign({}, state.data);
    if (currentAppData) {
      currentAppData.sliders = sliders;
    }
    return {
      ...state,
      data: currentAppData,
      status: 'success'
    };
  }),
  on(loadListOfSlidersFailure, (state, { payload }) => ({
    ...state,
    status: 'error',
    error: payload.result.message
  })),
  on(addSlider, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(addSliderSuccess, (state, { newlyAddedSlider }) => {
    const currentAppData = Object.assign({}, state.data);
    if (currentAppData) {
      currentAppData.sliders = newlyAddedSlider;
    }
    return {
      ...state,
      data: currentAppData,
      status: 'success'
    };
  }),
  on(addSliderFailure, (state, { payload }) => ({
    ...state,
    status: 'error',
    error: payload.result.message
  })),
  on(addSlide, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(addSlideSuccess, (state, { payload }) => {
    const currentAppData = Object.assign({}, state.data);
    if (currentAppData) {
      currentAppData.sliders = [payload.sliderWithLastChanges];
    }
    return {
      ...state,
      data: currentAppData,
      status: 'success'
    };
  }),
  on(addSlideFailure, (state, { payload }) => ({
    ...state,
    status: 'error',
    error: payload.result.message
  })),
  on(updateExisitingSlide, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(updateExisitingSlideSuccess, (state, { payload }) => {
    const currentAppData = Object.assign({}, state.data);
    if (currentAppData) {
      currentAppData.sliders = [payload.sliderWithLastChanges];
    }
    return {
      ...state,
      data: currentAppData,
      status: 'success'
    };
  }),
  on(updateExisitingSlideFailure, (state, { payload }) => ({
    ...state,
    status: 'error',
    error: payload.result.message
  })),
  on(deleteSlide, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(deleteSlideSuccess, (state, { payload }) => {
    const currentAppData = Object.assign({}, state.data);
    if (currentAppData) {
      const slider = Object.assign([], currentAppData.sliders);
      currentAppData.sliders = [
        {
          ...slider[0],
          slides: slider[0].slides.filter((x) => x.id !== payload.slideId)
        }
      ];
    }
    return {
      ...state,
      data: currentAppData,
      status: 'success'
    };
  }),
  on(deleteSlideFailure, (state, { payload }) => ({
    ...state,
    status: 'error',
    error: payload.result.message
  }))
);
