import { conversationTypes } from '../types/conversation-type';

export interface IConversation {
  id?: string;
  accountName: string;
  title: string;
  type: conversationTypes;
  properties: {
    eCommerceButton: boolean;
    isOnline: boolean;
    apiKey: string;
    accountNumber?: string;
    countryPhoneCode?:string;
    whatsAppWebIsActive: boolean;
    whatsAppMobileIsActive: boolean;
  };
}
