import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { finalize } from 'rxjs/internal/operators/finalize';

import { AnimatedLogoModule } from '@shopiroller/ui';
import { AppSettingService } from '@shopiroller/core';
import { CommonModule } from '@angular/common';
import { GENERAL_VALUES } from '@shopiroller/data';
import { SubSink } from 'subsink';
import { Subject } from 'rxjs/internal/Subject';
import { SeoOptionsModel } from '../../data';
import { TranslateModule } from '@ngx-translate/core';
import { BrandsService } from '../brand';
import { BrandSlugSuggestion } from '../brand/models/brand.model';

@Component({
  selector: 'app-seo-container',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    AnimatedLogoModule
  ],
  templateUrl: './seo-container.component.html',
  styleUrls: ['./seo-container.component.scss']
})
export class SeoContainerComponent implements OnInit, OnDestroy, OnChanges {
  public isLoading = false;
  public isFetchingSlugSuggestion = false;

  @Input()
  public shouldSupportMultipleLanguages = false;

  @Input()
  public previousData: SeoOptionsModel | null = null;

  @Input()
  public newEntityName = '';

  @Output()
  public onChange = new EventEmitter<SeoOptionsModel | null>();

  @Output()
  public formValidationStatus = new EventEmitter<boolean>();

  public seoOptionsForm!: FormGroup;

  private _subs = new SubSink();

  private _nameChangesSubject = new Subject<string>();

  constructor(
    private _fb: FormBuilder,
    private _brandService: BrandsService,
    private _appSettingService: AppSettingService
  ) {
    this._initForm();
  }

  ngOnInit(): void {
    this._dispatchEvents();
    this._listenToChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentData = changes?.previousData?.currentValue;
    if (currentData != null) {
      this.seoOptionsForm.setValue(currentData);
      this.formValidationStatus.emit(this.seoOptionsForm.valid);
    }

    if (this.previousData == null) {
      // we need to call slug suggestion in add mode
      const newEntityName = changes?.newEntityName;
      if (!newEntityName.firstChange) {
        const currentName = newEntityName?.currentValue.trim();
        const previousName = newEntityName?.previousValue;
        if (currentName && previousName && currentName != previousName) {
          this._nameChangesSubject.next(currentName);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  private _initForm(): void {
    this.seoOptionsForm = this._fb.group({
      slug: ['', Validators.required],
      pageTitle: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

  private _dispatchEvents(): void {
    if (this.previousData == null) {
      this.formValidationStatus.emit(false);
    }
    this._subs.sink = this.seoOptionsForm.valueChanges.subscribe((value) => {
      this.onChange.emit(value);
      this.formValidationStatus.emit(this.seoOptionsForm.valid);
    });
  }

  private _listenToChanges(): void {
    this._subs.sink = this._nameChangesSubject
      .pipe(debounceTime(GENERAL_VALUES.SEARCH_DELAY), distinctUntilChanged())
      .subscribe((change) => {
        this._fetchSlugSuggestion(change);
      });
  }

  private _fetchSlugSuggestion(entityName: string): void {
    const appId = this._appSettingService.getCurrentAppId();
    const payload: BrandSlugSuggestion = {
      name: entityName
    };
    this.isFetchingSlugSuggestion = true;
    this.isLoading = true;
    this._subs.sink = this._brandService
      .generateSlugSuggestion(appId, payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.isFetchingSlugSuggestion = false;
        })
      )
      .subscribe((suggestion: string) =>
        this.seoOptionsForm.controls['slug'].setValue(suggestion)
      );
  }
}
