import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { END_POINTS_URL } from '@shopiroller/data';
import { NewStoreModel } from '../../models/store.model';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  constructor(private _http: HttpClient) {}

  add(payload: NewStoreModel): Observable<any> {
    const URL = `${END_POINTS_URL.V2.PROJECT_URL}`;
    return this._http.post(URL, payload);
  }

  delete(appId: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.PROJECT_URL}/demo/${appId}`;
    return this._http.delete(URL);
  }

  checkDemoDataExistence(appId: string): Observable<boolean> {
    const URL = `${END_POINTS_URL.V2.PROJECT_URL}/demo/${appId}`;
    return this._http.get(URL).pipe(
      map((result: any) => {
        return result.data.isExist as boolean;
      })
    );
  }
}
