import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { PAYMENT_TYPES_ENUM } from 'src/app/modules/store-settings';
import { Store } from '@ngrx/store';
import { selectCurrentAppPaymentInfo } from '@shopiroller/stores';

@Injectable({
  providedIn: 'root'
})
export class CollectionGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.select(selectCurrentAppPaymentInfo).pipe(
      map((paymentInfo: any) => {
        if (paymentInfo) {
          let shoppayPaymentProvider = paymentInfo?.supportedPaymentTypes.find(
            (x: any) => x.paymentType === PAYMENT_TYPES_ENUM.Shopiroller
          );
          return shoppayPaymentProvider.isActive as boolean;
        }
        return false;
      })
    );
  }
}
