import * as webHookActions from './web-hook.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { withLatestFrom } from 'rxjs/internal/operators/withLatestFrom';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { WebhookService } from '../services';
import {
  selectListOfWebHooksWithAppId,
  selectWebhookActivenessWithAppId,
  selectWebhookTypes
} from './web-hook.selectors';
import { GetAllHooksModel } from '../../data/models';
import {
  loadWebsettingDataFailure,
  loadWebsettingDataSuccess,
  WebSettingService
} from '@shopiroller/store/sales-channel';
import { StoreActionsMessageTypesList } from '@shopiroller/core';

@Injectable({
  providedIn: 'root'
})
export class WebHookFeatureEffects {
  public loadListOfWebHooksEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(webHookActions.loadListOfWebHooks),
      withLatestFrom(this._store.select(selectListOfWebHooksWithAppId)),
      switchMap((actions) => {
        const currentAppId = actions[0].appId;
        const previousAppId = actions[1]?.appId;
        const previousWebhookList = actions[1]?.list;
        if (currentAppId == previousAppId && previousWebhookList != null) {
          webHookActions.loadListOfWebHooksSuccess({
            response: {
              appId: previousAppId,
              data: previousWebhookList
            }
          });
        }
        const dto: GetAllHooksModel = {
          appId: currentAppId
        };
        return this._webHookService.getAll(dto).pipe(
          map(
            (response) =>
              webHookActions.loadListOfWebHooksSuccess({ response: response }),
            catchError(() => EMPTY)
          )
        );
      })
    )
  );

  public loadListOfWebHooksTypeEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(webHookActions.loadListOfWebHooksTypes),
      withLatestFrom(this._store.select(selectWebhookTypes)),
      switchMap((actions) => {
        const fetchedWebhooks = actions[1];
        if (fetchedWebhooks) {
          webHookActions.loadListOfWebHooksTypesSuccess({
            response: fetchedWebhooks
          });
        }
        return this._webHookService.getAllTypes().pipe(
          map(
            (response) =>
              webHookActions.loadListOfWebHooksTypesSuccess({
                response: response
              }),
            catchError(() => EMPTY)
          )
        );
      })
    )
  );

  public checkWebhookActivenessEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(webHookActions.checkWebhookActiveness),
      withLatestFrom(this._store.select(selectWebhookActivenessWithAppId)),
      switchMap((actions) => {
        const currentAppId = actions[0].appId;
        const previousAppId = actions[1]?.appId;
        const previousActivenessStatus = actions[1]?.isActive;
        if (currentAppId == previousAppId && previousActivenessStatus != null) {
          webHookActions.checkWebhookActivenessSuccess({
            response: {
              appId: previousAppId,
              isActive: previousActivenessStatus
            }
          });
        }
        return this._webSettingService.getWebSettings(currentAppId).pipe(
          map(
            (response: any) => {
              loadWebsettingDataSuccess({
                response: response.data
              });
              return webHookActions.checkWebhookActivenessSuccess({
                response: {
                  appId: currentAppId,
                  isActive: response.data.webHook
                }
              });
            },
            catchError((error) => {
              loadWebsettingDataFailure({
                error: error
              });
              webHookActions.checkWebhookActivenessFailure(error);
              return EMPTY;
            })
          )
        );
      })
    )
  );

  public CreateWebHookEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(webHookActions.CreateWebHook),
      switchMap((actions) => {
        return this._webHookService.create(actions.payload).pipe(
          map(
            (response) => {
              return webHookActions.CreateWebHookSuccess({
                payload: {
                  newHook: response,
                  result: {
                    message: 'WEB_HOOK.SUCCESSFUL_CREATE',
                    messageType: StoreActionsMessageTypesList.Success
                  }
                }
              });
            },
            catchError((error) => {
              webHookActions.CreateWebHookFailure({
                payload: {
                  result: {
                    message: 'WEB_HOOK.FAILED_CREATE',
                    messageType: StoreActionsMessageTypesList.Error
                  }
                }
              });
              return EMPTY;
            })
          )
        );
      })
    )
  );

  public UpdateWebHookEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(webHookActions.UpdateWebHook),
      switchMap((actions) => {
        return this._webHookService.update(actions.payload).pipe(
          map(
            (response) => {
              return webHookActions.UpdateWebHookSuccess({
                payload: {
                  updatedHook: response,
                  result: {
                    message: 'WEB_HOOK.SUCCESSFUL_EDIT',
                    messageType: StoreActionsMessageTypesList.Success
                  }
                }
              });
            },
            catchError((error) => {
              webHookActions.CreateWebHookFailure({
                payload: {
                  result: {
                    message: 'WEB_HOOK.FAILED_EDIT',
                    messageType: StoreActionsMessageTypesList.Error
                  }
                }
              });
              return EMPTY;
            })
          )
        );
      })
    )
  );

  public DeleteWebHookEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(webHookActions.DeleteWebHook),
      switchMap((actions) => {
        return this._webHookService.delete(actions.payload).pipe(
          map(
            (response) =>
              webHookActions.DeleteWebHookSuccess({
                payload: {
                  deletedHookId: actions.payload.hookId,
                  result: {
                    message: 'WEB_HOOK.SUCCESSFUL_DELETE',
                    messageType: StoreActionsMessageTypesList.Success
                  }
                }
              }),
            catchError((error) => {
              webHookActions.DeleteWebHookFailure({
                payload: {
                  result: {
                    message: 'WEB_HOOK.FAILED_DELETE',
                    messageType: StoreActionsMessageTypesList.Error
                  }
                }
              });
              return EMPTY;
            })
          )
        );
      })
    )
  );

  constructor(
    private readonly _store: Store,
    private readonly _actions$: Actions,
    private readonly _webHookService: WebhookService,
    private readonly _webSettingService: WebSettingService
  ) {}
}
