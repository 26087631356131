import { Pipe, PipeTransform } from '@angular/core';

import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'packagePrice',
  standalone: true
})
export class PackagePricePipe implements PipeTransform {
  constructor(private _currencyPipe: CurrencyPipe) {}
  transform(periodAndPrices: any, period: 'month' | 'year'): string {
    const priceInfo = periodAndPrices.find((x) => x.period == period);
    const currency = priceInfo['currency'];
    const priceAmount = priceInfo['price'] + '';
    let transformedPrice = this._currencyPipe.transform(
      priceAmount,
      currency.toUpperCase(),
      'symbol-narrow',
      '.0'
    );
    if (currency == 'try' && transformedPrice != undefined) {
      const liraSymbol = transformedPrice.substring(0, 1);
      transformedPrice = `${transformedPrice.substring(1)} ${liraSymbol}`;
    }
    return transformedPrice ?? `0 ${currency}`;
  }
}
