import { Routes } from '@angular/router';

export const PRODUCT_ROUTES: Routes = [
  {
    path: 'list',
    loadComponent: async () =>
      (await import('../features/pages/product-list/product-list.component'))
        .ProductListComponent
  },
  {
    path: 'manage-product/:productId', // used for editing an existing product
    loadComponent: async () =>
      (await import('../features/pages/manage-product/manage-product.component'))
        .ManageProductComponent
  },
  {
    path: 'manage-product', // used for adding a new product
    loadComponent: async () =>
      (await import('../features/pages/manage-product/manage-product.component'))
        .ManageProductComponent
  },
  {
    path: '**',
    redirectTo: 'list',
    pathMatch: 'full'
  }
];
