import { AppStoreFeatureKey, AppStoreFeatureState } from './app-store.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const appStoreState =
  createFeatureSelector<AppStoreFeatureState>(AppStoreFeatureKey);

export const selectAppStoreLoadingStatus = createSelector(
  appStoreState,
  ({ status }) => status
);

export const selectAppStoreFeaturesData = createSelector(
  appStoreState,
  ({ featuresData }) => featuresData
);

export const selectAppStoreFeaturesDataWithAppId = createSelector(
  appStoreState,
  ({ appId, featuresData }) => ({ appId, featuresData })
);

export const selectWhatsappData = createSelector(
  appStoreState,
  ({ featuresData }) => featuresData?.whatsapp
);

export const selectWhatsappActivnessStatus = createSelector(
  appStoreState,
  ({ featuresData }) => featuresData?.whatsapp !== null
);

export const selectCartReminderData = createSelector(
  appStoreState,
  ({ featuresData }) => featuresData?.cartReminder
);

export const selectCartReminderActivnessStatus = createSelector(
  appStoreState,
  ({ featuresData }) => featuresData?.cartReminder !== null
);
