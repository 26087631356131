import { Routes } from '@angular/router';

export const SHOWCASE_ROUTES: Routes = [
  {
    path: 'list',
    loadComponent: async () =>
      (
        await import(
          '../features/showcase-list-page/showcase-list-page.component'
        )
      ).ShowcaseListPageComponent
  },
  {
    path: 'manage-showcase/:mode',
    loadComponent: async () =>
      (await import('../features/manage-showcase/manage-showcase.component'))
        .ManageShowcaseComponent
  },
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full'
  }
];
