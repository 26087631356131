import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { of } from 'rxjs/internal/observable/of';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { withLatestFrom } from 'rxjs/internal/operators/withLatestFrom';
import * as appStoreActions from './app-store.actions';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAppStoreFeaturesDataWithAppId } from './app-store.selectors';
import { ConversationService, ReminderService } from '../services';
import { StoreActionsMessageTypesList } from '@shopiroller/core';

@Injectable({
  providedIn: 'root'
})
export class AppStoreFeatureEffects {
  public loadAppStoreFeaturesDataEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(appStoreActions.loadAppStoreData),
      withLatestFrom(this._store.select(selectAppStoreFeaturesDataWithAppId)),
      switchMap((actions) => {
        const currentAppId = actions[0].appId;
        const previousAppId = actions[1]?.appId;
        const previousAppStoreFeaturesData = actions[1]?.featuresData;
        if (
          currentAppId == previousAppId &&
          previousAppStoreFeaturesData != null
        ) {
          return of(
            appStoreActions.loadAppStoreDataSuccess({
              response: {
                appId: previousAppId,
                featuresData: previousAppStoreFeaturesData
              }
            })
          );
        }

        const getWhatsappInfromation$ =
          this._conversationService.getWhatsAppConversation(currentAppId);

        const getCartReminderInfromation$ =
          this._reminderService.getInformation(currentAppId);

        const appStoreActiveFeaturesDataRequests$: Observable<any>[] = [
          getWhatsappInfromation$,
          getCartReminderInfromation$
        ];

        return forkJoin(appStoreActiveFeaturesDataRequests$).pipe(
          map(
            (apiResponse: any) => {
              return appStoreActions.loadAppStoreDataSuccess({
                response: {
                  appId: currentAppId,
                  featuresData: {
                    whatsapp:
                      apiResponse[0].data.length > 0
                        ? apiResponse[0].data[0]
                        : null,
                    cartReminder:
                      Object.keys(apiResponse[1].data).length == 0
                        ? null
                        : apiResponse[1].data
                  }
                }
              });
            },
            catchError((error) => EMPTY)
          )
        );
      })
    )
  );

  public updateCartReminderEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(appStoreActions.updateCartReminder),
      switchMap((action) => {
        const appId = action.appId;
        const payload = action.payload;
        return this._reminderService.setInformation(appId, payload).pipe(
          map(
            (apiResponse: any) => {
              return appStoreActions.updateCartReminderSuccess({
                payload: {
                  updatedCartReminder: payload,
                  result: {
                    message: 'APP_STORE.REMINDER_CONFIGURATION_UPDATED',
                    messageType: StoreActionsMessageTypesList.Success
                  }
                }
              });
            },
            catchError((error) => {
              appStoreActions.updateCartReminderFailure({
                payload: {
                  result: {
                    message:
                      'APP_STORE.UPDATING_REMINDER_CONFIGURATION_FAILED_MSG',
                    messageType: StoreActionsMessageTypesList.Error
                  }
                }
              });
              return EMPTY;
            })
          )
        );
      })
    )
  );

  public activateWhatsappEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(appStoreActions.activateWhatsapp),
      switchMap((action) => {
        const appId = action.appId;
        const payload = action.payload;
        return this._conversationService.addConversation(appId, payload).pipe(
          map((apiResponse: any) => {
            return appStoreActions.activateWhatsappSuccess({
              payload: {
                updatedWhatsapp: apiResponse.data,
                result: {
                  message: 'APP_STORE.WHATS_APP_ACTIVATED',
                  messageType: StoreActionsMessageTypesList.Success
                }
              }
            });
          }),
          catchError((error) => {
            return of(
              appStoreActions.activateWhatsappFailure({
                payload: {
                  result: {
                    message: 'APP_STORE.ACTIVATING_WHATS_APP_FAILED_MSG',
                    messageType: StoreActionsMessageTypesList.Error
                  }
                }
              })
            );
          })
        );
      })
    )
  );

  public updateWhatsappEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(appStoreActions.updateWhatsapp),
      switchMap((action) => {
        const appId = action.appId;
        const conversationId = action.conversationId;
        const payload = action.payload;
        return this._conversationService
          .updateConversation(appId, conversationId, payload)
          .pipe(
            map((apiResponse: any) => {
              return appStoreActions.updateWhatsappSuccess({
                payload: {
                  updatedWhatsapp: apiResponse.data,
                  result: {
                    message: 'APP_STORE.WHATS_APP_CONFIGURATION_UPDATED',
                    messageType: StoreActionsMessageTypesList.Success
                  }
                }
              });
            }),
            catchError((error) => {
              return of(
                appStoreActions.updateWhatsappFailure({
                  payload: {
                    result: {
                      message: 'APP_STORE.ACTIVATING_WHATS_APP_FAILED_MSG',
                      messageType: StoreActionsMessageTypesList.Error
                    }
                  }
                })
              );
            })
          );
      })
    )
  );

  constructor(
    private readonly _store: Store,
    private readonly _actions$: Actions,
    private _conversationService: ConversationService,
    private _reminderService: ReminderService
  ) {}
}
