import { Routes } from '@angular/router';
import { featuresList } from '@shopiroller/data';
import { FeatureGuard } from '@shopiroller/core';
import { SubscriptionFeaturesEnum } from '@shopiroller/store/subscription';

export const APP_STORE_ROUTES: Routes = [
  {
    path: 'index',
    loadComponent: async () =>
      (await import('../features/pages/market/market.component'))
        .MarketComponent
  },
  {
    path: 'whats-app',
    loadComponent: async () =>
      (
        await import(
          '../../common/pages/whats-app-page/whats-app-page.component'
        )
      ).WhatsAppPageComponent,
    data: {
      feature: featuresList.whatsApp,
      subscriptionFeature: SubscriptionFeaturesEnum.apps_WhatsApp
    },
    canActivate: [FeatureGuard]
  },
  {
    path: 'card-reminder',
    loadComponent: async () =>
      (
        await import(
          '../../common/pages/cart-reminder-page/cart-reminder-page.component'
        )
      ).CartReminderPageComponent,
    data: {
      feature: featuresList.cartReminder
    },
    canActivate: [FeatureGuard]
  },
  {
    path: 'google-analytics',
    loadComponent: async () =>
      (
        await import(
          '../../common/pages/google-analytic-page/google-analytic.component'
        )
      ).GoogleAnalyticPageComponent,
    data: {
      feature: featuresList.googleAnalytics
    },
    canActivate: [FeatureGuard]
  },
  {
    path: 'google-tag-manager',
    loadComponent: async () =>
      (
        await import(
          '../../common/pages/google-tag-manager-page/google-tag-manager.component'
        )
      ).GoogleTagManagerPageComponent,
    data: {
      feature: featuresList.googleTagManager
    },
    canActivate: [FeatureGuard]
  },
  {
    path: 'guest-shopping',
    loadComponent: async () =>
      (
        await import(
          '../../common/pages/guest-shopping-page/guest-shopping-page.component'
        )
      ).GuestShoppingPageComponent,
    data: {
      feature: featuresList.guestShopping
    },
    canActivate: [FeatureGuard]
  },
  {
    path: 'web-hook',
    loadComponent: async () =>
      (await import('../../common/pages/web-hook-page/web-hook-page.component'))
        .WebHookPageComponent,
    data: {
      feature: featuresList.webHook
    },
    canActivate: [FeatureGuard]
  },
  {
    path: '**',
    redirectTo: 'index',
    pathMatch: 'full'
  }
];
