import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import {
  SubscriptionService,
  selectCurrentSubscriptionPackagesInfo
} from '../../data-access';

import { AppSettingService } from '@shopiroller/core';
import { AutoUnsubscribe } from '@shopiroller/decorators';
import { Store } from '@ngrx/store';
import { SubSink } from 'subsink';
import { TranslateService } from '@ngx-translate/core';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import { finalize } from 'rxjs/internal/operators/finalize';
import { SubscriptionTypes, SubscriptionTypesEnum } from '../../data';
import { AnimatedLogoModule } from '@shopiroller/ui';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
@AutoUnsubscribe()
@Component({
  selector: 'subscription-current-plan-badge',
  templateUrl: './current-subscription-badge.component.html',
  styleUrls: ['./current-subscription-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, AnimatedLogoModule]
})
export class CurrentSubscriptionBadgeComponent implements OnInit {
  public faBox = faBoxOpen;
  public ButtonTitle = '';
  public PackageType: SubscriptionTypes = 'Starter';
  public isLoading = false;
  private _subs = new SubSink();

  constructor(
    private _store: Store,
    private _cdr: ChangeDetectorRef,
    private _translateService: TranslateService,
    private _appSettingService: AppSettingService,
    private _subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this._getSubscriptionData();
  }

  public upgradePackage(): void {
    if (this.PackageType == SubscriptionTypesEnum.Business) return;
    this.isLoading = true;
    const appId = this._appSettingService.getCurrentAppId();
    this._subs.sink = this._subscriptionService
      .getUpgradeSubscriptionPageURL(appId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this._cdr.detectChanges();
        })
      )
      .subscribe((portalURL) => window.open(portalURL.data, '_blank'));
  }

  private _getSubscriptionData(): void {
    this._subs.sink = this._store
      .select(selectCurrentSubscriptionPackagesInfo)
      .subscribe((packageData: any) => {
        if (packageData) {
          this.PackageType = this._translateService.instant(
            `SUBSCRIPTION.${packageData.type.toUpperCase()}`
          );
          this.ButtonTitle = this._translateService.instant(
            `SUBSCRIPTION.UPGRADE_PACKAGE_BUTTON`,
            {
              planType: this.PackageType
            }
          );
          this._cdr.detectChanges();
        }
      });
  }
}
