import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplashScreenComponent } from './splash-screen.component';
import { AnimatedLogoModule } from '@shopiroller/ui';

@NgModule({
  declarations: [SplashScreenComponent],
  imports: [CommonModule, AnimatedLogoModule],
  exports: [SplashScreenComponent]
})
export class SplashScreenModule {}
