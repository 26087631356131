import * as clientActions from './client.actions';

import { Actions } from '@ngrx/effects';

import { ClientService } from '../services';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class ClientFeatureEffects {
  constructor(
    private readonly _store: Store,
    private readonly _actions$: Actions,
    private readonly _clientService: ClientService
  ) {}
}
