import { AppSumoFeatureKey, AppSumoFeatureReducer } from '@shopiroller/appsumo';
import { AppsFeatureKey, AppsFeatureReducer } from '@shopiroller/stores';
import {
  StoreSettingsFeatureReducer,
  storeSettingsFeatureKey
} from 'src/app/modules/store-settings';
// import { clientFeatureKey, clientFeatureReducer } from 'clients';
import {
  dashboardFeatureKey,
  dashboardFeatureReducer
} from '@shopiroller/store/dashboard';
// import { discountFeatureKey, discountFeatureReducer } from 'discount';
import {
  orderFeatureKey,
  orderFeatureReducer
} from '@shopiroller/store/orders';
import {
  salesChannelFeatureKey,
  salesChannelFeatureReducer
} from '@shopiroller/store/sales-channel';
import {
  userProfileFeatureKey,
  userProfileFeatureReducer
} from '@shopiroller/user-profile';
import {
  subscriptionFeatureKey,
  subscriptionFeatureReducer
} from '@shopiroller/store/subscription';
import {
  webHookFeatureKey,
  webHookFeatureReducer
} from '@shopiroller/store/webhook';
import { AppStoreFeatureKey, AppStoreFeatureReducer } from '@shopiroller/store/apps';

export const APP_REDUCERS = {
  [userProfileFeatureKey]: userProfileFeatureReducer,
  [AppSumoFeatureKey]: AppSumoFeatureReducer,
  [AppsFeatureKey]: AppsFeatureReducer,
  [subscriptionFeatureKey]: subscriptionFeatureReducer,
  [dashboardFeatureKey]: dashboardFeatureReducer,
  [salesChannelFeatureKey]: salesChannelFeatureReducer,
  [storeSettingsFeatureKey]: StoreSettingsFeatureReducer,
  [orderFeatureKey]: orderFeatureReducer,
  // [discountFeatureKey]: discountFeatureReducer,
  // [clientFeatureKey]: clientFeatureReducer,
  [webHookFeatureKey]: webHookFeatureReducer,
  [AppStoreFeatureKey]: AppStoreFeatureReducer
};
