import * as salesChannelActions from './sales-channel.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { timer } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { withLatestFrom } from 'rxjs/internal/operators/withLatestFrom';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectMagentySaleChannelDataWithAppId,
  selectMobileSaleChannelDataWithAppId,
  selectShopilinkSaleChannelDataWithAppId,
  selectWebSaleChannelDataWithAppId
} from './sales-channel.selectors';
import { MobileSettingsService, SalesChannelService } from '../services';
import { SaleChannelTypes } from '../../data/models';
import { StoreActionsMessageTypesList } from '@shopiroller/core';
import { TranslateService } from '@ngx-translate/core';
import { ShopilinkSettingTypes, WebChannelSettingTypes } from '../../features';
import { MagentyService } from '../../features/magenty/data-access';

@Injectable({
  providedIn: 'root'
})
export class SalesChannelFeatureEffects {
  loadWebSaleChannelDataEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(salesChannelActions.loadWebSaleChannelData),
      withLatestFrom(this._store.select(selectWebSaleChannelDataWithAppId)),
      switchMap((actions) => {
        const currentAppId = actions[0].appId;
        const expireCache = actions[0].expireCache;
        const previousAppId = actions[1].appId;
        const previousChannelData = actions[1].web;
        if (
          currentAppId == previousAppId &&
          !expireCache &&
          previousChannelData != null
        ) {
          return of(
            salesChannelActions.loadWebSaleChannelDataSuccess({
              response: {
                appId: currentAppId,
                webSaleChannelData: previousChannelData
              }
            })
          );
        }
        return this._salesChannelService
          .getChannelListByChannelType(currentAppId, SaleChannelTypes.Web)
          .pipe(
            map((apiResult) =>
              salesChannelActions.loadWebSaleChannelDataSuccess({
                response: {
                  appId: currentAppId,
                  webSaleChannelData: apiResult[0]
                }
              })
            )
          );
      })
    )
  );

  loadMobileChannelCategoryDisplayModelEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(salesChannelActions.loadMobileChannelCategoryDisplayModel),
      switchMap((action) => {
        return this._mobileSettingsService.getMobileSettings(action.appId).pipe(
          map((apiResult: any) => {
            const categoryDisplayType = apiResult?.data?.categoryDisplayType;
            return salesChannelActions.loadMobileChannelCategoryDisplayModelSuccess(
              {
                payload: {
                  categoryDisplayModel:
                    categoryDisplayType == undefined
                      ? 'ImageAndText'
                      : categoryDisplayType
                }
              }
            );
          })
        );
      })
    )
  );

  loadMobileSaleChannelDataEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(salesChannelActions.loadMobileSaleChannelData),
      withLatestFrom(this._store.select(selectMobileSaleChannelDataWithAppId)),
      switchMap((actions) => {
        const currentAppId = actions[0].appId;
        const expireCache = actions[0].expireCache;
        const previousAppId = actions[1].appId;
        const previousChannelData = actions[1].mobile;
        if (
          currentAppId == previousAppId &&
          !expireCache &&
          previousChannelData != null
        ) {
          return of(
            salesChannelActions.loadMobileSaleChannelDataSuccess({
              response: {
                appId: currentAppId,
                mobileSaleChannelData: previousChannelData
              }
            })
          );
        }
        return this._salesChannelService
          .getChannelListByChannelType(currentAppId, SaleChannelTypes.Mobile)
          .pipe(
            map((result) =>
              salesChannelActions.loadMobileSaleChannelDataSuccess({
                response: {
                  appId: currentAppId,
                  mobileSaleChannelData: result
                }
              })
            ),
            catchError((error) => {
              return of(
                salesChannelActions.loadMobileSaleChannelDataFailure(error)
              );
            })
          );
      })
    )
  );

  changeActivityOfSaleChannelEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(salesChannelActions.changeActivityOfSaleChannel),
      switchMap((action) => {
        const appId = action.appId;
        let channelInfo = action.channelInfo;
        const channelType = action.channelType;
        const channelId = channelInfo.id;

        return this._salesChannelService
          .changeActivityOfSaleChannel(appId, channelId)
          .pipe(
            map((result) => {
              let successMessage = '';
              if (channelType == 'Web') {
                successMessage =
                  channelInfo.isActive == false
                    ? this._translateService.instant(
                        'SALES_CHANNEL.SALES_CHANNEL_DISABLED',
                        {
                          saleChannelType:
                            this._translateService.instant('SHARED.WEB')
                        }
                      )
                    : this._translateService.instant(
                        'SALES_CHANNEL.SALES_CHANNEL_ENABLED',
                        {
                          saleChannelType:
                            this._translateService.instant('SHARED.WEB')
                        }
                      );
              }
              if (channelType == 'Mobile') {
                channelInfo = {
                  ...channelInfo,
                  isActive: !channelInfo.isActive
                };
                successMessage =
                  channelInfo.isActive == false
                    ? this._translateService.instant(
                        'SALES_CHANNEL.SALES_CHANNEL_DISABLED',
                        {
                          saleChannelType:
                            this._translateService.instant('SHARED.MOBILE')
                        }
                      )
                    : this._translateService.instant(
                        'SALES_CHANNEL.SALES_CHANNEL_ENABLED',
                        {
                          saleChannelType:
                            this._translateService.instant('SHARED.MOBILE')
                        }
                      );
              }

              if (channelType == 'Shopilink') {
                channelInfo = {
                  ...channelInfo,
                  isActive: !channelInfo.isActive
                };
                successMessage =
                  channelInfo.isActive == true
                    ? this._translateService.instant(
                        'SALES_CHANNEL.SALES_CHANNEL_DISABLED',
                        {
                          saleChannelType: this._translateService.instant(
                            'SALES_CHANNEL.SHOPILINK'
                          )
                        }
                      )
                    : this._translateService.instant(
                        'SALES_CHANNEL.SALES_CHANNEL_ENABLED',
                        {
                          saleChannelType: this._translateService.instant(
                            'SALES_CHANNEL.SHOPILINK'
                          )
                        }
                      );
              }

              if (channelType == 'Collection') {
                channelInfo = {
                  ...channelInfo,
                  isActive: !channelInfo.isActive
                };
                successMessage =
                  channelInfo.isActive == true
                    ? this._translateService.instant(
                        'SALES_CHANNEL.SALES_CHANNEL_ENABLED',
                        {
                          saleChannelType: this._translateService.instant(
                            'SALES_CHANNEL.EASY_COLLECTION'
                          )
                        }
                      )
                    : this._translateService.instant(
                        'SALES_CHANNEL.SALES_CHANNEL_DISABLED',
                        {
                          saleChannelType: this._translateService.instant(
                            'SALES_CHANNEL.EASY_COLLECTION'
                          )
                        }
                      );
              }

              return salesChannelActions.changeActivityOfSaleChannelSuccess({
                payload: {
                  updatedChannelInfo: channelInfo,
                  channelType: channelType,
                  result: {
                    message: successMessage,
                    messageType: StoreActionsMessageTypesList.Success
                  }
                }
              });
            }),
            catchError((error) => {
              let errMsg = '';
              let saleChannelTypeTranslation = '';
              if (channelType == 'Web') {
                channelInfo.isActive = !channelInfo.isActive;
                saleChannelTypeTranslation =
                  this._translateService.instant('SHARED.WEB');
              }
              if (channelType == 'Mobile') {
                saleChannelTypeTranslation =
                  this._translateService.instant('SHARED.MOBILE');
              }
              if (channelType == 'Shopilink') {
                channelInfo.isActive = !channelInfo.isActive;
                saleChannelTypeTranslation = this._translateService.instant(
                  'SALES_CHANNEL.SHOPILINK'
                );
              }

              errMsg = this._translateService.instant(
                'SALES_CHANNEL.CHANGING_SALE_CHANNEL_ACTIVATION_FAILED',
                {
                  saleChannelType: saleChannelTypeTranslation
                }
              );
              return of(
                salesChannelActions.changeActivityOfSaleChannelFailure({
                  payload: {
                    channelType: channelType,
                    result: {
                      message: errMsg,
                      messageType: StoreActionsMessageTypesList.Error
                    }
                  }
                })
              );
            })
          );
      })
    )
  );

  updateSaleChannelEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(salesChannelActions.updateSaleChannel),
      switchMap((action) => {
        const appId = action.appId;
        const payload = action.payload;
        const channelInfo = action.channelInfo;
        const channelId = action.channelInfo.id;
        const channelType = action.channelType;
        const updateType = action.updateType;
        const settingType = payload.settingType;
        return this._salesChannelService
          .updateSaleChannel(appId, channelId, payload)
          .pipe(
            map((result) => {
              let successMessage = '';
              if (updateType == 'UPDATE_LOGO_FAVICON') {
                successMessage = this._translateService.instant(
                  'SALES_CHANNEL.SUCCESSFUL_UPDATING_FAVICON_LOGO',
                  {
                    settingType:
                      settingType == ShopilinkSettingTypes.FAV_ICON
                        ? this._translateService.instant('SHARED.FAVICON')
                        : this._translateService.instant('SHARED.LOGO')
                  }
                );
              }
              if (updateType == 'REMOVING_FAVICON_LOGO') {
                successMessage = this._translateService.instant(
                  'SALES_CHANNEL.SUCCESSFUL_REMOVING_FAVICON_LOGO',
                  {
                    settingType:
                      settingType == ShopilinkSettingTypes.FAV_ICON
                        ? this._translateService.instant('SHARED.FAVICON')
                        : this._translateService.instant('SHARED.LOGO')
                  }
                );
                if (settingType == ShopilinkSettingTypes.LOGO) {
                  delete channelInfo.logo;
                  if (channelInfo.hasOwnProperty('favIcon')) {
                    payload.favIcon = channelInfo.favIcon.n;
                  }
                } else if (settingType == ShopilinkSettingTypes.FAV_ICON) {
                  delete channelInfo.favIcon;
                  if (channelInfo.hasOwnProperty('logo')) {
                    payload.logo = channelInfo.logo.n;
                  }
                }
              }
              timer(500).subscribe((_) => {
                if (channelType == 'Mobile') {
                  this._store.dispatch(
                    salesChannelActions.loadMobileSaleChannelData({
                      appId: appId,
                      expireCache: true
                    })
                  );
                }
                if (channelType == 'Web') {
                  this._store.dispatch(
                    salesChannelActions.loadWebSaleChannelData({
                      appId: appId,
                      expireCache: true
                    })
                  );
                }
                if (channelType == 'Shopilink') {
                  this._store.dispatch(
                    salesChannelActions.loadShopilinkSaleChannelData({
                      appId: appId,
                      expireCache: true
                    })
                  );
                }

                if (channelType == 'Collection') {
                  this._store.dispatch(
                    salesChannelActions.loadEasyCollectionSaleChannelData({
                      appId: appId,
                      expireCache: true
                    })
                  );
                }
              });
              return salesChannelActions.updateSaleChannelSuccess({
                payload: {
                  result: {
                    message: successMessage,
                    messageType: StoreActionsMessageTypesList.Success
                  }
                }
              });
            }),
            catchError((error) => {
              let errMsg = '';
              if (updateType == 'UPDATE_LOGO_FAVICON') {
                errMsg = this._translateService.instant(
                  'SALES_CHANNEL.FAILED_UPDATING_FAVICON_LOGO',
                  {
                    settingType:
                      settingType == WebChannelSettingTypes.FAV_ICON
                        ? this._translateService.instant('SHARED.FAVICON')
                        : this._translateService.instant('SHARED.LOGO')
                  }
                );
              }
              if (updateType == 'REMOVING_FAVICON_LOGO') {
                errMsg = this._translateService.instant(
                  'SALES_CHANNEL.FAILED_REMOVING_FAVICON_LOGO',
                  {
                    settingType:
                      settingType == ShopilinkSettingTypes.FAV_ICON
                        ? this._translateService.instant('SHARED.FAVICON')
                        : this._translateService.instant('SHARED.LOGO')
                  }
                );
              }
              return of(
                salesChannelActions.updateSaleChannelFailure({
                  payload: {
                    result: {
                      message: errMsg,
                      messageType: StoreActionsMessageTypesList.Error
                    }
                  }
                })
              );
            })
          );
      })
    )
  );

  updateMobileSaleChannelEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(salesChannelActions.updateMobileSaleChannel),
      switchMap((action) => {
        const appId = action.appId;
        const payload = action.payload;
        return this._mobileSettingsService
          .updateMobileSettings(appId, payload)
          .pipe(
            map((result) => {
              let successMessage = this._translateService.instant(
                'SHARED.MESSAGES.SUCCESSFUL_EDIT',
                {
                  case: this._translateService.instant(
                    'SALES_CHANNEL.CATEGORY_LAYOUT'
                  )
                }
              );
              return salesChannelActions.updateMobileSaleChannelSuccess({
                payload: {
                  result: {
                    message: successMessage,
                    messageType: StoreActionsMessageTypesList.Success
                  }
                }
              });
            }),
            catchError((error) => {
              let errMsg = this._translateService.instant(
                'SHARED.MESSAGES.FAILED_EDIT',
                {
                  case: this._translateService.instant(
                    'SALES_CHANNEL.CATEGORY_LAYOUT'
                  )
                }
              );
              return of(
                salesChannelActions.updateMobileSaleChannelFailure({
                  payload: {
                    result: {
                      message: errMsg,
                      messageType: StoreActionsMessageTypesList.Error
                    }
                  }
                })
              );
            })
          );
      })
    )
  );

  loadShopilinkChannelsEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(salesChannelActions.loadShopilinkSaleChannelData),
      withLatestFrom(
        this._store.select(selectShopilinkSaleChannelDataWithAppId)
      ),
      switchMap((actions) => {
        const currentAppId = actions[0].appId;
        const expireCache = actions[0].expireCache;
        const previousAppId = actions[1].appId;
        const previousChannelData = actions[1].shopilink;
        if (
          currentAppId == previousAppId &&
          !expireCache &&
          previousChannelData != null
        ) {
          return of(
            salesChannelActions.loadShopilinkSaleChannelDataSuccess({
              response: {
                appId: currentAppId,
                shopilinkSaleChannelData: previousChannelData
              }
            })
          );
        }
        return this._salesChannelService
          .getChannelListByChannelType(currentAppId, SaleChannelTypes.Shopilink)
          .pipe(
            map((result) =>
              salesChannelActions.loadShopilinkSaleChannelDataSuccess({
                response: {
                  appId: currentAppId,
                  shopilinkSaleChannelData: result
                }
              })
            )
          );
      })
    )
  );

  connectMagentySaleChannelEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(salesChannelActions.connectMagentySaleChannel),
      switchMap((action) => {
        const appId = action.appId;
        const payload = action.payload;
        return this._magentyService.connect(appId, payload).pipe(
          map((result) => {
            const successMessage = this._translateService.instant(
              'SALES_CHANNEL.MAGENTY_CONFIGURATION_SUCCESSFULY_COMPLETED'
            );
            timer(300).subscribe((_) => {
              this._store.dispatch(
                salesChannelActions.loadMagentySaleChannelData({
                  appId,
                  expireCache: true
                })
              );
            });
            return salesChannelActions.connectMagentySaleChannelSuccess({
              payload: {
                response: {
                  magentyData: result.data,
                  appId: appId
                },
                result: {
                  message: successMessage,
                  messageType: StoreActionsMessageTypesList.Success
                }
              }
            });
          }),
          catchError((error) => {
            const errMsg = this._translateService.instant(
              'SALES_CHANNEL.MAGENTY_CONFIGURATION_FAILED'
            );
            return of(
              salesChannelActions.connectMagentySaleChannelFailure({
                payload: {
                  result: {
                    message: errMsg,
                    messageType: StoreActionsMessageTypesList.Error
                  }
                }
              })
            );
          })
        );
      })
    )
  );

  loadMagentySaleChannelDataEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(salesChannelActions.loadMagentySaleChannelData),
      withLatestFrom(this._store.select(selectMagentySaleChannelDataWithAppId)),
      switchMap((actions) => {
        const currentAppId = actions[0].appId;
        const expireCache = actions[0].expireCache;
        const previousAppId = actions[1].appId;
        const previousChannelData = actions[1].magenty;
        if (
          currentAppId == previousAppId &&
          !expireCache &&
          previousChannelData != null
        ) {
          return of(
            salesChannelActions.loadMagentySaleChannelDataSuccess({
              response: {
                appId: currentAppId,
                channelData: previousChannelData
              }
            })
          );
        }
        return this._salesChannelService
          .getChannelListByChannelType(currentAppId, SaleChannelTypes.Magenty)
          .pipe(
            map((apiResult) =>
              salesChannelActions.loadMagentySaleChannelDataSuccess({
                response: {
                  appId: currentAppId,
                  channelData: apiResult[0]
                }
              })
            ),
            catchError((error) => {
              return of(
                salesChannelActions.loadMagentySaleChannelDataFailure({
                  error: error
                })
              );
            })
          );
      })
    )
  );

  loadEasyCollectionSaleChannelDataEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(salesChannelActions.loadEasyCollectionSaleChannelData),
      withLatestFrom(this._store.select(selectMagentySaleChannelDataWithAppId)),
      switchMap((actions) => {
        const currentAppId = actions[0].appId;
        const expireCache = actions[0].expireCache;
        const previousAppId = actions[1].appId;
        const previousChannelData = actions[1].magenty;
        if (
          currentAppId == previousAppId &&
          !expireCache &&
          previousChannelData != null
        ) {
          return of(
            salesChannelActions.loadEasyCollectionSaleChannelDataSuccess({
              response: {
                appId: currentAppId,
                channelData: previousChannelData
              }
            })
          );
        }
        return this._salesChannelService
          .getChannelListByChannelType(
            currentAppId,
            SaleChannelTypes.Collection
          )
          .pipe(
            map((apiResult) =>
              salesChannelActions.loadEasyCollectionSaleChannelDataSuccess({
                response: {
                  appId: currentAppId,
                  channelData: apiResult[0]
                }
              })
            ),
            catchError((error) => {
              return of(
                salesChannelActions.loadEasyCollectionSaleChannelDataFailure({
                  error: error
                })
              );
            })
          );
      })
    )
  );

  constructor(
    private readonly _store: Store,
    private readonly _actions$: Actions,
    private readonly _translateService: TranslateService,
    private readonly _salesChannelService: SalesChannelService,
    private readonly _mobileSettingsService: MobileSettingsService,
    private readonly _magentyService: MagentyService
  ) {}
}
