import { GenericState, GenericStoreStatus } from '@shopiroller/core';
import {
  LoadDashboardPageData,
  LoadDashboardPageDataFailure,
  LoadDashboardPageDataSuccess
} from './dashboard.actions';
import { createReducer, on } from '@ngrx/store';
import { DashboardTodayOrdersDataModel } from '../../data/models';
export const dashboardFeatureKey = 'feature-dashboard';

export class dashboardFeatureState implements GenericState<any> {
  appId!: string;
  lastCustomers: any[] = [];
  lastOrders: any[] = [];
  todayOrdersData: DashboardTodayOrdersDataModel | null = null;
  integrationSteps: any = null;
  status: GenericStoreStatus | any = 'pending';
  error: any = null;
}

const initialState: dashboardFeatureState = {
  appId: '',
  lastCustomers: [],
  lastOrders: [],
  todayOrdersData: null,
  integrationSteps:null,
  error: null,
  status: 'pending'
};

export const dashboardFeatureReducer = createReducer(
  initialState,
  on(LoadDashboardPageData, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(LoadDashboardPageDataSuccess, (state, { response }) => {
    return {
      ...state,
      appId: response.appId,
      lastCustomers: response.lastCustomers,
      lastOrders: response.lastOrders,
      todayOrdersData: response.todayOrdersData,
      integrationSteps: response.integrationSteps,
      status: 'success',
      error: null
    };
  }),
  on(LoadDashboardPageDataFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  }))
);
