import { END_POINTS_URL } from '@shopiroller/data';
import { HttpClient } from '@angular/common/http';
import { ICategoryDisplayType } from '../../data/models/i-category-display-type.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MobileSettingsService {
  private readonly MOBILE_SETTINGS_BASE_URL = `${END_POINTS_URL.V2.MOBILE_SETTINGS_URL}`;
  constructor(private httpClient: HttpClient) {}

  /**
   * @param {string} appId
   * @return {  category display type of app }
   * @memberof MobileSettingsService
   * related API: https://dev.ecommerce.applyze.com/api-docs/index.html?urls.primaryName=2.0#/MobileSettings/GetMobileSettings
   */
  public getMobileSettings(appId: string) {
    const URL = `${this.MOBILE_SETTINGS_BASE_URL}/${appId}`;
    return this.httpClient.get(URL);
  }

  /**
   * Used when we are going to set display type for the first time
   * @param {string} appId
   * @param {ICategoryDisplayType} payload
   * @return {*}
   * related API: https://dev.ecommerce.applyze.com/api-docs/index.html?urls.primaryName=2.0#/MobileSettings/SetMobileSettings
   */
  public setMobileSettings(appId: string, payload: ICategoryDisplayType) {
    const URL = `${this.MOBILE_SETTINGS_BASE_URL}/${appId}`;
    return this.httpClient.post(URL, payload);
  }

  /**
   *
   *
   * @param {string} appId
   * @param {ICategoryDisplayType} payload
   * @return {*}
   * related API: https://dev.ecommerce.applyze.com/api-docs/index.html?urls.primaryName=2.0#/MobileSettings/UpdateMobileSettings
   */
  public updateMobileSettings(appId: string, payload: ICategoryDisplayType) {
    const URL = `${this.MOBILE_SETTINGS_BASE_URL}/${appId}`;
    return this.httpClient.put(URL, payload);
  }
}
