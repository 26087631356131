import {
  AddNewApp,
  AddNewAppFailure,
  AddNewAppSuccess,
  GetListOfApps,
  GetListOfAppsFailure,
  GetListOfAppsSuccess,
  LoadCurrentAppData,
  LoadCurrentAppDataFailure,
  LoadCurrentAppDataSuccess,
  LoadMoreApps,
  LoadMoreAppsFailure,
  LoadMoreAppsSuccess,
  UpdateAppWebsetting,
  UpdateAppWebsettingFailure,
  UpdateAppWebsettingSuccess,
  addNewLanguage,
  addNewLanguageFailure,
  addNewLanguageSuccess,
  changeDefaultLanguageOfApp,
  changeDefaultLanguageOfAppFailure,
  changeDefaultLanguageOfAppSuccess,
  createStore,
  createStoreFailure,
  createStoreSuccess,
  deleteAppLanguage,
  deleteAppLanguageFailure,
  deleteAppLanguageSuccess,
  deleteExistingApp,
  editExistingAppSuccess,
  loadPrimitiveStoreInformation,
  loadPrimitiveStoreInformationFailure,
  loadPrimitiveStoreInformationSuccess,
  moveAppToTopOfAppList
} from './app.actions';
import { GenericState, GenericStoreStatus } from '@shopiroller/core';
import { createReducer, on } from '@ngrx/store';
import { AppListResultModel, SelectedAppDataModel } from '../../models';
import { updatePaymentInformationSuccess } from 'src/app/modules/store-settings';
import { updateSupportedSalesChannelListSuccess } from '@shopiroller/store/sales-channel';
import { PrimitveStoreDataModel } from '../../models/store.model';

export const AppsFeatureKey = 'feature-apps';

export class AppsFeatureState implements GenericState<any> {
  list: AppListResultModel | any = null;
  currentApp: SelectedAppDataModel | null = null;
  primitiveData!: PrimitveStoreDataModel;
  status: GenericStoreStatus | any = 'pending';
  error: any = null;
}

const initialState: AppsFeatureState = {
  list: null,
  currentApp: null,
  primitiveData: {
    countryList: [],
    currencyList: [],
    languageList: [],
    sectorList: []
  },
  error: null,
  status: 'pending'
};

export const AppsFeatureReducer = createReducer(
  initialState,
  on(GetListOfApps, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(GetListOfAppsSuccess, (state, { response }) => {
    return {
      ...state,
      list: response,
      status: 'success',
      error: null
    };
  }),
  on(GetListOfAppsFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(LoadMoreApps, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(LoadMoreAppsSuccess, (state, { response }) => {
    const newApps = response.data;
    const previousApps = state.list?.data;
    return {
      ...state,
      list: {
        ...state.list,
        data: previousApps.concat(newApps)
      },
      status: 'success',
      error: null
    };
  }),
  on(LoadMoreAppsFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(moveAppToTopOfAppList, (state, { appId }) => {
    let appList = Object.assign([], state.list);
    const appListData = Object.assign([], appList.data);
    const previousFirstApp = appListData[0];
    const newlySelectedAppIndex = appListData.findIndex((x) => x.id == appId);
    appListData[0] = appListData[newlySelectedAppIndex];
    appListData[newlySelectedAppIndex] = previousFirstApp;
    appList = {
      ...appList,
      data: appListData
    };
    return {
      ...state,
      list: appList,
      currentApp: null,
      status: 'success',
      error: null
    };
  }),
  on(AddNewApp, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(AddNewAppSuccess, (state, { response }) => {
    const newData: AppListResultModel = {
      data:
        state.list == undefined ? [response] : state.list.data.concat(response),
      meta: {
        count: (state.list?.meta.count ?? 0) + 1
      }
    };
    return {
      ...state,
      list: newData,
      status: 'success',
      error: null
    };
  }),
  on(AddNewAppFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(deleteExistingApp, (state, {}) => {
    return {
      ...state,
      currentApp: null,
      status: 'success'
    };
  }),
  on(editExistingAppSuccess, (state, { payload }) => {
    if (payload == undefined) return state;
    const edittedAppIndex = state.list.data.findIndex(
      (x) => x.id === payload.edittedApp.id
    );
    let appListData = Object.assign([], state.list.data);
    appListData[edittedAppIndex] = payload.edittedApp;
    const newData: AppListResultModel = {
      data: appListData,
      meta: {
        count: (state.list?.meta.count ?? 0) + 1
      }
    };
    return {
      ...state,
      list: newData,
      status: 'success',
      error: null
    };
  }),
  on(LoadCurrentAppData, (state) => ({
    ...state,
    currentApp: null,
    status: 'loading'
  })),
  on(LoadCurrentAppDataSuccess, (state, { response }) => {
    return {
      ...state,
      currentApp: response,
      status: 'success',
      error: null
    };
  }),
  on(LoadCurrentAppDataFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(UpdateAppWebsetting, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(UpdateAppWebsettingSuccess, (state, { payload }) => {
    const currentAppData = Object.assign({}, state.currentApp);
    if (currentAppData) {
      currentAppData.webSettings = payload.webSetting;
    }
    return {
      ...state,
      currentApp: currentAppData,
      status: 'success',
      error: null
    };
  }),
  on(UpdateAppWebsettingFailure, (state, { payload }) => ({
    ...state,
    status: 'error',
    error: payload.result.message
  })),
  on(updatePaymentInformationSuccess, (state, { payload }) => {
    const currentAppData = Object.assign({}, state.currentApp);
    if (currentAppData) {
      currentAppData.paymentInfo = payload.updatedPaymentInformation;
    }
    return {
      ...state,
      currentApp: currentAppData,
      status: 'success',
      error: null
    };
  }),
  on(addNewLanguage, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(addNewLanguageSuccess, (state, { payload }) => {
    const currentAppData = Object.assign({}, state.currentApp);
    if (currentAppData) {
      const newLanguage = {
        ...payload.newLanguage,
        isDefault: false
      };
      const supportedLanguages = Object.assign(
        [],
        currentAppData.languageList.supportedLanguages
      );
      supportedLanguages.push(newLanguage);
      currentAppData.languageList = {
        ...currentAppData.languageList,
        supportedLanguages: supportedLanguages
      };
    }
    return {
      ...state,
      currentApp: currentAppData,
      status: 'success',
      error: null
    };
  }),
  on(addNewLanguageFailure, (state, { payload }) => ({
    ...state,
    status: 'error',
    error: payload.result.message
  })),
  on(deleteAppLanguage, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(deleteAppLanguageSuccess, (state, { payload }) => {
    let currentAppData = Object.assign({}, state.currentApp);
    if (currentAppData) {
      const updatedLanguageList = {
        ...currentAppData.languageList,
        supportedLanguages:
          currentAppData.languageList.supportedLanguages.filter(
            (x) => x.id != payload.deletedLanguageId
          )
      };
      currentAppData = {
        ...currentAppData,
        languageList: updatedLanguageList
      };
    }
    return {
      ...state,
      currentApp: currentAppData,
      status: 'success',
      error: null
    };
  }),
  on(deleteAppLanguageFailure, (state, { payload }) => ({
    ...state,
    status: 'error',
    error: payload.result.message
  })),
  on(changeDefaultLanguageOfApp, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(changeDefaultLanguageOfAppSuccess, (state, { payload }) => {
    let currentAppData = Object.assign({}, state.currentApp);
    if (currentAppData) {
      const previousDefaultLanguage = {
        ...currentAppData.languageList.defaultLanguage,
        isDefault: false
      };

      const newDefaultlanguage = {
        ...currentAppData.languageList.supportedLanguages.find(
          (x) => x.id == payload.newDefaultLanguageId
        ),
        isDefault: true
      };

      const supportedLanguages =
        currentAppData.languageList.supportedLanguages.filter(
          (x) =>
            x.id != payload.newDefaultLanguageId &&
            x.id != previousDefaultLanguage.id
        );

      supportedLanguages.push(newDefaultlanguage);
      supportedLanguages.push(previousDefaultLanguage);

      const updatedLanguageList = {
        defaultLanguage: newDefaultlanguage,
        supportedLanguages: supportedLanguages
      };
      currentAppData = {
        ...currentAppData,
        languageList: updatedLanguageList
      };
    }
    return {
      ...state,
      currentApp: currentAppData,
      status: 'success',
      error: null
    };
  }),
  on(changeDefaultLanguageOfAppFailure, (state, { payload }) => ({
    ...state,
    status: 'error',
    error: payload.result.message
  })),
  on(updateSupportedSalesChannelListSuccess, (state, { payload }) => {
    const currentAppData = Object.assign({}, state.currentApp);
    if (currentAppData) {
      currentAppData.supportedSalesChannelList = payload.channelList;
    }
    return {
      ...state,
      currentApp: currentAppData,
      status: 'success',
      error: null
    };
  }),
  on(loadPrimitiveStoreInformation, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(loadPrimitiveStoreInformationSuccess, (state, { response }) => {
    return {
      ...state,
      primitiveData: response,
      status: 'success',
      error: null
    };
  }),
  on(loadPrimitiveStoreInformationFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(createStore, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(createStoreSuccess, (state, {}) => {
    return {
      ...state,
      status: 'success',
      error: null
    };
  }),
  on(createStoreFailure, (state, { payload }) => ({
    ...state,
    status: 'error',
    error: payload.result.message
  }))
);
