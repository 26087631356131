import { IRegion } from '../../data/models';

import * as storeSettingsActions from './store-settings.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, Observable, forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { withLatestFrom } from 'rxjs/internal/operators/withLatestFrom';


import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectStoreSettingsDataWithAppId,
  selectStoreSlidersWithAppId
} from './store-settings.selectors';
import {
  LanguageService,
  PaymentService,
  RegionService,
  SharedService,
  ShippingAndDeliveryService,
  SliderService,
  StoreService
} from '../services';
import {
  addNewLanguage,
  addNewLanguageFailure,
  addNewLanguageSuccess,
  changeDefaultLanguageOfApp,
  changeDefaultLanguageOfAppFailure,
  changeDefaultLanguageOfAppSuccess,
  deleteAppLanguage,
  deleteAppLanguageFailure,
  deleteAppLanguageSuccess
} from '@shopiroller/stores';
import { StoreSettingsModel } from '../../data/models/store-settings.model';
import { StoreActionsMessageTypesList } from '@shopiroller/core';
import { TranslateService } from '@ngx-translate/core';
import { Operations, PAYMENT_TYPES_ENUM } from '../../data/enums';
import { LayoutService } from 'src/app/shared/@metronic/core';

@Injectable({
  providedIn: 'root'
})
export class StoreSettingsFeatureEffects {
  loadAppStoreFeaturesDataEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(storeSettingsActions.loadStoreSettingInformation),
      withLatestFrom(this._store.select(selectStoreSettingsDataWithAppId)),
      switchMap((actions) => {
        const currentAppId = actions[0].appId;
        const previousAppId = actions[1]?.appId;
        const previousStoreSettingData = actions[1]?.data;
        if (currentAppId == previousAppId && previousStoreSettingData != null) {
          return of(
            storeSettingsActions.loadStoreSettingInformationSuccess({
              response: {
                appId: previousAppId,
                data: previousStoreSettingData
              }
            })
          );
        }

        const storeInfo$ = this._storeService.getStoreInfo(currentAppId);
        const languageList$ = this._languageService.getLanguageList();
        const currencyList$ = this._sharedService.getCurrenciesList();
        const countryList$ = this._sharedService.getCountriesList();
        const regionList$ = this._regionService.getRegions(currentAppId);
        const sectorList$ = this._sharedService.getSectorsList();
        const shippingInfo$ =
          this._shippingAndDeliveryService.getShippingAndDeliveryInfo(
            currentAppId
          );
        const appStoreActiveFeaturesDataRequests$: Observable<any>[] = [
          storeInfo$,
          languageList$,
          currencyList$,
          countryList$,
          regionList$,
          sectorList$,
          shippingInfo$
        ];
        return forkJoin(appStoreActiveFeaturesDataRequests$).pipe(
          map(
            (apiResponses: any) => {
              const languageList = apiResponses[1].data;
              const currencyList = apiResponses[2];
              const countryList = apiResponses[3];
              const regions = apiResponses[4].data.map(
                (regionInfo: IRegion) => {
                  return {
                    currency: this._getCurrency(
                      currencyList,
                      regionInfo.currency
                    ),
                    language: this._getLanguage(
                      languageList,
                      regionInfo.language
                    ),
                    regions: this._getRegions(countryList, regionInfo.regions),
                    id: regionInfo.id
                  };
                }
              );

              const storeInfo: StoreSettingsModel = {
                info: apiResponses[0],
                languages: languageList,
                currencies: currencyList,
                countries: countryList,
                regions: regions,
                sectors: apiResponses[5].data,
                shipping: apiResponses[6]
              };
              return storeSettingsActions.loadStoreSettingInformationSuccess({
                response: {
                  appId: currentAppId,
                  data: storeInfo
                }
              });
            },
            catchError((error) => EMPTY)
          )
        );
      })
    )
  );

  updateStoreInformationEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(storeSettingsActions.updateStoreInformation),
      switchMap((action) => {
        const appId = action.payload.appId;
        const updatePaymentInfo$ = this._paymentService.updatePaymentInfo(
          appId,
          action.payload.updatePaymentDto
        );
        const updateStoreInfo$ = this._storeService.updateStoreInfo(
          appId,
          action.payload.updateStoreInfoDto
        );
        const updateShippingInfo$ =
          this._shippingAndDeliveryService.updateShippingAndDelivery(
            appId,
            action.payload.updateShippingInfoDto
          );

        return updatePaymentInfo$.pipe(
          switchMap((_) => forkJoin([updateStoreInfo$, updateShippingInfo$])),
          map(
            (result) =>
              storeSettingsActions.updateStoreInformationSuccess({
                payload: {
                  result: {
                    message:
                      'STORE_SETTINGS.MESSAGES.STORE_SUCCESSFULLY_EDITED',
                    messageType: StoreActionsMessageTypesList.Success
                  }
                }
              }),
            catchError((error) => {
              let errMsg = 'SHARED.MESSAGES.ERROR';
              if (
                error?.error?.message.startsWith(
                  "'Name Surname' must be between 4 and 255 characters"
                )
              ) {
                const LegalNameOfCompany = this._translateService.instant(
                  'STORE_SETTINGS.STORE_INFORMATION_PAGE.BUSINESS_OFFICIAL_NAME'
                );
                errMsg = error?.error?.message.replace(
                  'Name Surname',
                  `${LegalNameOfCompany}`
                );
              }
              if (error?.error?.key == 'CurrencyCantUpdate') {
                errMsg = 'STORE_SETTINGS.MESSAGES.CURRENCY_CANT_UPDATE';
              } else {
                errMsg = 'STORE_SETTINGS.MESSAGES.STORE_FAILED_EDITED';
              }

              storeSettingsActions.updateStoreInformationFailure({
                payload: {
                  result: {
                    message: errMsg,
                    messageType: StoreActionsMessageTypesList.Error
                  }
                }
              });
              return EMPTY;
            })
          )
        );
      })
    )
  );

  updatePaymentInformationEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(storeSettingsActions.updatePaymentInformation),
      switchMap((action) => {
        const appId = action.payload.appId;
        const paymentInformation = action.payload.paymentInformationDto;
        const paymentIndexInSupportedPayments =
          action.payload.paymentIndexInSupportedPayments;
        const updateSourceName = action.payload.updateSourceName;
        const operationType = action.payload?.operationType;
        return this._paymentService
          .updatePaymentInfo(appId, paymentInformation)
          .pipe(
            map((result) => {
              let successMessage = '';
              if (
                updateSourceName == 'iyzicoPaymentConfigurationComponent' ||
                updateSourceName == 'paypalPaymentConfigurationComponent' ||
                updateSourceName == 'shopipayPaymentConfigurationComponent' ||
                updateSourceName == 'stripePaymentConfigurationComponent' ||
                updateSourceName == 'paytrPaymentConfigurationComponent' ||
                updateSourceName == 'payzeePaymentConfigurationComponent' ||
                updateSourceName == 'payAtDoorPaymentConfigurationComponent' ||
                updateSourceName == 'moneyOrderPaymentConfigurationComponent'
              ) {
                let componentName = '';
                switch (updateSourceName) {
                  case 'iyzicoPaymentConfigurationComponent':
                    componentName =
                      'STORE_SETTINGS.PAYMENT_PAGE.IYZICO_PROVIDER_CONFIGURE';
                    break;
                  case 'paypalPaymentConfigurationComponent':
                    componentName =
                      'STORE_SETTINGS.PAYMENT_PAGE.PAYPAL_PROVIDER_CONFIGURE';
                    break;
                  case 'shopipayPaymentConfigurationComponent':
                    componentName =
                      'STORE_SETTINGS.PAYMENT_PAGE.SHOPIPAY_PROVIDER_CONFIGURE';
                    break;
                  case 'stripePaymentConfigurationComponent':
                    componentName =
                      'STORE_SETTINGS.PAYMENT_PAGE.STRIPE_PROVIDER_CONFIGURE';
                    break;
                  case 'paytrPaymentConfigurationComponent':
                    componentName =
                      'STORE_SETTINGS.PAYMENT_PAGE.PAY_TR_PROVIDER_CONFIGURE';
                    break;
                  case 'payzeePaymentConfigurationComponent':
                    componentName =
                      'STORE_SETTINGS.PAYMENT_PAGE.BIRLESIK_ODEME_PROVIDER_CONFIGURE';
                    break;
                  case 'payAtDoorPaymentConfigurationComponent':
                    componentName =
                      'STORE_SETTINGS.PAYMENT_PAGE.CASH_ON_DELIVERY_CONFIGURE';
                    break;
                  case 'moneyOrderPaymentConfigurationComponent':
                    componentName =
                      'STORE_SETTINGS.PAYMENT_PAGE.MONEY_ORDER_CONFIGURE';
                    break;
                }
                successMessage = this._translateService.instant(
                  'SHARED.MESSAGES.SUCCESSFUL_EDIT',
                  {
                    case: this._translateService.instant(componentName)
                  }
                );
              }

              if (updateSourceName == 'emailNotificationPage') {
                if (
                  operationType === null ||
                  operationType === Operations.SUBMIT
                ) {
                  successMessage = this._translateService.instant(
                    'STORE_SETTINGS.MESSAGES.EMAILS_NOTIFICATION_SUCCESSFULLY_EDITED'
                  );
                } else if (operationType === Operations.DELETE) {
                  successMessage = this._translateService.instant(
                    'STORE_SETTINGS.MESSAGES.EMAILS_NOTIFICATION_SUCCESSFULLY_DELETED'
                  );
                } else if (operationType === undefined) {
                  successMessage = this._translateService.instant(
                    'STORE_SETTINGS.EMAIL_NOTIFICATION.SENDER_EMAIL_EDITED_SUCCESSFULLY'
                  );
                }
              }

              if (updateSourceName == 'legalPages') {
                successMessage = this._translateService.instant(
                  'SHARED.MESSAGES.SUCCESSFUL_EDIT',
                  {
                    case: this._translateService.instant(
                      'STORE_SETTINGS.LEGAL_PAGE.PAGE_TITLE'
                    )
                  }
                );
              }

              if (updateSourceName == 'paymentProvidersPage') {
                const paymentData =
                  paymentInformation.supportedPaymentTypes[
                    paymentIndexInSupportedPayments ?? 0
                  ];
                if (
                  paymentData.paymentType == PAYMENT_TYPES_ENUM.Shopiroller &&
                  !paymentData.isActive
                ) {
                  successMessage =
                    'STORE_SETTINGS.PAYMENT_PAGE.SHOPIPAY_PASSIVE_MESSAGE';
                } else if (
                  paymentData.hasOwnProperty('configuration') &&
                  Object.keys(paymentData.configuration).length > 0
                ) {
                  successMessage = paymentData.isConfirmed
                    ? 'STORE_SETTINGS.PAYMENT_PAGE.SUCCESSFUL_UPDATE_CONFIRMED'
                    : 'STORE_SETTINGS.PAYMENT_PAGE.SUCCESSFUL_UPDATE_NOT_CONFIRMED';
                }
                if (paymentData.paymentType == PAYMENT_TYPES_ENUM.Shopiroller) {
                  this._layoutService.setSelectedApp(appId);
                }
              }

              return storeSettingsActions.updatePaymentInformationSuccess({
                payload: {
                  updatedPaymentInformation: paymentInformation,
                  result: {
                    message: successMessage,
                    messageType: StoreActionsMessageTypesList.Success
                  }
                }
              });
            }),
            catchError((error) => {
              let errMsg = this._translateService.instant(
                'SHARED.MESSAGES.FAILED_EDIT_OPERATION'
              );

              if (updateSourceName == 'legalPages') {
                errMsg = this._translateService.instant(
                  'SHARED.MESSAGES.FAILED_EDIT',
                  {
                    case: this._translateService.instant(
                      'STORE_SETTINGS.LEGAL_PAGE.PAGE_TITLE'
                    )
                  }
                );
              }
              if (updateSourceName == 'emailNotificationPage') {
                errMsg = this._translateService.instant(
                  'SHARED.MESSAGES.FAILED_EDIT',
                  {
                    case: this._translateService.instant(
                      'STORE_SETTINGS.EMAIL_NOTIFICATION.PAGE_TITLE'
                    )
                  }
                );
              }
              return of(
                storeSettingsActions.updatePaymentInformationFailure({
                  payload: {
                    result: {
                      message: errMsg,
                      messageType: StoreActionsMessageTypesList.Error
                    }
                  }
                })
              );
            })
          );
      })
    )
  );

  updateShippingInformationEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(storeSettingsActions.updateShippingInformation),
      switchMap((action) => {
        const appId = action.payload.appId;
        const shippingInformation = action.payload.shippingInformationDto;
        const shippingTranslation = this._translateService.instant(
          'STORE_SETTINGS.SHIPPING.SHIPPING_SETTINGS'
        );
        return this._shippingAndDeliveryService
          .updateShippingAndDelivery(appId, shippingInformation)
          .pipe(
            map((apiResponse) => {
              const successMessage = this._translateService.instant(
                'SHARED.MESSAGES.SUCCESSFUL_EDIT',
                {
                  case: shippingTranslation
                }
              );
              return storeSettingsActions.updateShippingInformationSuccess({
                payload: {
                  updatedShippingInformation: shippingInformation,
                  result: {
                    message: successMessage,
                    messageType: StoreActionsMessageTypesList.Success
                  }
                }
              });
            }),
            catchError((error) => {
              const errMsg = this._translateService.instant(
                'SHARED.MESSAGES.FAILED_EDIT',
                {
                  case: shippingTranslation
                }
              );
              return of(
                storeSettingsActions.updateShippingInformationFailure({
                  payload: {
                    result: {
                      message: errMsg,
                      messageType: StoreActionsMessageTypesList.Error
                    }
                  }
                })
              );
            })
          );
      })
    )
  );

  addNewLanguageEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(addNewLanguage),
      switchMap((action) => {
        const appId = action.appId;
        const language = action.language;
        const languageDto = {
          isDefault: false,
          languageId: language.id
        };
        const languageTranslation =
          this._translateService.instant('SHARED.LANGUAGE');
        return this._languageService.addNewLanguage(appId, languageDto).pipe(
          map((apiResponse) => {
            const successMessage = this._translateService.instant(
              'SHARED.MESSAGES.SUCCESSFUL_ADD',
              {
                case: languageTranslation
              }
            );
            return addNewLanguageSuccess({
              payload: {
                newLanguage: language,
                result: {
                  message: successMessage,
                  messageType: StoreActionsMessageTypesList.Success
                }
              }
            });
          }),
          catchError((error) => {
            const errMsg = this._translateService.instant(
              'SHARED.MESSAGES.FAILED_ADD',
              {
                case: languageTranslation
              }
            );
            return of(
              addNewLanguageFailure({
                payload: {
                  result: {
                    message: errMsg,
                    messageType: StoreActionsMessageTypesList.Error
                  }
                }
              })
            );
          })
        );
      })
    )
  );

  deleteAppLanguageEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(deleteAppLanguage),
      switchMap((action) => {
        const appId = action.appId;
        const languageId = action.languageId;
        const languageTranslation = this._translateService.instant(
          'GENERAL_SETTINGS.LANGUAGE_LOCALIZATION'
        );
        return this._languageService
          .deleteLanguageFromApp(appId, languageId)
          .pipe(
            map((apiResponse) => {
              const successMessage = this._translateService.instant(
                'SHARED.MESSAGES.SUCCESSFUL_DELETE',
                {
                  case: languageTranslation
                }
              );
              return deleteAppLanguageSuccess({
                payload: {
                  deletedLanguageId: languageId,
                  result: {
                    message: successMessage,
                    messageType: StoreActionsMessageTypesList.Success
                  }
                }
              });
            }),
            catchError((error) => {
              const errMsg = this._translateService.instant(
                'SHARED.MESSAGES.FAILED_DELETE',
                {
                  case: languageTranslation
                }
              );
              return of(
                deleteAppLanguageFailure({
                  payload: {
                    result: {
                      message: errMsg,
                      messageType: StoreActionsMessageTypesList.Error
                    }
                  }
                })
              );
            })
          );
      })
    )
  );

  changeDefaultLanguageOfAppEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(changeDefaultLanguageOfApp),
      switchMap((action) => {
        const appId = action.appId;
        const languageId = action.languageId;
        return this._languageService
          .changeDefaultLanguageOfApp(appId, languageId)
          .pipe(
            map((apiResponse) => {
              const successMessage = this._translateService.instant(
                'SHARED.MESSAGES.SUCCESSFUL_EDIT',
                {
                  case: this._translateService.instant(
                    'STORE_SETTINGS.LANGUAGE_LOCALIZATION_PAGE.DEFAULT_LANGUAGE'
                  )
                }
              );
              return changeDefaultLanguageOfAppSuccess({
                payload: {
                  newDefaultLanguageId: languageId,
                  result: {
                    message: successMessage,
                    messageType: StoreActionsMessageTypesList.Success
                  }
                }
              });
            }),
            catchError((error) => {
              let errorMsg = this._translateService.instant(
                'SHARED.MESSAGES.REQUEST_FAILED'
              );
              if (error != null) {
                if (error?.error?.key == 'CantChangeDefaultLanguage') {
                  errorMsg = this._translateService.instant(
                    'GENERAL_SETTINGS.CHANGE_DEFAULT_LANGUAGE_ERROR'
                  );
                }
              }
              return of(
                changeDefaultLanguageOfAppFailure({
                  payload: {
                    result: {
                      message: errorMsg,
                      messageType: StoreActionsMessageTypesList.Error
                    }
                  }
                })
              );
            })
          );
      })
    )
  );

  deleteRegionEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(storeSettingsActions.deleteRegion),
      switchMap((action) => {
        const appId = action.payload.appId;
        const regionId = action.payload.regionId;
        const regionTranslation =
          this._translateService.instant('SHARED.REGION');
        return this._regionService.deleteRegion(appId, regionId).pipe(
          map((apiResponse) => {
            const successMessage = this._translateService.instant(
              'SHARED.MESSAGES.SUCCESSFUL_DELETE',
              {
                case: regionTranslation
              }
            );
            return storeSettingsActions.deleteRegionSuccess({
              payload: {
                deletedRegionId: regionId,
                result: {
                  message: successMessage,
                  messageType: StoreActionsMessageTypesList.Success
                }
              }
            });
          }),
          catchError((error) => {
            const errorMsg = this._translateService.instant(
              'SHARED.MESSAGES.FAILED_DELETION',
              {
                case: regionTranslation
              }
            );
            return of(
              storeSettingsActions.deleteRegionFailure({
                payload: {
                  result: {
                    message: errorMsg,
                    messageType: StoreActionsMessageTypesList.Error
                  }
                }
              })
            );
          })
        );
      })
    )
  );

  addNewRegionEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(storeSettingsActions.addNewRegion),
      switchMap((action) => {
        const appId = action.payload.appId;
        const payload = action.payload.dto;
        const regionTranslation =
          this._translateService.instant('SHARED.REGION');
        return this._regionService.addRegion(appId, payload).pipe(
          map((apiResponse) => {
            const successMessage = this._translateService.instant(
              'SHARED.MESSAGES.SUCCESSFUL_ADD',
              {
                case: regionTranslation
              }
            );
            return storeSettingsActions.addNewRegionSuccess({
              payload: {
                addedRegion: apiResponse.data,
                result: {
                  message: successMessage,
                  messageType: StoreActionsMessageTypesList.Success
                }
              }
            });
          }),
          catchError((error) => {
            const errorMsg = this._translateService.instant(
              'SHARED.MESSAGES.FAILED_ADD',
              {
                case: regionTranslation
              }
            );
            return of(
              storeSettingsActions.addNewRegionFailure({
                payload: {
                  result: {
                    message: errorMsg,
                    messageType: StoreActionsMessageTypesList.Error
                  }
                }
              })
            );
          })
        );
      })
    )
  );

  updateExisitingRegionEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(storeSettingsActions.updateExisitingRegion),
      switchMap((action) => {
        const appId = action.payload.appId;
        const regionId = action.payload.regionId;
        const payload = action.payload.dto;
        const regionTranslation =
          this._translateService.instant('SHARED.REGION');
        return this._regionService.updateRegion(appId, regionId, payload).pipe(
          map((apiResponse) => {
            const successMessage = this._translateService.instant(
              'SHARED.MESSAGES.SUCCESSFUL_EDIT',
              {
                case: regionTranslation
              }
            );
            return storeSettingsActions.updateExistingRegionSuccess({
              payload: {
                updatedRegion: apiResponse.data,
                result: {
                  message: successMessage,
                  messageType: StoreActionsMessageTypesList.Success
                }
              }
            });
          }),
          catchError((error) => {
            const errorMsg = this._translateService.instant(
              'SHARED.MESSAGES.FAILED_EDIT',
              {
                case: regionTranslation
              }
            );
            return of(
              storeSettingsActions.updateExistingRegionFailure({
                payload: {
                  result: {
                    message: errorMsg,
                    messageType: StoreActionsMessageTypesList.Error
                  }
                }
              })
            );
          })
        );
      })
    )
  );

  loadListOfSlidersEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(storeSettingsActions.loadListOfSliders),
      withLatestFrom(this._store.select(selectStoreSlidersWithAppId)),
      switchMap((actions) => {
        const currentAppId = actions[0].payload.appId;
        const previousAppId = actions[1]?.appId;
        const previousSliders = actions[1]?.sliders;
        if (currentAppId == previousAppId && previousSliders != null) {
          return of(
            storeSettingsActions.loadListOfSlidersSuccess({
              sliders: previousSliders
            })
          );
        }

        const sliderType = actions[0].payload.sliderType;
        return this._sliderService.getSliders(currentAppId, sliderType).pipe(
          map(
            (apiResponse: any) => {
              return storeSettingsActions.loadListOfSlidersSuccess({
                sliders: apiResponse
              });
            },
            catchError((error) => {
              storeSettingsActions.loadListOfSlidersFailure({
                payload: {
                  result: {
                    message: this._translateService.instant(
                      'SHARED.MESSAGES.FETCH_FAILED'
                    ),
                    messageType: StoreActionsMessageTypesList.Error
                  }
                }
              });
              return EMPTY;
            })
          )
        );
      })
    )
  );

  addSliderEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(storeSettingsActions.addSlider),
      switchMap((action) => {
        const appId = action.payload.appId;
        const sliderData = action.payload.dto;
        return this._sliderService.addNewSlideWithoutId(appId, sliderData).pipe(
          map((apiResponse: any) => {
            return storeSettingsActions.addSliderSuccess({
              newlyAddedSlider: apiResponse.data
            });
          }),
          catchError((error) => {
            return of(
              storeSettingsActions.addSliderFailure({
                payload: {
                  result: {
                    message: this._translateService.instant(
                      'SHARED.MESSAGES.FETCH_FAILED'
                    ),
                    messageType: StoreActionsMessageTypesList.Error
                  }
                }
              })
            );
          })
        );
      })
    )
  );

  addSlideEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(storeSettingsActions.addSlide),
      switchMap((action) => {
        const appId = action.payload.appId;
        const slideDto = action.payload.slide;
        const sliderId = action.payload.sliderId;
        const slideTranslation = this._translateService.instant(
          'STORE_SETTINGS.SLIDER.SLIDE'
        );

        return this._sliderService.addNewSlide(appId, slideDto, sliderId).pipe(
          map(
            (apiResponse: any) => {
              const msg = this._translateService.instant(
                'SHARED.MESSAGES.SUCCESSFUL_ADD',
                {
                  case: slideTranslation
                }
              );
              return storeSettingsActions.addSlideSuccess({
                payload: {
                  sliderWithLastChanges: apiResponse,
                  result: {
                    message: msg,
                    messageType: StoreActionsMessageTypesList.Success
                  }
                }
              });
            },
            catchError((error) => {
              const msg = this._translateService.instant(
                'SHARED.MESSAGES.FAILED_ADD',
                {
                  case: slideTranslation
                }
              );
              return of(
                storeSettingsActions.addSlideFailure({
                  payload: {
                    result: {
                      message: msg,
                      messageType: StoreActionsMessageTypesList.Error
                    }
                  }
                })
              );
            })
          )
        );
      })
    )
  );

  updateExisitingSlideEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(storeSettingsActions.updateExisitingSlide),
      switchMap((action) => {
        const appId = action.payload.appId;
        const slideDto = action.payload.slide;
        const sliderId = action.payload.sliderId;
        const slideTranslation = this._translateService.instant(
          'STORE_SETTINGS.SLIDER.SLIDE'
        );
        return this._sliderService.updateSlide(appId, slideDto, sliderId).pipe(
          map((apiResponse: any) => {
            const msg = this._translateService.instant(
              'SHARED.MESSAGES.SUCCESSFUL_EDIT',
              {
                case: slideTranslation
              }
            );
            return storeSettingsActions.updateExisitingSlideSuccess({
              payload: {
                sliderWithLastChanges: apiResponse,
                result: {
                  message: msg,
                  messageType: StoreActionsMessageTypesList.Success
                }
              }
            });
          }),
          catchError((error) => {
            const errorMsg = this._translateService.instant(
              'SHARED.MESSAGES.FAILED_EDIT',
              {
                case: slideTranslation
              }
            );
            return of(
              storeSettingsActions.updateExisitingSlideFailure({
                payload: {
                  result: {
                    message: errorMsg,
                    messageType: StoreActionsMessageTypesList.Error
                  }
                }
              })
            );
          })
        );
      })
    )
  );

  deleteSlideEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(storeSettingsActions.deleteSlide),
      switchMap((action) => {
        const appId = action.payload.appId;
        const slideId = action.payload.slideId;
        const sliderId = action.payload.sliderId;
        const slideTranslation = this._translateService.instant(
          'STORE_SETTINGS.SLIDER.SLIDE'
        );
        return this._sliderService.deleteSlide(appId, sliderId, slideId).pipe(
          map((apiResponse: any) => {
            const msg = this._translateService.instant(
              'SHARED.MESSAGES.SUCCESSFUL_DELETE',
              {
                case: slideTranslation
              }
            );
            return storeSettingsActions.deleteSlideSuccess({
              payload: {
                slideId: slideId,
                result: {
                  message: msg,
                  messageType: StoreActionsMessageTypesList.Success
                }
              }
            });
          }),
          catchError((error) => {
            const msg = this._translateService.instant(
              'SHARED.MESSAGES.FAILED_DELETION',
              {
                case: slideTranslation
              }
            );
            return of(
              storeSettingsActions.deleteSlideFailure({
                payload: {
                  result: {
                    message: msg,
                    messageType: StoreActionsMessageTypesList.Error
                  }
                }
              })
            );
          })
        );
      })
    )
  );

  private _getCurrency(currencyList, currencyIsoName): string {
    const currency = currencyList.find((x) => x.isoName == currencyIsoName);
    return `${currency.symbol} / ${currency.isoName}`;
  }

  private _getLanguage(languageList, languageIso2): string {
    return languageList.find((x) => x.isO2 == languageIso2)?.displayName;
  }

  private _getRegions(countryList, regionsInfo): string[] {
    const countriesName: string[] = [];
    regionsInfo.map((regionInfo) => {
      if (regionInfo.length > 2 && regionInfo.length < 32) {
        countriesName.push(regionInfo);
      }
      if (regionInfo.length == 32) {
        const countryName = countryList.find((x) => x.id == regionInfo)?.name;
        countriesName.push(countryName);
      } else if (regionInfo.length == 2) {
        const countryName = countryList.find((x) => x.isO2 == regionInfo)?.name;
        countriesName.push(countryName);
      }
    });
    return countriesName;
  }

  constructor(
    private _store: Store,
    private _actions$: Actions,
    private _storeService: StoreService,
    private _sliderService: SliderService,
    private _layoutService: LayoutService,
    private _regionService: RegionService,
    private _sharedService: SharedService,
    private _languageService: LanguageService,
    private _paymentService: PaymentService,
    private _translateService: TranslateService,
    private _shippingAndDeliveryService: ShippingAndDeliveryService
  ) {}
}
