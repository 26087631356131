import { END_POINTS_URL } from '@shopiroller/data';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class AppSumoService {
  constructor(private httpClient: HttpClient) {}

  public checkCurrentUserMembershipStatus(): Observable<boolean> {
    const URL = `${END_POINTS_URL.V2_1.PACKAGES}/check-user`;
    return this.httpClient
      .get(URL)
      .pipe(map((response: any) => response.data as boolean));
  }

  public getAppSumoPackagesCount(): Observable<any> {
    const URL = `${END_POINTS_URL.V2_1.APPS_URL}/package/count`;
    return this.httpClient.get(URL);
  }

  public getPackageInfo(): Observable<any> {
    const URL = `${END_POINTS_URL.V2_1.PACKAGES}`;
    return this.httpClient.get(URL);
  }

  public getProjectList(
    fetchAppSumoProjects: boolean | null = null,
    currentPage = 1,
    pageSize = 5
  ): Observable<any> {
    let URL = `${END_POINTS_URL.V2_1.PROFILE_URL}/apps?Page=${currentPage}&PerPage=${pageSize}&SortBy=Descending&Sort=App.EditedAt`;
    if (fetchAppSumoProjects != null) {
      URL += `&App.IsAppsumo=${fetchAppSumoProjects}`;
    }
    return this.httpClient.get(URL);
  }

  public upgradeProject(appId: string) {
    const URL = `${END_POINTS_URL.V2_1.APPS_URL}/${appId}/package`;
    return this.httpClient.put(URL, null);
  }

  public downgradeProject(appId: string) {
    const URL = `${END_POINTS_URL.V2_1.APPS_URL}/${appId}/package`;
    return this.httpClient.delete(URL);
  }
}
