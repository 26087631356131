export const SUBSCRIPTION_PACKAGES = [
  {
    type: 'Starter',
    features: [
      {
        en: '2% Transaction fee',
        tr: '%2 İşlem ücreti'
      },
      {
        en: 'Get paid with a link',
        tr: 'Link ile ödeme alma'
      },
      {
        en: 'Basic e-commerce site',
        tr: 'Temel e-ticaret sitesi'
      },
      {
        en: 'private domain',
        tr: 'Özel domain'
      },
      {
        en: 'Unlimited products, traffic and web space',
        tr: 'Sınırsız ürün, trafik ve web alanı'
      },
      {
        en: 'Campaign system',
        tr: 'Kampanya sistemi'
      },
      {
        en: '70% discount with contracted cargoes',
        tr: 'Anlaşmalı kargolar ile %70 indirim'
      },
      {
        en: 'WhatsApp integration',
        tr: 'WhatsApp entegrasyonu'
      }
    ],
    comingSoon: []
  },
  {
    type: 'Professional',
    features: [
      {
        en: '1% Transaction fee',
        tr: '%1 İşlem ücreti'
      },
      {
        en: 'All the features in the Starter package',
        tr: 'Başlangıç paketindeki tüm özellikler'
      }
    ],
    comingSoon: [
      {
        en: 'Native mobile application',
        tr: 'Native mobil uygulama',
        hasComingSoonBadge: false
      },
      {
        en: 'API & SDK',
        tr: 'API & SDK',
        hasComingSoonBadge: false
      },
      {
        en: 'Multilingualism',
        tr: 'Çok dillilik',
        hasComingSoonBadge: false
      },
      {
        en: 'Sales via different currencies',
        tr: 'Farklı kurlarda satış',
        hasComingSoonBadge: false
      },
      {
        en: 'Back in stock reminder',
        tr: 'Tekrar stokta hatırlatması',
        hasComingSoonBadge: true
      }
    ]
  },
  {
    type: 'Business',
    features: [
      {
        en: '0% Transaction fee',
        tr: '%0 İşlem ücreti'
      },
      {
        en: 'All the features in the Professional package',
        tr: 'Profesyonel paketindeki tüm özellikler'
      }
    ],
    comingSoon: [
      {
        en: 'Marketplace Integration',
        tr: 'Pazaryeri entegrasyonu',
        hasComingSoonBadge: true
      },
      {
        en: 'Cart Reminder',
        tr: 'Sepet hatırlatma',
        hasComingSoonBadge: false
      },
      {
        en: 'Product Reviews & Ratings',
        tr: 'Ürün yorum ve puanlama',
        hasComingSoonBadge: true
      },
      {
        en: 'Subscription System',
        tr: 'Abonelik sistemi',
        hasComingSoonBadge: true
      },
      {
        en: 'Up Sell & Cross Sell',
        tr: 'Yukarı Satış ve Çapraz Satış',
        hasComingSoonBadge: true
      }
    ]
  }
];
