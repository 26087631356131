import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from '@angular/router';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  loadSubscriptionInfo
} from '../../data-access';

@Injectable({
  providedIn: 'root'
})
export class AfterSubscriptionPaymentResolver implements Resolve<void> {
  constructor(private _store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    this._store.dispatch(loadSubscriptionInfo());
  }
}
