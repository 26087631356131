import { NgModule } from '@angular/core';
import { TreeOfCategoriesComponent } from './tree-of-categories.component';
import { TreeModule } from 'primeng/tree';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [TreeOfCategoriesComponent],
  exports: [TreeOfCategoriesComponent],
  imports: [TreeModule, FontAwesomeModule, TranslateModule]
})
export class TreeOfCategoriesModule {}
