import { CurrentSubscriptionPackageModel } from "./models";

export const businessPackageData:CurrentSubscriptionPackageModel = {
  duration: 'month',
  type: 'Business'
};

export const businessPackagePermissionListInfo = {
  apps_BackinStockReminder: 'true',
  apps_CartReminder: 'true',
  apps_WhatsApp: 'true',
  catalog_ProductVideo: 'true',
  catalog_Variant_max_count: '3',
  payment_birlesikodeme_active: 'true',
  payment_credicardatthedoor_active: 'true',
  payment_eft_active: 'true',
  payment_iyzico_active: 'true',
  payment_payatthedoor_active: 'true',
  payment_paypal_active: 'true',
  payment_paytr_active: 'true',
  payment_stripe_active: 'true',
  saleschannel_advancedsite_active: 'true',
  saleschannel_apisdk_active: 'true',
  saleschannel_basicsite_active: 'true',
  saleschannel_marketplace_active: 'true',
  saleschannel_mobileapp_active: 'true',
  saleschannel_shopilink_active: 'true',
  saleschannel_socialmedia_active: 'true',
  settings_AdvancedShippingSettings: 'true',
  settings_LocationBasedRouting: 'true',
  settings_MultiCurrency: 'true',
  settings_MultiLanguage: 'true',
  shopiroller_badge: 'falseğe'
};
