<form [formGroup]="seoOptionsForm" id="seo-container">
  <fieldset>
    <legend>{{ 'PRODUCTS.SEO_OPTIONS' | translate }}</legend>
    <div
      formArrayName="options"
      *ngFor="let t of seoOptions.controls; let i = index"
    >
      <div
        class="row"
        [formGroupName]="i"
        [hidden]="t.value.language != lastLanguageSelected?.isO2"
      >
        <div class="col-6 mt-3">
          <div class="form-group">
            <label
              >{{ 'PRODUCTS.SLUG' | translate }} ({{
                lastLanguageSelected?.isO2
              }}) *
            </label>
            <div>
              <input
                id="{{ 'slug' + i }}"
                class="form-control"
                name="slug"
                formControlName="slug"
                autocomplete="off"
                [disabled]="isFetchingSlugSuggestion == true"
              />
              <ng-template
                [ngTemplateOutlet]="formError"
                [ngTemplateOutletContext]="{
                  validation: 'required',
                  control: t.get('slug')
                }"
              ></ng-template>
            </div>
          </div>
        </div>
        <div class="col-6 mt-3">
          <div class="form-group">
            <label
              >{{ 'PRODUCTS.PAGE_TITLE' | translate }} ({{
                lastLanguageSelected?.isO2
              }}) *
            </label>
            <input
              class="form-control"
              name="pageTitle"
              formControlName="pageTitle"
              autocomplete="off"
              id="{{ 'pageTitle' + i }}"
            />
            <ng-template
              [ngTemplateOutlet]="formError"
              [ngTemplateOutletContext]="{
                validation: 'required',
                control: t.get('pageTitle')
              }"
            ></ng-template>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label
              >{{ 'PRODUCTS.DESCRIPTION' | translate }} ({{
                lastLanguageSelected?.isO2
              }}) *
            </label>
            <textarea
              class="form-control"
              rows="5"
              name="description"
              formControlName="description"
              id="{{ 'description' + i }}"
            ></textarea>
            <ng-template
              [ngTemplateOutlet]="formError"
              [ngTemplateOutletContext]="{
                control: t.get('description'),
                validation: 'required'
              }"
            ></ng-template>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</form>

<ng-template #formError let-control="control" let-validation="validation">
  <ng-container
    *ngIf="
      control?.hasError(validation) &&
      ((control?.invalid && control?.touched) ||
        (control?.dirty && control?.error))
    "
  >
    <p class="text-danger">
      {{ 'SHARED.VALIDATION.FIELD_REQUIRED' | translate }}
    </p>
  </ng-container>
</ng-template>
<app-animated-logo *ngIf="isLoading == true"></app-animated-logo>
