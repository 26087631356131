export interface DashboardPageModel {
  appId:string,
  lastCustomers: any[];
  lastOrders: any[];
  todayOrdersData: DashboardTodayOrdersDataModel,
  integrationSteps: any
}


export interface DashboardTodayOrdersDataModel{
    awaitingOrdersCount: number;
    totalOrders: number;
    totalSalesAmount: number;
}