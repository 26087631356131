import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Store } from '@ngrx/store';
import { selectUserAppSumoMembershipStatus } from '../../data-access';
import { map } from 'rxjs/internal/operators/map';

@Injectable({ providedIn: 'root' })
export class AppSumoGuard implements CanActivate {
  constructor(private readonly _store: Store, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this._store.select(selectUserAppSumoMembershipStatus).pipe(
      map((status) => {
        if (status == true) {
          return true;
        }
        this.router.navigate(['/stores/list']);
        return false;
      })
    );
  }
}
