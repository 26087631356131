import { AddProductListToShowcaseModel } from '../models/add-product-list-to-showcase.model';
import { END_POINTS_URL } from '@shopiroller/data';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { MultiLanguageSlugSuggestionModel } from '../../../data';

@Injectable({
  providedIn: 'root'
})
export class ShowcaseService {
  constructor(private http: HttpClient) {}

  public getShowcase(appId: string, showcaseId: string): Observable<any> {
    const url = `${END_POINTS_URL.V2.SHOW_CASE_URL}/${showcaseId}/apps/${appId}`;
    return this.http.get(url);
  }

  public filterShowCases(
    appId: string,
    showcaseName: string = '',
    page: number = 1,
    iso2Language: string,
    sortBy: string = 'Descending',
    sortField: 'CurrentName' | 'CreateDate' = 'CreateDate',
    ignorePassiveItem = false
  ): Observable<any> {
    const URL =
      showcaseName === ''
        ? `${END_POINTS_URL.V2.SHOW_CASE_URL}/ignore-product/apps/${appId}?iso2LanguageName=${iso2Language}&page=${page}&perPage=10&sortBy=${sortBy}&sort=${sortField}&ignorePassiveItem=${ignorePassiveItem}`
        : `${END_POINTS_URL.V2.SHOW_CASE_URL}/ignore-product/apps/${appId}?name=${showcaseName}&iso2LanguageName=${iso2Language}&page=${page}&perPage=10&sortBy=${sortBy}&sort=${sortField}&ignorePassiveItem=${ignorePassiveItem}`;
    return this.http.get(URL);
  }

  public getShowCaseList(appId: string): Observable<any> {
    const url = `${END_POINTS_URL.V2.SHOW_CASE_URL}/apps/${appId}`;
    return this.http.get(url);
  }

  public addNewShowcase(appId: string, newShowcase: any): Observable<boolean> {
    const url = `${END_POINTS_URL.V2.SHOW_CASE_URL}/apps/${appId}`;
    return this.http.post(url, newShowcase, { observe: 'response' }).pipe(
      map((result: any) => {
        return result.status === 200 ||
          result.status === 201 ||
          result.status === 204
          ? true
          : false;
      })
    );
  }

  public addProductToShowcase(appId: string, data: any): Observable<boolean> {
    const url = `${END_POINTS_URL.V2.SHOW_CASE_URL}/apps/${appId}/products`;
    return this.http.post(url, data, { observe: 'response' }).pipe(
      map((result: any) => {
        return result.status === 200 ||
          result.status === 201 ||
          result.status === 204
          ? true
          : false;
      })
    );
  }

  public addProductListToShowcase(
    appId: string,
    payload: AddProductListToShowcaseModel
  ): Observable<any> {
    const url = `${END_POINTS_URL.V2.SHOW_CASE_URL}/apps/${appId}/product-list`;
    return this.http.post(url, payload);
  }

  public updateShowcase(appId: string, showcase: any): Observable<boolean> {
    const url = `${END_POINTS_URL.V2.SHOW_CASE_URL}/${showcase.id}/apps/${appId}`;
    return this.http.put(url, showcase, { observe: 'response' }).pipe(
      map((result: any) => {
        return result.status === 200 ||
          result.status === 201 ||
          result.status === 204
          ? true
          : false;
      })
    );
  }

  public deleteShowCase(
    appId: string,
    showcaseId: string
  ): Observable<boolean> {
    const url = `${END_POINTS_URL.V2.SHOW_CASE_URL}/${showcaseId}/apps/${appId}`;
    return this.http.delete(url, { observe: 'response' }).pipe(
      map((result: any) => {
        return result.status === 200 ||
          result.status === 201 ||
          result.status === 204
          ? true
          : false;
      })
    );
  }

  public deleteProductInShowCase(
    appId: string,
    showcaseId: string,
    productId: string
  ): Observable<boolean> {
    const url = `${END_POINTS_URL.V2.SHOW_CASE_URL}/apps/${appId}/products?showcaseId=${showcaseId}&productId=${productId}`;
    return this.http.delete(url, { observe: 'response' }).pipe(
      map((result: any) => {
        return result.status === 200 ||
          result.status === 201 ||
          result.status === 204
          ? true
          : false;
      })
    );
  }

  public generateSlugSuggestion(
    appId: string,
    payload: MultiLanguageSlugSuggestionModel
  ): Observable<any> {
    const url = `${END_POINTS_URL.V2.SHOW_CASE_URL}/apps/${appId}/slugs`;
    return this.http.post(url, payload).pipe(map((result: any) => result.data));
  }
}
