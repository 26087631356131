export interface IAppSetting {
  currentAppId: string;
  showAsideMenu: boolean;
  apiKey: string;
  googleAuthApi: any;
  features: any;
}

export class AppSetting implements IAppSetting {
  constructor(
    public currentAppId: string,
    public showAsideMenu: boolean,
    public apiKey: string,
    public googleAuthApi: any,
    public features: any
  ) {}
}

export interface IPublicSetting {
  currentAppId: string;
  showAsideMenu: boolean;
  currentAppUpgradedToAppSumo: boolean;
}
export class PublicSetting implements IPublicSetting {
  constructor(
    public currentAppId: string,
    public showAsideMenu: boolean,
    public defaultPanelLanguage: string,
    public currentAppUpgradedToAppSumo: boolean
  ) {}
}

export interface ISettingState {
  publicConfiguration: PublicSetting;
}

export enum SettingFields {
  currentAppId = 'currentAppId',
  currentAppName = 'currentAppName',
  showAsideMenu = 'showAsideMenu',
  defaultPanelLanguage = 'defaultPanelLanguage',
}
