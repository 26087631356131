import {
  CurrentSubscriptionPackageModel,
  SubscriptionPackageModel
} from '../../data';
import { createAction, props } from '@ngrx/store';
import {
  SubscriptionInfoModel,
  SubscriptionInfoOfAppModel
} from '../../data/models/interfaces/subscription-info.model';

/*******************  Loading Subscription Packages Information  ***********************/
export const loadSubscriptionPackagesList = createAction(
  '[Subscription] Load Subscription Packages List'
);

export const loadSubscriptionPackagesListSuccess = createAction(
  '[Subscription] Load Subscription Packages List Success',
  props<{ response: SubscriptionPackageModel[] }>()
);

export const loadSubscriptionPackagesListFailure = createAction(
  '[Subscription] Load Subscription Packages List Failure',
  props<{ error: any }>()
);

/*******************  Loading Subscription Information  ***********************/
export const loadSubscriptionInfo = createAction(
  '[Subscription] Load Subscription Info'
);

export const loadSubscriptionInfoSuccess = createAction(
  '[Subscription] Load Subscription Info Success',
  props<{ response: SubscriptionInfoModel }>()
);

export const loadSubscriptionInfoFailure = createAction(
  '[Subscription] Load Subscription Info Failure',
  props<{ error: any }>()
);

/*******************  Loading Subscription Package Type  ***********************/
export const loadCurrentSubscriptionPackageType = createAction(
  '[Subscription] Load Current Subscription Package Type'
);

export const loadCurrentSubscriptionPackageTypeSuccess = createAction(
  '[Subscription] Load Current Subscription Package Type Success',
  props<{ response: CurrentSubscriptionPackageModel }>()
);

export const loadCurrentSubscriptionPackageTypeFailure = createAction(
  '[Subscription] Load Current Subscription Package Type Failure',
  props<{ error: any }>()
);

/*******************  Loading Subscription Information Of App  ***********************/
export const loadCurrentSubscriptionInformationOfApp = createAction(
  '[Subscription] Load Current Subscription Information Of App'
);

export const loadCurrentSubscriptionInformationOfAppSuccess = createAction(
  '[Subscription] Load Current Subscription Information Of App Success',
  props<{ response: SubscriptionInfoOfAppModel }>()
);

export const loadCurrentSubscriptionInformationOfAppFailure = createAction(
  '[Subscription] Load Current Subscription Information Of App Failure',
  props<{ error: any }>()
);
