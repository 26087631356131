import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { LOCAL_STORAGE_KEYS } from '@shopiroller/data';
import { Observable } from 'rxjs/internal/Observable';
import { UserService } from '../../data-access';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
  constructor(private _userService: UserService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let userAccessToken = this._userService.user?.accessToken;
    let finalRequest = req;
    if (
      (userAccessToken == undefined || userAccessToken == null) &&
      !(
        req.url.includes('auth/login') ||
        req.url.includes('auth/register') ||
        req.url.includes('auth/forgotPassword')
      )
    ) {
      userAccessToken =
        localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) ?? '';
    }

    if (userAccessToken) {
      finalRequest = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${userAccessToken}`)
      });
    }

    return next.handle(finalRequest);
  }
}
