import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IRefund, UpdateRefundModel } from '../../data/models/refund.model';

import { END_POINTS_URL } from '@shopiroller/data';
import { GetPaymentDetailsOfOrderModel } from '../../data/models/get-payment-details-of-order.model';
import { IOrderStatus } from '../../data/models/order-status.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  constructor(private http: HttpClient) {}

  public getSingleOrder(appId: string, orderId: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/orders/${orderId}`;
    return this.http.get(URL);
  }

  public filterOrders(
    appId: string,
    searchedOrderCode: string = '',
    currentPage: number,
    perPage: number,
    sortByType: 'Ascending' | 'Descending' = 'Descending',
    sortByOption:
      | 'CurrentStatus'
      | 'TotalPrice'
      | 'CreateDate'
      | 'OrderCode' = 'OrderCode',
    ...additionalFilters: string[]
  ): Observable<any> {
    let url = `${END_POINTS_URL.V2.APPS_URL}/${appId}/orders?orderCode=${searchedOrderCode}&page=${currentPage}&perPage=${perPage}&sortBy=${sortByType}&sort=${sortByOption}&isPaymentFailed=true`;
    if (additionalFilters.length > 0) {
      url = url + '&' + additionalFilters.join('&');
    }
    return this.http.get<any>(url);
  }

  public updateOrder(appId: string, order: any): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/orders/${order.id}`;
    return this.http.put(URL, order).pipe(
      map(() => {
        return true;
      })
    );
  }

  public updateOrderStatus(appId: string, data: IOrderStatus): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/orders/setStatus/${data.orderId}?orderStatus=${data.orderStatus}&sendEmail=${data.sendEmail}`;
    return this.http.patch(URL, {}).pipe(
      map(() => {
        return true;
      })
    );
  }

  public markAnOrderAsRead(
    appId: string,
    orderId: string,
    isRead: boolean
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/orders/${orderId}/markAsRead?isRead=${isRead}`;
    return this.http.patch(URL, {}).pipe(
      map(() => {
        return true;
      })
    );
  }

  public refundProduct(
    appId: string,
    appKey: string,
    orderId: string,
    productId: string,
    data: IRefund
  ): Observable<any> {
    const url = `${END_POINTS_URL.V2.APPS_URL}/${appId}/orders/${orderId}/products/${productId}/refund`;
    const header = {
      headers: new HttpHeaders({
        'app-Key': appKey
      })
    };
    return this.http.post<any>(url, data, header);
  }

  public refundProducts(
    appId: string,
    orderId: string,
    data: UpdateRefundModel
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/orders/${orderId}/refund`;
    return this.http.post<any>(URL, data);
  }

  public getOrdersOfClient(appId: string, buyerId: string): Observable<any> {
    const url = `${END_POINTS_URL.V2.APPS_URL}/${appId}/orders/buyers/${buyerId}`;
    return this.http.get<any>(url);
  }

  public getUnreadOrdersCount(appId: string): Observable<any> {
    const url = `${END_POINTS_URL.V2.APPS_URL}/${appId}/orders/unreads/count`;
    return this.http.get<any>(url);
  }

  public getPaymentDetailsOfOrder(
    payload: GetPaymentDetailsOfOrderModel
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${payload.appId}/orders/orders/${payload.orderId}`;
    return this.http.get<any>(URL);
  }

  public getOrderBalance(
    appId: string,
    currency: 'TRY' | 'USD' | 'EUR' | 'GBP' | '' = ''
  ): Observable<any> {
    let URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/orders/balance`;
    if (currency != '') {
      URL += `?currency=${currency}`;
    }
    return this.http.get<any>(URL);
  }
}
