import { ISubscriptionProduct } from './subscription-product.interface';
import { SubscriptionTypes } from '../types';

export interface ISubscriptionPlan {
  type: SubscriptionTypes;
  duration: 'month' | 'year';
  features: any;
  products: ISubscriptionProduct[];
  prices?: any[];
  currentAppUpgradedToAppSumo: boolean;
}
