import {
  ApplicationsListResult,
  IApplicationModel
} from '../../models/application.model';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { finalize } from 'rxjs/internal/operators/finalize';
import { map } from 'rxjs/internal/operators/map';
import { END_POINTS_URL,OPERATION_RESULT } from '@shopiroller/data';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppListResultModel } from '../../models';

const APPLICATION_API_URL = `${END_POINTS_URL.V2_1.PROFILE_URL}/apps`;
@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {
  private applicationListSubject: BehaviorSubject<ApplicationsListResult>;
  private selectedApplicationSubject: BehaviorSubject<any>;
  public isLoading$: Observable<boolean>;
  public isLoadingSubject: BehaviorSubject<boolean>;
  public applicationList$: Observable<ApplicationsListResult>;
  public selectedApplication$: Observable<any>;

  private searchedAppSubject: BehaviorSubject<string>;
  public searchedApp$: Observable<string>;

  constructor(private http: HttpClient) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();

    const emptyApplicationList = new ApplicationsListResult();
    this.applicationListSubject = new BehaviorSubject<ApplicationsListResult>(
      emptyApplicationList
    );
    this.applicationList$ = this.applicationListSubject.asObservable();

    this.selectedApplicationSubject = new BehaviorSubject<any>(null);
    this.selectedApplication$ = this.selectedApplicationSubject.asObservable();

    this.searchedAppSubject = new BehaviorSubject<string>('NotSearched');
    this.searchedApp$ = this.searchedAppSubject.asObservable();
  }

  public appList(
    appName?: string,
    page: number = 1,
    perPage: number = 8
  ): Observable<AppListResultModel> {
    this.isLoadingSubject.next(true);
    const url =
      appName !== undefined && appName !== ''
        ? `${APPLICATION_API_URL}?App.Name=${appName}&Page=${page}&PerPage=9&SortBy=Descending&Sort=App.EditedAt`
        : `${APPLICATION_API_URL}?Page=${page}&PerPage=${perPage}&SortBy=Descending&Sort=App.EditedAt`;
    return this.http.get(url).pipe(
      map((result: any) => {
        this.applicationListSubject.next(result);
        return result as AppListResultModel;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  public addApp(data: IApplicationModel): Observable<any[]> {
    this.isLoadingSubject.next(true);
    const url = `${APPLICATION_API_URL}`;
    return this.http.post<any>(url, data).pipe(
      map((result: any) => {
        return result;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  public editApp(app: IApplicationModel): Observable<OPERATION_RESULT> {
    this.isLoadingSubject.next(true);
    const url = `${APPLICATION_API_URL}/${app.id}`;
    return this.http.put<any>(url, app, { observe: 'response' }).pipe(
      map((result: any) => {
        return result.status === 200 || result.status === 204
          ? OPERATION_RESULT.SUCCESSFUL
          : OPERATION_RESULT.FAILED;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  public deleteApp(appId: string): Observable<any[]> {
    this.isLoadingSubject.next(true);
    const url = `${APPLICATION_API_URL}/${appId}`;
    return this.http.delete<any>(url).pipe(
      map((result: any) => {
        return result;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  public updateApplicationListSubject(newVal: ApplicationsListResult): void {
    this.applicationListSubject.next(newVal);
  }

  public updateSelectedApplicationSubject(newVal: any): void {
    this.selectedApplicationSubject.next(newVal);
  }

  public getSelectedApplicationSubjectValue(): any {
    return this.selectedApplicationSubject.value;
  }

  public updateSearchedAppSubject(appName: string): void {
    this.searchedAppSubject.next(appName);
  }

  public getSingleApplication(appId: string): Observable<any> {
    const url = `${APPLICATION_API_URL}/${appId}`;
    let selectedAppInfo: any = null;
    return this.http.get<any>(url).pipe(
      map((result: any) => {
        selectedAppInfo = result;
        return result;
      }),
      finalize(() => this.updateSelectedApplicationSubject(selectedAppInfo))
    );
  }
}
