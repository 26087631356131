import { Observable } from 'rxjs/internal/Observable';
 import { HttpClient } from '@angular/common/http';
import { ISendySubscribe } from '../models/sendy-subscribe.interface';
import { Injectable } from '@angular/core';
import { share } from 'rxjs/internal/operators/share';
import { END_POINTS_URL } from '@shopiroller/data';

@Injectable({
  providedIn: 'root',
})
export class SendyService {
  constructor(private readonly httpClient: HttpClient) {}

  subscribe(payload: ISendySubscribe): Observable<any> {
    return this.httpClient
      .post(END_POINTS_URL.V2.SENDY_SUBSCRIBE_URL, payload)
      .pipe(share());
  }
}
