import { Routes } from '@angular/router';

export const BRAND_ROUTES: Routes = [
  {
    path: '',
    loadComponent: async () =>
      (await import('../features/brands/brands.component')).BrandsComponent
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];
