import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';

import { selectFeaturePermissionInCurrentPackage } from '../../../data-access';
import { Store } from '@ngrx/store';
import { SubscriptionFeaturesEnum } from '../../../data';

@Directive({
  selector: '[subscriptionFeature]',
  standalone: true
})
export class SubscriptionFeatureDirective implements OnInit {
  @Input() subscriptionFeature: SubscriptionFeaturesEnum;

  constructor(
    private _store: Store,
    private _tpl: TemplateRef<any>,
    private _vcr: ViewContainerRef
  ) {}

  ngOnInit() {
    this._store
      .select(selectFeaturePermissionInCurrentPackage(this.subscriptionFeature))
      .subscribe((featureIsEnabled) => {
        if (featureIsEnabled) {
          this._vcr.createEmbeddedView(this._tpl);
        }
      });
  }
}
