import { GenericState, GenericStoreStatus } from '@shopiroller/core';
import {
  CreateWebHook,
  CreateWebHookFailure,
  CreateWebHookSuccess,
  DeleteWebHook,
  DeleteWebHookFailure,
  DeleteWebHookSuccess,
  UpdateWebHook,
  UpdateWebHookFailure,
  UpdateWebHookSuccess,
  checkWebhookActiveness,
  checkWebhookActivenessFailure,
  checkWebhookActivenessSuccess,
  loadListOfWebHooks,
  loadListOfWebHooksFailure,
  loadListOfWebHooksSuccess,
  loadListOfWebHooksTypes,
  loadListOfWebHooksTypesFailure,
  loadListOfWebHooksTypesSuccess
} from './web-hook.actions';
import { createReducer, on } from '@ngrx/store';

export const webHookFeatureKey = 'feature-web-hook';

export class WebHookFeatureState implements GenericState<any> {
  appId?: string | null;
  list: any;
  types: any;
  isActive!: boolean | null;
  status: GenericStoreStatus | any = 'pending';
  error: any = null;
}

const initialState: WebHookFeatureState = {
  appId: null,
  list: null,
  types: null,
  isActive: null,
  error: null,
  status: 'pending'
};

export const webHookFeatureReducer = createReducer(
  initialState,
  on(loadListOfWebHooks, (state) => ({ ...state, status: 'loading' })),
  on(loadListOfWebHooksSuccess, (state, { response }) => ({
    ...state,
    appId: response.appId,
    list: response.data,
    status: 'success',
    error: null
  })),
  on(loadListOfWebHooksFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(loadListOfWebHooksTypes, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(loadListOfWebHooksTypesSuccess, (state, { response }) => ({
    ...state,
    types: response,
    status: 'success',
    error: null
  })),
  on(loadListOfWebHooksTypesFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(CreateWebHook, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(CreateWebHookSuccess, (state, { payload }) => {
    const newListData = [...state.list];
    newListData.push(payload.newHook);
    return {
      ...state,
      list: newListData,
      status: 'success',
      error: null
    };
  }),
  on(CreateWebHookFailure, (state, { payload }) => ({
    ...state,
    status: 'error',
    error: payload.result.message
  })),
  on(UpdateWebHook, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(UpdateWebHookSuccess, (state, { payload }) => {
    const hooksListData = [...state.list];
    const updatedItemIndex = hooksListData.findIndex(
      (x) => x.id == payload.updatedHook.id
    );
    hooksListData[updatedItemIndex] = payload.updatedHook;
    return {
      ...state,
      list: hooksListData,
      status: 'success',
      error: null
    };
  }),
  on(UpdateWebHookFailure, (state, { payload }) => ({
    ...state,
    status: 'error',
    error: payload.result.message
  })),
  on(DeleteWebHook, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(DeleteWebHookSuccess, (state, { payload }) => {
    const newList = state.list.filter(
      (x: any) => x.id != payload.deletedHookId
    );
    return {
      ...state,
      list: newList,
      status: 'success',
      error: null
    };
  }),
  on(DeleteWebHookFailure, (state, { payload }) => ({
    ...state,
    status: 'error',
    error: payload.result.message
  })),
  on(checkWebhookActiveness, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(checkWebhookActivenessSuccess, (state, { response }) => ({
    ...state,
    appId: response.appId,
    isActive: response.isActive,
    status: 'success'
  })),
  on(checkWebhookActivenessFailure, (state, { payload }) => ({
    ...state,
    isActive: false,
    status: 'error',
    error: payload.result.message
  }))
);
