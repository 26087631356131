import { IFeatureData } from "libs/shared/data/src/lib/features";

export interface IAppConfiguration {
  name: string;
  production: boolean;
  appVersion: string;
  apiKey: string;
  shopiroller_API_URL: string;
  identity_API_URL: string;
  storage_API_URL: string;
  users_API_URL: string;
  storageContainerName: string;
  fileCDNBaseURL: string;
  shopilinkBaseURL: string;
  panelBaseURL: string;
  testUser: {
    userName: string;
    password: string;
  };
  features: IFeatureData[];
  googleAuthApi: any;
  sentryConfig: any;
  frillWidgetKey: string;
}
