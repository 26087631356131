import {
  CurrentSubscriptionPackageModel,
  SubscriptionPackageModel,
  SubscriptionPermissionType
} from '../../data/models';
import { GenericState, GenericStoreStatus } from '@shopiroller/core';
import { createReducer, on } from '@ngrx/store';
import {
  loadCurrentSubscriptionInformationOfApp,
  loadCurrentSubscriptionInformationOfAppFailure,
  loadCurrentSubscriptionInformationOfAppSuccess,
  loadCurrentSubscriptionPackageType,
  loadCurrentSubscriptionPackageTypeFailure,
  loadCurrentSubscriptionPackageTypeSuccess,
  loadSubscriptionInfo,
  loadSubscriptionInfoFailure,
  loadSubscriptionInfoSuccess,
  loadSubscriptionPackagesList,
  loadSubscriptionPackagesListFailure,
  loadSubscriptionPackagesListSuccess
} from './subscription.actions';

export const subscriptionFeatureKey = 'feature-subscription';

export class SubscriptionFeatureState implements GenericState<any> {
  packagesInfo!: SubscriptionPackageModel[] | null;
  currentPackage!: CurrentSubscriptionPackageModel;
  currentPackagePermissionList!: SubscriptionPermissionType | null;
  status: GenericStoreStatus | any = 'pending';
  error: any = null;
}

const initialState: SubscriptionFeatureState = {
  packagesInfo: null,
  currentPackage: {
    duration: 'month',
    type: 'Starter'
  },
  currentPackagePermissionList: null,
  error: null,
  status: 'pending'
};

export const subscriptionFeatureReducer = createReducer(
  initialState,
  on(loadSubscriptionPackagesList, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(loadSubscriptionPackagesListSuccess, (state, { response }) => ({
    ...state,
    packagesInfo: response,
    status: 'success',
    error: null
  })),
  on(loadSubscriptionPackagesListFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(loadCurrentSubscriptionPackageType, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(loadCurrentSubscriptionPackageTypeSuccess, (state, { response }) => {
    return {
      ...state,
      currentPackage: response,
      status: 'success',
      error: null
    };
  }),
  on(loadCurrentSubscriptionPackageTypeFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(loadSubscriptionInfo, (state) => ({ ...state, status: 'loading' })),
  on(loadSubscriptionInfoSuccess, (state, { response }) => {
    return {
      ...state,
      packagesInfo: response.packagesInfo,
      currentPackage: response.currentPackage,
      currentPackagePermissionList: response.currentPackagePermissionList,
      status: 'success',
      error: null
    };
  }),
  on(loadSubscriptionInfoFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(loadCurrentSubscriptionInformationOfApp, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(loadCurrentSubscriptionInformationOfAppSuccess, (state, { response }) => {
    return {
      ...state,
      currentPackage: response.currentPackage,
      currentPackagePermissionList: response.currentPackagePermissionList,
      status: 'success',
      error: null
    };
  }),
  on(loadCurrentSubscriptionInformationOfAppFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  }))
);
