import { AppRoutingModule } from './routes/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from '@shopiroller/core';
import { HighlightModule } from 'ngx-highlightjs';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';
import { SplashScreenModule } from './shared/@metronic/partials/layout/splash-screen/splash-screen.module';
import { TranslateModule } from '@ngx-translate/core';
import { ToastModule } from 'primeng/toast';

export const APP_IMPORTS = [
  BrowserModule,
  BrowserAnimationsModule,
  SplashScreenModule,
  TranslateModule.forRoot(),
  HttpClientModule,
  HighlightModule,
  AppRoutingModule,
  InlineSVGModule.forRoot(),
  ToastModule,
  CoreModule
];
