import { END_POINTS_URL } from '@shopiroller/data';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class ImageUploaderService {
  constructor(private http: HttpClient) {}

  uploadedImage(
    appId: string,
    containerName: string,
    image: any
  ): Observable<any> {
    const E_COMMERCE_IMAGE_URL = `${END_POINTS_URL.V1.OPERATION_APPS_URL}/${appId}/ecommerceImage`;
    return this.http.post(E_COMMERCE_IMAGE_URL, image).pipe(
      map((result: any) => {
        if (result.success === true) {
          return result.data;
        }
      })
    );
  }
}
