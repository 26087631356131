import { createReducer, on } from '@ngrx/store';
import {
  activateWhatsapp,
  activateWhatsappFailure,
  activateWhatsappSuccess,
  loadAppStoreData,
  loadAppStoreDataFailure,
  loadAppStoreDataSuccess,
  updateCartReminder,
  updateCartReminderFailure,
  updateCartReminderSuccess,
  updateWhatsapp,
  updateWhatsappFailure,
  updateWhatsappSuccess
} from './app-store.actions';
import { AppStoreDataModel } from '../../data/models';
import { GenericState, GenericStoreStatus } from '@shopiroller/core';

export const AppStoreFeatureKey = 'feature-app-store';

export class AppStoreFeatureState implements GenericState<any> {
  appId?: string | null;
  featuresData!: AppStoreDataModel;
  status: GenericStoreStatus | any = 'pending';
  error: any = null;
}

const initialState: AppStoreFeatureState = {
  appId: null,
  featuresData: {
    cartReminder: null,
    whatsapp: null
  },
  error: null,
  status: 'pending'
};

export const AppStoreFeatureReducer = createReducer(
  initialState,
  on(loadAppStoreData, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(loadAppStoreDataSuccess, (state, { response }) => {
    return {
      ...state,
      appId: response.appId,
      featuresData: response.featuresData,
      status: 'success',
      error: null
    };
  }),
  on(loadAppStoreDataFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  })),
  on(updateCartReminder, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(updateCartReminderSuccess, (state, { payload }) => {
    const featuresData = Object.assign({}, state.featuresData);
    featuresData.cartReminder = payload.updatedCartReminder;
    return {
      ...state,
      featuresData: featuresData,
      status: 'success',
      error: null
    };
  }),
  on(updateCartReminderFailure, (state, { payload }) => ({
    ...state,
    status: 'error',
    error: payload.result.message
  })),
  on(activateWhatsapp, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(activateWhatsappSuccess, (state, { payload }) => {
    const featuresData = Object.assign({}, state.featuresData);
    featuresData.whatsapp = payload.updatedWhatsapp;
    return {
      ...state,
      featuresData: featuresData,
      status: 'success',
      error: null
    };
  }),
  on(activateWhatsappFailure, (state, { payload }) => ({
    ...state,
    status: 'error',
    error: payload.result.message
  })),
  on(updateWhatsapp, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(updateWhatsappSuccess, (state, { payload }) => {
    const featuresData = Object.assign({}, state.featuresData);
    featuresData.whatsapp = payload.updatedWhatsapp;
    return {
      ...state,
      featuresData: featuresData,
      status: 'success',
      error: null
    };
  }),
  on(updateWhatsappFailure, (state, { payload }) => ({
    ...state,
    status: 'error',
    error: payload.result.message
  }))
);
