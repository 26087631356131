import { END_POINTS_URL } from '@shopiroller/data';
import { HttpClient } from '@angular/common/http';
import { IRegion } from '../../data/models/region.interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root',
})
export class RegionService {
  constructor(private _http: HttpClient) {}

  public getRegions(appId: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.ZONE_URL}/apps/${appId}?sortBy=Descending&sort=CreateDate`;
    return this._http.get(URL);
  }

  public getRegionById(appId: string, regionId: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.ZONE_URL}/apps/${appId}?sortBy=Descending&sort=CreateDate`;
    return this._http.get(URL).pipe(
      map((result: any) => {
        if (result.data.length == 0) {
          throw new Error('Region not found');
        } else return result.data.find((x) => x.id == regionId);
      })
    );
  }

  public addRegion(appId: string, Region: IRegion): Observable<any> {
    const URL = `${END_POINTS_URL.V2.ZONE_URL}/apps/${appId}`;
    return this._http.post(URL, Region);
  }

  public updateRegion(
    appId: string,
    id: String,
    Region: IRegion
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.ZONE_URL}/apps/${appId}/${id}`;
    return this._http.put(URL, Region);
  }

  public deleteRegion(appId: string, id: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.ZONE_URL}/apps/${appId}/${id}`;
    return this._http.delete(URL);
  }
}
