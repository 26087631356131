import { createAction, props } from '@ngrx/store';
import { StoreActionsMessageTypesList } from '@shopiroller/core';
import { ICategoryDisplayType, SalesChannelTypes } from '../../data/models';
import { MagentyConnectModel } from '../../features/magenty/models/magenty.model';

/******************* Load web sale Channel Data  ***********************/
export const loadWebSaleChannelData = createAction(
  '[SalesChannel] Load Web Sale Channel Data',
  props<{ appId: string; expireCache: boolean }>()
);

export const loadWebSaleChannelDataSuccess = createAction(
  '[SalesChannel] Load Web Sale Channel Data Success',
  props<{ response: any }>()
);

export const loadWebSaleChannelDataFailure = createAction(
  '[SalesChannel] Load Web Sale Channel Data Failure',
  props<{ error: any }>()
);

/******************* Load Mobile Sale Channel Data  ***********************/
export const loadMobileSaleChannelData = createAction(
  '[SalesChannel] Load Mobile Sale Channel Data',
  props<{
    appId: string;
    expireCache: boolean;
  }>()
);

export const loadMobileSaleChannelDataSuccess = createAction(
  '[SalesChannel] Load Mobile Sale Channel Data Success',
  props<{ response: any }>()
);

export const loadMobileSaleChannelDataFailure = createAction(
  '[SalesChannel] Load Mobile Sale Channel Data Failure',
  props<{ error: any }>()
);

/******************* Load Shopilink Sale Channel Data  ***********************/
export const loadShopilinkSaleChannelData = createAction(
  '[SalesChannel] Load Shopilink Sale Channel Data',
  props<{ appId: string; expireCache: boolean }>()
);

export const loadShopilinkSaleChannelDataSuccess = createAction(
  '[SalesChannel] Load Shopilink Sale Channel Data Success',
  props<{ response: any }>()
);

export const loadShopilinkSaleChannelDataFailure = createAction(
  '[SalesChannel] Load Shopilink Sale Channel Data Failure',
  props<{ error: any }>()
);

/******************* Load Web Setting Data  ***********************/
export const loadWebsettingData = createAction(
  '[SalesChannel] Load Web Setting Data ',
  props<{ appId: string }>()
);

export const loadWebsettingDataSuccess = createAction(
  '[SalesChannel] Load Web Setting Data Success',
  props<{ response: any }>()
);

export const loadWebsettingDataFailure = createAction(
  '[SalesChannel] Load Web Setting Data Failure',
  props<{ error: any }>()
);

/******************* change Activity Of Sale Channel  ***********************/
export const changeActivityOfSaleChannel = createAction(
  '[SalesChannel]  Change Activity Of Sale Channel',
  props<{
    appId: string;
    channelType: SalesChannelTypes;
    channelInfo: any;
  }>()
);

export const changeActivityOfSaleChannelSuccess = createAction(
  '[SalesChannel] Change Activity Of Sale Channel Success',
  props<{
    payload: {
      channelType: SalesChannelTypes;
      updatedChannelInfo: any;
      result: {
        message: string;
        messageType: StoreActionsMessageTypesList.Success;
      };
    };
  }>()
);

export const changeActivityOfSaleChannelFailure = createAction(
  '[SalesChannel] Change Activity Of Sale Channel Failure',
  props<{
    payload: {
      channelType: SalesChannelTypes;
      result: {
        message: string;
        messageType: StoreActionsMessageTypesList.Error;
      };
    };
  }>()
);

/******************* update Sale Channel  ***********************/
export const updateSaleChannel = createAction(
  '[SalesChannel] update Sale Channel',
  props<{
    appId: string;
    channelInfo: any;
    payload: any;
    channelType: SalesChannelTypes;
    updateType: 'UPDATE_LOGO_FAVICON' | 'REMOVING_FAVICON_LOGO';
  }>()
);

export const updateSaleChannelSuccess = createAction(
  '[SalesChannel] update Sale Channel Success',
  props<{
    payload: {
      result: {
        message: string;
        messageType: StoreActionsMessageTypesList.Success;
      };
    };
  }>()
);

export const updateSaleChannelFailure = createAction(
  '[SalesChannel] update Sale Channel Failure',
  props<{
    payload: {
      result: {
        message: string;
        messageType: StoreActionsMessageTypesList.Error;
      };
    };
  }>()
);

/******************* update Mobile Sale Channel  ***********************/
export const updateMobileSaleChannel = createAction(
  '[SalesChannel] update Mobile Sale Channel',
  props<{
    appId: string;
    payload: any;
  }>()
);

export const updateMobileSaleChannelSuccess = createAction(
  '[SalesChannel] update Mobile Sale Channel Success',
  props<{
    payload: {
      result: {
        message: string;
        messageType: StoreActionsMessageTypesList.Success;
      };
    };
  }>()
);

export const updateMobileSaleChannelFailure = createAction(
  '[SalesChannel] update Mobile Sale Channel Failure',
  props<{
    payload: {
      result: {
        message: string;
        messageType: StoreActionsMessageTypesList.Error;
      };
    };
  }>()
);

/******************* load Mobile Channel Category Display Model  ***********************/
export const loadMobileChannelCategoryDisplayModel = createAction(
  '[SalesChannel] load Mobile Channel Category Display Model',
  props<{ appId: string }>()
);

export const loadMobileChannelCategoryDisplayModelSuccess = createAction(
  '[SalesChannel] load Mobile Channel Category Display Model Success',
  props<{
    payload: {
      categoryDisplayModel: string;
    };
  }>()
);

export const loadMobileChannelCategoryDisplayModelFailure = createAction(
  '[SalesChannel] load Mobile Channel Category Display Model Failure',
  props<{
    payload: {
      result: {
        message: string;
        messageType: StoreActionsMessageTypesList.Error;
      };
    };
  }>()
);

export const updateSupportedSalesChannelListSuccess = createAction(
  '[SalesChannel] Update Supported Sales Channel List Success',
  props<{
    payload: {
      channelList: string[];
    };
  }>()
);

/*******************  Magenty Sale Channel  ***********************/
export const connectMagentySaleChannel = createAction(
  '[SalesChannel] Connect Magenty ',
  props<{ appId: string; payload: MagentyConnectModel }>()
);

export const connectMagentySaleChannelSuccess = createAction(
  '[SalesChannel] Connect Magenty Success',
  props<{
    payload: {
      response: {
        magentyData: any;
        appId: string;
      };
      result: {
        message: string;
        messageType: StoreActionsMessageTypesList.Success;
      };
    };
  }>()
);

export const connectMagentySaleChannelFailure = createAction(
  '[SalesChannel] Connect Magenty  Failure',
  props<{
    payload: {
      result: {
        message: string;
        messageType: StoreActionsMessageTypesList.Error;
      };
    };
  }>()
);

/******************* Load Magenty Channel Data  ***********************/
export const loadMagentySaleChannelData = createAction(
  '[SalesChannel] Load Magenty Channel Data',
  props<{ appId: string; expireCache: boolean }>()
);

export const loadMagentySaleChannelDataSuccess = createAction(
  '[SalesChannel] Load Magenty Channel Data Success',
  props<{ response: any }>()
);

export const loadMagentySaleChannelDataFailure = createAction(
  '[SalesChannel] Load Magenty Channel Data Failure',
  props<{ error: any }>()
);

/******************* Load Easy Collection Channel Data  ***********************/
export const loadEasyCollectionSaleChannelData = createAction(
  '[SalesChannel] Load EasyCollection Channel Data',
  props<{ appId: string; expireCache: boolean }>()
);

export const loadEasyCollectionSaleChannelDataSuccess = createAction(
  '[SalesChannel] Load EasyCollection Channel Data Success',
  props<{ response: any }>()
);

export const loadEasyCollectionSaleChannelDataFailure = createAction(
  '[SalesChannel] Load EasyCollection Channel Data Failure',
  props<{ error: any }>()
);
