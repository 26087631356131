import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  checkUserMembershipStatusInAppSumo,
  checkUserMembershipStatusInAppSumoSuccess
} from './appsumo.actions';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { of } from 'rxjs/internal/observable/of';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { withLatestFrom } from 'rxjs/internal/operators/withLatestFrom';
import { AppSumoService } from '../services';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectUserAppSumoMembershipStatus } from './appsumo.selectors';

@Injectable({
  providedIn: 'root'
})
export class AppSumoFeatureEffects {
  public checkUserMembershipStatusInAppSumo$ = createEffect(() =>
    this._actions$.pipe(
      ofType(checkUserMembershipStatusInAppSumo),
      withLatestFrom(this._store.select(selectUserAppSumoMembershipStatus)),
      switchMap((actions) => {
        const previousData = actions[1];
        if (previousData != null) {
          return of(
            checkUserMembershipStatusInAppSumoSuccess({
              response: previousData
            })
          );
        }
        return this._appsumoService.checkCurrentUserMembershipStatus().pipe(
          map(
            (response) =>
              checkUserMembershipStatusInAppSumoSuccess({ response: response }),
            catchError(() => EMPTY)
          )
        );
      })
    )
  );

  constructor(
    private readonly _store: Store,
    private readonly _actions$: Actions,
    private readonly _appsumoService: AppSumoService
  ) {}
}
