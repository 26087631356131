<form [formGroup]="seoOptionsForm" id="seo-container">
  <fieldset>
    <legend>{{ 'PRODUCTS.SEO_OPTIONS' | translate }}</legend>
    <div class="row">
      <div class="col-6 mt-3">
        <div class="form-group">
          <label>{{ 'PRODUCTS.SLUG' | translate }} *</label>
          <input
            class="form-control"
            name="slug"
            formControlName="slug"
            autocomplete="off"
            [disabled]="isFetchingSlugSuggestion == true"
          />
          <ng-template
            [ngTemplateOutlet]="formError"
            [ngTemplateOutletContext]="{
              validation: 'required',
              control: seoOptionsForm.controls['slug']
            }"
          ></ng-template>
        </div>
      </div>
      <div class="col-6 mt-3">
        <div class="form-group">
          <label>{{ 'PRODUCTS.PAGE_TITLE' | translate }} *</label>
          <input
            class="form-control"
            name="pageTitle"
            formControlName="pageTitle"
            autocomplete="off"
          />
          <ng-template
            [ngTemplateOutlet]="formError"
            [ngTemplateOutletContext]="{
              validation: 'required',
              control: seoOptionsForm.controls['pageTitle']
            }"
          ></ng-template>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label>{{ 'PRODUCTS.DESCRIPTION' | translate }} *</label>
          <textarea
            class="form-control"
            rows="5"
            name="description"
            formControlName="description"
          ></textarea>
          <ng-template
            [ngTemplateOutlet]="formError"
            [ngTemplateOutletContext]="{
              control: seoOptionsForm.controls['description'],
              validation: 'required'
            }"
          ></ng-template>
        </div>
      </div>
    </div>
  </fieldset>
</form>

<ng-template #formError let-control="control" let-validation="validation">
  <ng-container
    *ngIf="
      control?.hasError(validation) &&
      ((control?.invalid && control?.touched) ||
        (control?.dirty && control?.error))
    "
  >
    <p class="text-danger">
      {{ 'SHARED.VALIDATION.FIELD_REQUIRED' | translate }}
    </p>
  </ng-container>
</ng-template>

<app-animated-logo *ngIf="isLoading == true"></app-animated-logo>
