import { END_POINTS_URL } from '@shopiroller/data';
import { GetUserLocationModel } from '../../data';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class GeoLocationService {
  constructor(private _http: HttpClient) {}

  public getCurrentUserLocationInfo(): Observable<GetUserLocationModel> {
    const URL = `${END_POINTS_URL.V2.SHOPIROLLER_URL}/supportedLanguages/region-info`;
    return this._http
      .get(URL)
      .pipe(map((result:any) => result.data as GetUserLocationModel));
  }
}
