import { of } from 'rxjs/internal/observable/of';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable } from 'rxjs/internal/Observable';
import {
  UserProfileModel,
  loadUserProfileInformation
} from '@shopiroller/user-profile';
import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/internal/operators/catchError';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { AuthHTTPService } from './auth-http.service';
import { ExternalLoginModel } from '../../models/external-login.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LOCAL_STORAGE_KEYS } from '@shopiroller/data';
import { LoginModel } from '../../models/login.model';
import { ResetPasswordModel } from '../../models/reset-password.model';
import { Store } from '@ngrx/store';
import { TranslationService } from '@shopiroller/i18n';
import { UserModel } from '../../models/user.model';
import { UserRegistrationModel } from '../../models/user-registration.model';
import { UserRegistrationResponseModel } from '../../models';
import { UserService } from './user.service';
import { AuthUtils } from '../../utils';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _authenticated = false;

  constructor(
    private _store: Store,
    private _userService: UserService,
    private _httpClient: HttpClient,
    private _authHttpService: AuthHTTPService,
    private _translationService: TranslationService
  ) {}

  get authenticated(): boolean {
    return this._authenticated;
  }

  set accessToken(token: string) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, token);
  }

  get accessToken(): string {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) ?? '';
  }

  set email(email: string) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.EMAIL, email);
  }

  get email(): string {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.EMAIL) ?? '';
  }

  forgotPassword(email: string): Observable<any> {
    return this._authHttpService.forgotPassword(email);
  }

  resetPassword(data: ResetPasswordModel): Observable<any> {
    return this._authHttpService.resetPassword(data);
  }

  signIn(data: LoginModel): Observable<boolean> {
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._authHttpService.login(data).pipe(
      map((loggedInUserData: UserModel) => {
        this._userService.user = loggedInUserData;
        this.accessToken = loggedInUserData.accessToken;
        this.email = loggedInUserData.email;
        this._authenticated = true;

        const user: UserProfileModel = {
          ...loggedInUserData,
          fullName: loggedInUserData.fullName ?? loggedInUserData.userName
        };
        this._store.dispatch(loadUserProfileInformation());
        return loggedInUserData && loggedInUserData.accessToken ? true : false;
      })
    );
  }

  signInUsingToken(): Observable<any> {
    return this._httpClient
      .post('api/auth/sign-in-with-token', {
        accessToken: this.accessToken
      })
      .pipe(
        catchError(() => of(false)),
        switchMap((response: any) => {
          if (response.accessToken) {
            this.accessToken = response.accessToken;
          }

          this._authenticated = true;
          this._userService.user = response.user;

          return of(true);
        })
      );
  }

  externalSignIn(data: ExternalLoginModel): any {
    return this._authHttpService.externalLogin(data).pipe(
      map((auth: any) => {
        return auth && auth.accessToken;
      })
    );
  }

  signUp(
    callBackURL: string,
    user: UserRegistrationModel
  ): Observable<UserRegistrationResponseModel> {
    return this._authHttpService.register(callBackURL, user).pipe(
      map((result) => {
        this._resetUserData();
        return result;
      })
    );
  }

  signOut(): Observable<boolean> {
    this._resetUserData();
    this._authenticated = false;
    return of(true);
  }

  check(): Observable<boolean> {
    if (this._authenticated) {
      return of(true);
    }

    if (!this.accessToken) {
      return of(false);
    }

    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    return this.signInUsingToken();
  }

  private _resetUserData(): void {
    const userLanguage = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_LANGUAGE);
    localStorage.clear();
    let defaultLanguage = '';
    if (userLanguage != null) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.USER_LANGUAGE, userLanguage);
    } else {
      const browserLanguage = (defaultLanguage = navigator.language).substring(
        0,
        2
      );
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.BROWSER_LANGUAGE,
        browserLanguage
      );
    }
    this._translationService.setLanguage(defaultLanguage);
  }
}
