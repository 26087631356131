import { environment } from 'src/environments/environment';

export const END_POINTS_URL = {
  V1: {
    APPS_URL: `https://dev.legacy.mobiroller.com/v1.0/appList/apps`,
    OPERATION_APPS_URL: `${environment.storage_API_URL}/v1.0/operation/apps`,
    USERS: `${environment.users_API_URL}/v1/users`,
    ADDRESSES_URL: `${environment.users_API_URL}/v1.0/addresses`
  },
  V2: {
    SHOPIROLLER_URL: `${environment.shopiroller_API_URL}/v2.0`,
    APPS_URL: `${environment.shopiroller_API_URL}/v2.0/apps`,
    CURRENCIES_LIST_URL: `${environment.shopiroller_API_URL}/v2.0/currencies`,
    SECTORS_LIST_URL: `${environment.shopiroller_API_URL}/v2.0/sectors`,
    CATEGORIES_URL: `${environment.shopiroller_API_URL}/v2.0/categories`,
    BRANDS_URL: `${environment.shopiroller_API_URL}/v2.0/brands`,
    SHOW_CASE_URL: `${environment.shopiroller_API_URL}/v2.0/showcases`,
    PRODUCTS_URL: `${environment.shopiroller_API_URL}/v2.0/products`,
    ORDERS_URL: `${environment.shopiroller_API_URL}/v2.0/orders`,
    SALES_CHANNELS_URL: `${environment.shopiroller_API_URL}/v2.0/saleschannels`,
    MOBILE_SETTINGS_URL: `${environment.shopiroller_API_URL}/v2.0/mobileSettings`,
    SHOPILINK_URL: `${environment.shopiroller_API_URL}/v2.0/shopiLinks`,
    SHOPILINK_SERVICES_URL: `${environment.shopiroller_API_URL}/v2.0/services`,
    WEB_SETTINGS: `${environment.shopiroller_API_URL}/v2.0/webSettings`,
    COUPON_URL: `${environment.shopiroller_API_URL}/v2.0/coupons`,
    ZONE_URL: `${environment.shopiroller_API_URL}/v2.0/zones`,
    SENDY_SUBSCRIBE_URL: `${environment.shopiroller_API_URL}/v2.0/sendy/subscribe`,
    SUBSCRIPTIONS_URL: `${environment.shopiroller_API_URL}/v2.0/subscriptions`,
    INTEGRATION_URL: `${environment.shopiroller_API_URL}/v2.0/integration`,
    HOOKS_ULR: `${environment.shopiroller_API_URL}/v2.0/hooks`,
    SHIPPING_URL: `${environment.shopiroller_API_URL}/v2.0/shipping`,
    CONTRACTS_URL: `${environment.shopiroller_API_URL}/v2.0/contracts`,
    GOOGLE_MERCHANTS_URL: `${environment.shopiroller_API_URL}/v2.0/googleMerchantSettings`,
    PROJECT_URL: `${environment.shopiroller_API_URL}/v2.0/project`,
    COLLECTIONS_URL: `${environment.shopiroller_API_URL}/v2.0/collections`
  },
  V2_1: {
    USER_URL: `${environment.identity_API_URL}/v2.1/user`,
    AUTH_URL: `${environment.identity_API_URL}/v2.1/auth`,
    PROFILE_URL: `${environment.identity_API_URL}/v2.1/profile`,
    APPS_URL: `${environment.identity_API_URL}/v2.1/apps`,
    PACKAGES: `${environment.identity_API_URL}/v2.1/packages`
  }
};
