<p-tree
  [filter]="true"
  [value]="categoriesNode"
  [scrollHeight]="defaultScrollHeight"
  selectionMode="single"
  [(selection)]="selectedCategory"
  display="chip"
  (onNodeSelect)="handleNodeSelect($event)"
  #parentCategoryTree
  [loading]="isLoading"
>
  <ng-template let-node pTemplate="default">
    <span class="d-flex justify-content-between w-100">
      <b>
        {{ node.label }}
      </b>
      <fa-icon
        [icon]="faTimesCircle"
        *ngIf="
          selectedCategory &&
          node.data.categoryId == selectedCategory.categoryId
        "
        (click)="handleRemoveNodeSelection($event)"
      ></fa-icon>
    </span>
  </ng-template>
  <ng-template p-Template="empty">
    {{ "primeng.emptyMessage" | translate }}
  </ng-template>
</p-tree>
