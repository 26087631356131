export const CommonEnvironment = {
  name: 'development',
  production: false,
  appVersion: 'v5.1.5.0-pre-dev',
  apiKey: 'ARz1Er9DHR0juxtDLHcywIGWVe86m7UzkLhpUeRMVtY=',
  shopiroller_API_URL: 'https://dev.ecommerce.applyze.com',
  identity_API_URL: 'https://dev.identity.applyze.com',
  storage_API_URL: 'https://dev.storage.applyze.com',
  users_API_URL: 'https://dev.app-user.applyze.com',
  storageContainerName: 'dev-shopiroller-ecommerce',
  fileCDNBaseURL: 'https://dev.content.cdn.shopiroller.com',
  fileCDNBaseURL2:'https://shopcontent-dev.b-cdn.net',
  shopilinkBaseURL: 'https://dev.link.shopiroller.com',
  panelBaseURL: 'dev.myshopiroller.com',
  testUser: {
    userName: 'hamid.hassani@mobiroller.com',
    password: 'hamid.hassani'
  },
  googleAuthApi: {
    clientId:
      '515686987557-ff6008eh63sld83jnv191r15a72qvdgf.apps.googleusercontent.com',
    clientSecret: 'gDEg-VsqG-5Bz9myiFJ5yPz-'
  },
  sentryConfig: {
    dsn: 'https://4cadbbd4406e4d138f0f3059b88b0da0@o4504372247461888.ingest.sentry.io/4504372250345472',
    tracingOrigins: ['localhost', 'https://dev.app.shopiroller.com/']
  },
  frillWidgetKey: '143e69c8-02b7-4714-b9f1-fd30db74ed6b',
  lightHouseTokens: {
    buildToken: '855663ba-d634-45f0-bb1d-fa7eb8da4a13',
    adminToken: 'dKQPjvbOVhIlbuMldlasLQx3bFyvFMhl7EapV8X4'
  }
};
