import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MessageService, TreeNode } from 'primeng/api';
import { AutoUnsubscribe } from '@shopiroller/decorators';
import { SubSink } from 'subsink';
import { TranslateService } from '@ngx-translate/core';
import { Tree } from 'primeng/tree';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { timer } from 'rxjs';
import { AppSettingService } from '@shopiroller/core';
import { CategoriesService } from '@shopiroller/store/catalog';

@AutoUnsubscribe()
@Component({
  selector: 'app-tree-of-categories',
  templateUrl: './tree-of-categories.component.html',
  styleUrls: ['./tree-of-categories.component.scss']
})
export class TreeOfCategoriesComponent implements AfterViewInit, OnChanges {
  private appId = '';
  private subs = new SubSink();
  public isLoading = false;
  public defaultScrollHeight = '150px';
  public categories: any[] = [];
  public categoriesNode: TreeNode[] = [];
  public selectedCategory: TreeNode | any;

  @ViewChild('parentCategoryTree')
  public parentCategoryTree!: Tree;

  @Input()
  public data: any = null;

  @Output()
  public categorySelected = new EventEmitter<any>();

  @Input()
  public currentAppDefaultLanguage = 'en';

  @Input()
  public currentlySelectedLanguage = 'en';

  public faTimesCircle = faTimesCircle;

  constructor(
    private appSettingService: AppSettingService,
    private categoryService: CategoriesService,
    private translateService: TranslateService,
    private msgService: MessageService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.appId = this.appSettingService.getCurrentAppId();
  }

  ngAfterViewInit(): void {
    this.getCategories();
  }

  ngOnChanges(changes: SimpleChanges) {
    const newCategory = changes.data?.currentValue;
    if (newCategory != null) {
      this.selectedCategory = this.convertCategoryToNode(newCategory);
    }
    if (this.categories.length > 0 && this.selectedCategory != null) {
      this.categories = this.categories.filter(
        (x) => x.categoryId != this.data.categoryId
      );
      this.selectedCategory = this.convertCategoryToNode(this.data);
      this.selectedCategory.expanded = false;
      this.selectedCategory.expanded = true;
      this.expandTreeNodes(this.categoriesNode);
    }
    this._markSelectedCategory();
  }

  public handleNodeSelect(event): void {
    const previousSelectedNode = document.querySelector('.tree-node-focused');
    if (previousSelectedNode != null) {
      (previousSelectedNode as HTMLElement).classList.remove(
        'tree-node-focused'
      );
    }
    (event.node as TreeNode).styleClass = 'tree-node-focused';
    this.selectedCategory = event.node.data;
    this.categorySelected.emit(this.selectedCategory);
  }

  public handleRemoveNodeSelection(event): void {
    event.stopPropagation();
    event.target.closest('.p-treenode-content').blur();

    this.selectedCategory = null;
    this.categorySelected.emit(null);

    const previousSelectedNode = document.querySelectorAll(
      '.p-treenode-content.p-treenode-selectable.p-highlight'
    );
    previousSelectedNode.forEach((el) => {
      el.classList.remove('p-highlight');
      (el as HTMLElement).blur();
    });

    const previousFocusedNode = document.querySelector('.tree-node-focused');
    if (previousFocusedNode != null) {
      (previousFocusedNode as HTMLElement).classList.remove(
        'tree-node-focused'
      );
    }
  }

  public getCategories(): void {
    this.isLoading = true;
    this.subs.sink = this.categoryService
      .getCategoriesList(this.appId)
      .subscribe(
        (categoryList: any) => {
          this.isLoading = false;
          this.categories = this.flattenCatagories(categoryList.data);
          this.convertCategoriesToTreeStructure(this.categories);
          if (this.selectedCategory != null) {
            this.categories = this.categories.filter(
              (x) => x.categoryId != this.data.categoryId
            );
            this.selectedCategory.expanded = false;
            this.selectedCategory.expanded = true;
            this.expandTreeNodes(this.categoriesNode);
          }
          this.changeDetector.detectChanges();
        },
        (error) => {
          this.isLoading = false;
          this.msgService.add({
            severity: 'error',
            summary: this.translateService.instant('SHARED.MESSAGES.ERROR'),
            detail: this.translateService.instant(
              'SHARED.MESSAGES.REQUEST_FAILED'
            )
          });
        }
      );
  }

  private flattenCatagories(array): any[] {
    let result: any[] = [];
    array.forEach((item: any) => {
      if (item != null) {
        result.push(item);
        if (Array.isArray(item.subCategories)) {
          const flattenedSubCategory = this.flattenCatagories(
            item.subCategories
          );
          result = result.concat(flattenedSubCategory);
        }
      }
    });
    return result;
  }

  private convertCategoryToNode(category): TreeNode {
    return {
      key: category.categoryId,
      label: category.name,
      data: category
    };
  }

  private convertCategoriesToTreeStructure(categories: any[]): void {
    const rootLevelCategories = categories.filter(
      (x) => x.parentCategoryId == undefined
    );
    for (let i = 0; i < rootLevelCategories.length; i++) {
      const rootLevelTreeNode = this.convertCategoryToNode(
        rootLevelCategories[i]
      );
      const subCategories = categories.filter(
        (x) =>
          x.hasOwnProperty('parentCategoryId') &&
          x.parentCategoryId === rootLevelCategories[i].categoryId
      );
      let rootLevel2: TreeNode = {};
      let rootLevel3: TreeNode = {};
      let rootLevel4: TreeNode = {};
      for (let i = 0; i < subCategories.length; i++) {
        rootLevel2 = Object.assign(
          {},
          this.convertCategoryToNode(subCategories[i])
        );

        if (subCategories[i].subCategories.length > 0) {
          for (let j = 0; j < subCategories[i].subCategories.length; j++) {
            rootLevel3 = Object.assign(
              {},
              this.convertCategoryToNode(subCategories[i].subCategories[j])
            );

            if (rootLevel3.data.subCategories.length > 0) {
              for (const sub of rootLevel3.data.subCategories) {
                rootLevel4 = Object.assign({}, this.convertCategoryToNode(sub));
                if (rootLevel4.data.subCategories.length > 0) {
                  for (const subLevel4 of rootLevel4.data.subCategories) {
                    if (Object.keys(rootLevel4).length != 0) {
                      if (rootLevel4.children == undefined) {
                        rootLevel4.children = [];
                      }
                      rootLevel4.children?.push(
                        this.convertCategoryToNode(subLevel4)
                      );
                    }
                  }
                }
                if (Object.keys(rootLevel3).length != 0) {
                  if (rootLevel3.children == undefined) {
                    rootLevel3.children = [];
                  }
                  rootLevel3.children?.push(rootLevel4);
                }
              }
            }
            if (Object.keys(rootLevel3).length != 0) {
              if (rootLevel2.children == undefined) {
                rootLevel2.children = [];
              }
              rootLevel2.children?.push(rootLevel3);
            }
          }
        }
        if (Object.keys(rootLevel2).length != 0) {
          if (rootLevelTreeNode.children == undefined) {
            rootLevelTreeNode.children = [];
          }
          rootLevelTreeNode.children?.push(rootLevel2);
        }
      }
      this.categoriesNode.push(rootLevelTreeNode);
    }
  }

  private expandTreeNodes(nodes: TreeNode[]): void {
    for (const node of nodes) {
      if (node.children) {
        if (node.key === this.selectedCategory.key) {
          node.expanded = false;
        } else {
          node.expanded = true;
        }
        for (const child of node.children) {
          this.expandTreeNodes(node.children);
        }
      }
    }
  }

  private _markSelectedCategory(): void {
    this.subs.sink = timer(2000).subscribe((_) => {
      const previousSelectedNode = document.querySelector(
        '.p-treenode-content.p-treenode-selectable.p-highlight'
      );
      if (previousSelectedNode != null) {
        (previousSelectedNode as HTMLElement).click();
      }
    });
  }
}
