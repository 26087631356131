import { of } from 'rxjs/internal/observable/of';
import { Observable } from 'rxjs/internal/Observable';
import { shareReplay } from 'rxjs/internal/operators/shareReplay';
import { tap } from 'rxjs/internal/operators/tap';
import { END_POINTS_URL } from '@shopiroller/data';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IntegrationStatusResponseDto } from '../../data/models/integration-status-response.dto';
import { IntegrationStepResponseDto } from '../../data/models';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class IntegrationStepService {
  private cache: string[] = [];

  constructor(private http: HttpClient) {}

  getStatus(appId: string): Observable<IntegrationStatusResponseDto> {
    const url = `${END_POINTS_URL.V2.INTEGRATION_URL}/status/${appId}`;
    return this.http.get(url).pipe(
      map((data: any) => data.data),
      shareReplay()
    );
  }

  getSteps(appId: string): Observable<IntegrationStepResponseDto[]> {
    return this.getStatus(appId).pipe(map((data) => data.steps));
  }

  needShowWithCache(appId: string): Observable<boolean> {
    if (this.cache.find((x) => x === appId)) {
      return of(false);
    }
    return this.getStatus(appId).pipe(
      tap(() => {
        this.cache.push(appId);
      }),
      map((data) => !!data.steps.find((x) => x.isCompleted === false))
    );
  }
}
