export interface SeoOptionsModel {
  slug: string;
  pageTitle: string;
  description: string;
}

export interface SeoOptionsMultiLanguageModel {
  slug: { [key: string]: string };
  pageTitle: { [key: string]: string };
  description: { [key: string]: string };
}

export interface MultiLanguageSlugSuggestionModel {
  name: {
    [key: string]: string;
  };
}
