import { Routes } from '@angular/router';

export const GOOGLE_SALE_CHANNEL_ROUTES: Routes = [
  {
    path: '',
    loadComponent: async () =>
      (await import('../features/shell/shell.component')).ShellComponent
  },

  {
    path: '**',
    pathMatch: 'full',
    loadComponent: async () =>
      (await import('../features/shell/shell.component')).ShellComponent
  }
];
