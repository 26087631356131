import { createAction, props } from '@ngrx/store';
import { AppListResultModel, GetAppListModel } from '../../models';
import { StoreActionResultModel } from '@shopiroller/core';
import {
  NewStoreModel,
  PrimitveStoreDataModel
} from '../../models/store.model';
import { WebSettingPartialUpdateModel } from '@shopiroller/store/sales-channel';

/******************* Get List Of Apps  ***********************/
export const GetListOfApps = createAction(
  '[Apps] Get List Of App',
  props<{ payload: GetAppListModel }>()
);

export const GetListOfAppsSuccess = createAction(
  '[Apps] Get List Of App Success',
  props<{ response: AppListResultModel }>()
);

export const GetListOfAppsFailure = createAction(
  '[Apps] Get List Of App Failure',
  props<{ error: any }>()
);

/******************* Load More Apps  ***********************/
export const LoadMoreApps = createAction(
  '[Apps] Load More Apps',
  props<{ payload: GetAppListModel }>()
);

export const LoadMoreAppsSuccess = createAction(
  '[Apps] Load More Apps Success',
  props<{ response: AppListResultModel }>()
);

export const LoadMoreAppsFailure = createAction(
  '[Apps] Load More Apps Failure',
  props<{ error: any }>()
);

/******************* Add New App  ***********************/
export const AddNewApp = createAction('[General  settings] Add New App');

export const AddNewAppSuccess = createAction(
  '[General  settings] Add New App Success',
  props<{ response: any }>()
);

export const AddNewAppFailure = createAction(
  '[Apps] Add New App Failure',
  props<{ error: any }>()
);

/******************* delete Exisiting App  ***********************/
export const deleteExistingApp = createAction(
  '[General  settings] Delete Exisiting App'
);

/******************* Edit Exisiting App  ***********************/
export const editExistingApp = createAction(
  '[General  settings] Edit Exisiting App',
  props<{
    appInfo: any;
  }>()
);

export const editExistingAppSuccess = createAction(
  '[General  settings] Edit Exisiting App',
  props<{
    payload: {
      edittedApp: any;
    };
  }>()
);

export const editExistingAppFailure = createAction(
  '[General  settings] Edit Exisiting App',
  props<{
    payload: {
      result: StoreActionResultModel;
    };
  }>()
);

/******************* Load Current App Data  ***********************/
export const LoadCurrentAppData = createAction(
  '[Apps] Load Current App Data',
  props<{ appId: string }>()
);

export const LoadCurrentAppDataSuccess = createAction(
  '[Apps] Load Current App DataSuccess',
  props<{ response: any }>()
);

export const LoadCurrentAppDataFailure = createAction(
  '[Apps] Load Current App Data Failure',
  props<{ error: any }>()
);

/******************* Update App WebSetting ***********************/
export const UpdateAppWebsetting = createAction(
  'Update App WebSetting Data',
  props<{
    appId: string;
    updateType:
      | 'guestShopping'
      | 'webHook'
      | 'googleAnalytics'
      | 'googleTagManager'
      | 'metaTages'
      | 'domain';
    payload: WebSettingPartialUpdateModel;
  }>()
);

export const UpdateAppWebsettingSuccess = createAction(
  'Update App WebSetting Data Success',
  props<{
    payload: {
      webSetting: any;
      result: StoreActionResultModel;
    };
  }>()
);

export const UpdateAppWebsettingFailure = createAction(
  'Update App WebSetting Failure',
  props<{
    payload: {
      result: StoreActionResultModel;
    };
  }>()
);

/******************* Add Language To  App ***********************/
export const addNewLanguage = createAction(
  'Add Language To  App',
  props<{
    appId: string;
    language: any;
  }>()
);

export const addNewLanguageSuccess = createAction(
  'Add Language To  App  Success',
  props<{
    payload: {
      newLanguage: any;
      result: StoreActionResultModel;
    };
  }>()
);

export const addNewLanguageFailure = createAction(
  'Add Language To  App  Failure',
  props<{
    payload: {
      result: StoreActionResultModel;
    };
  }>()
);

/******************* Delete App Language ***********************/
export const deleteAppLanguage = createAction(
  'Delete App Language',
  props<{
    appId: string;
    languageId: number;
  }>()
);

export const deleteAppLanguageSuccess = createAction(
  'Delete App Language Success',
  props<{
    payload: {
      deletedLanguageId: number;
      result: StoreActionResultModel;
    };
  }>()
);

export const deleteAppLanguageFailure = createAction(
  'Delete App Language Failure',
  props<{
    payload: {
      result: StoreActionResultModel;
    };
  }>()
);

/******************* Change Default Language Of App  ***********************/
export const changeDefaultLanguageOfApp = createAction(
  'Change Default Language Of App',
  props<{
    appId: string;
    languageId: number;
  }>()
);

export const changeDefaultLanguageOfAppSuccess = createAction(
  'Change Default Language Of App Success',
  props<{
    payload: {
      newDefaultLanguageId: any;
      result: StoreActionResultModel;
    };
  }>()
);

export const changeDefaultLanguageOfAppFailure = createAction(
  'Change Default Language Of App Failure',
  props<{
    payload: {
      result: StoreActionResultModel;
    };
  }>()
);

export const moveAppToTopOfAppList = createAction(
  'move App To Top Of App List',
  props<{
    appId: string;
  }>()
);

/******************* Load Store Information (includes lanauge/sector/currency list) ***********************/
export const loadPrimitiveStoreInformation = createAction(
  '[Store] load Primitive Store Information'
);

export const loadPrimitiveStoreInformationSuccess = createAction(
  '[Store] load Primitive Store Information Success',
  props<{ response: PrimitveStoreDataModel }>()
);

export const loadPrimitiveStoreInformationFailure = createAction(
  '[Store] lLoad Primitive Store Information Failure',
  props<{ error: any }>()
);

/******************* Create Store ***********************/
export const createStore = createAction(
  '[Store] Create Store',
  props<{ payload: NewStoreModel }>()
);

export const createStoreSuccess = createAction('[Store] Create Store Success');

export const createStoreFailure = createAction(
  '[Store] lCreate Store Failure',
  props<{
    payload: {
      result: StoreActionResultModel;
    };
  }>()
);

export default {
  AddNewApp,
  AddNewAppFailure,
  AddNewAppSuccess,
  editExistingAppSuccess,
  editExistingAppFailure,
  GetListOfApps,
  GetListOfAppsFailure,
  GetListOfAppsSuccess,
  LoadCurrentAppData,
  LoadCurrentAppDataFailure,
  LoadCurrentAppDataSuccess,
  LoadMoreApps,
  LoadMoreAppsFailure,
  LoadMoreAppsSuccess,
  UpdateAppWebsetting,
  UpdateAppWebsettingFailure,
  UpdateAppWebsettingSuccess,
  changeDefaultLanguageOfApp,
  changeDefaultLanguageOfAppFailure,
  changeDefaultLanguageOfAppSuccess,
  deleteAppLanguage,
  deleteAppLanguageFailure,
  deleteAppLanguageSuccess
};
