import { createAction, props } from '@ngrx/store';

import { StoreSettingsModel } from '../../data/models/store-settings.model';
import { StoreActionResultModel } from '@shopiroller/core';
import {
  ISlideModel,
  ISlidesModel,
  UpdateStoreInformationModel
} from '../../data/models';
import { Operations } from '../../data/enums';

/******************* Load Store Information  ***********************/
export const loadStoreSettingInformation = createAction(
  '[Store Settings] Load Store Setting Information',
  props<{ appId: string }>()
);

export const loadStoreSettingInformationSuccess = createAction(
  '[Store Settings] Load Store Setting Information Success',
  props<{
    response: {
      appId: string;
      data: StoreSettingsModel;
    };
  }>()
);

export const loadStoreSettingInformationFailure = createAction(
  '[Store Settings] Load Store Setting Information Failure',
  props<{ error: any }>()
);

/******************* Load Region List Of App ***********************/
export const LoadRegionListOfApp = createAction(
  '[Store Settings] Load Region List Of App',
  props<{ appId: string }>()
);

export const LoadRegionListOfAppSuccess = createAction(
  '[Store Settings] Load Region List Of App Success',
  props<{
    response: {
      appId: string;
      data: any[];
    };
  }>()
);

export const LoadRegionListOfAppFailure = createAction(
  '[Store Settings] Load Region List Of App Failure',
  props<{ error: any }>()
);

/******************* Update Store Information ***********************/
export const updateStoreInformation = createAction(
  '[Store Settings] Update Store Information',
  props<{
    payload: UpdateStoreInformationModel;
  }>()
);

export const updateStoreInformationSuccess = createAction(
  '[Store Settings] Update Store Information Success',
  props<{
    payload: {
      result: StoreActionResultModel;
    };
  }>()
);

export const updateStoreInformationFailure = createAction(
  '[Store Settings] Update Store Information Failure',
  props<{
    payload: {
      result: StoreActionResultModel;
    };
  }>()
);

/******************* Update Payment Information ***********************/
export const updatePaymentInformation = createAction(
  '[Store Settings] Update Payment Information',
  props<{
    payload: {
      appId: string;
      operationType?: Operations;
      paymentInformationDto: any;
      updateSourceName:
        | 'legalPages'
        | 'paymentProvidersPage'
        | 'shippingDeliveryPage'
        | 'emailNotificationPage'
        | 'shopipayPaymentConfigurationComponent'
        | 'paypalPaymentConfigurationComponent'
        | 'iyzicoPaymentConfigurationComponent'
        | 'stripePaymentConfigurationComponent'
        | 'paytrPaymentConfigurationComponent'
        | 'payzeePaymentConfigurationComponent'
        | 'payAtDoorPaymentConfigurationComponent'
        | 'moneyOrderPaymentConfigurationComponent';

      paymentIndexInSupportedPayments?: number;
    };
  }>()
);

export const updatePaymentInformationSuccess = createAction(
  '[Store Settings] Update Payment Information Success',
  props<{
    payload: {
      updatedPaymentInformation: any;
      result: StoreActionResultModel;
    };
  }>()
);

export const updatePaymentInformationFailure = createAction(
  '[Store Settings] Update Payment Information Failure',
  props<{
    payload: {
      result: StoreActionResultModel;
    };
  }>()
);

/******************* Update Shipping Information ***********************/
export const updateShippingInformation = createAction(
  '[Store Settings] Update Shipping Information',
  props<{
    payload: {
      appId: string;
      shippingInformationDto: any;
    };
  }>()
);

export const updateShippingInformationSuccess = createAction(
  '[Store Settings] Update Shipping Information Success',
  props<{
    payload: {
      updatedShippingInformation: any;
      result: StoreActionResultModel;
    };
  }>()
);

export const updateShippingInformationFailure = createAction(
  '[Store Settings] Update Shipping Information Failure',
  props<{
    payload: {
      result: StoreActionResultModel;
    };
  }>()
);

/******************* Delete Region ***********************/
export const deleteRegion = createAction(
  '[Store Settings] Delete Region',
  props<{
    payload: {
      appId: string;
      regionId: string;
    };
  }>()
);

export const deleteRegionSuccess = createAction(
  '[Store Settings] DeleteRegion Success',
  props<{
    payload: {
      deletedRegionId: any;
      result: StoreActionResultModel;
    };
  }>()
);

export const deleteRegionFailure = createAction(
  '[Store Settings] DeleteRegion Failure',
  props<{
    payload: {
      result: StoreActionResultModel;
    };
  }>()
);

/******************* Add New Region ***********************/
export const addNewRegion = createAction(
  '[Store Settings] Add New Region ',
  props<{
    payload: {
      appId: string;
      dto: any;
    };
  }>()
);

export const addNewRegionSuccess = createAction(
  '[Store Settings] Add New Region  Success',
  props<{
    payload: {
      addedRegion: any;
      result: StoreActionResultModel;
    };
  }>()
);

export const addNewRegionFailure = createAction(
  '[Store Settings] Add New Region  Failure',
  props<{
    payload: {
      result: StoreActionResultModel;
    };
  }>()
);

/******************* Update Exisiting Region ***********************/
export const updateExisitingRegion = createAction(
  '[Store Settings] Update Exisiting Region ',
  props<{
    payload: {
      appId: string;
      regionId: string;
      dto: any;
    };
  }>()
);

export const updateExistingRegionSuccess = createAction(
  '[Store Settings]Update Exisiting Region  Success',
  props<{
    payload: {
      updatedRegion: any;
      result: StoreActionResultModel;
    };
  }>()
);

export const updateExistingRegionFailure = createAction(
  '[Store Settings] Update Exisiting Region  Failure',
  props<{
    payload: {
      result: StoreActionResultModel;
    };
  }>()
);

/******************* Load List of Sliders ***********************/
export const loadListOfSliders = createAction(
  '[Store Settings] Load List Of Sliders',
  props<{
    payload: {
      appId: string;
      sliderType: 'Web' | 'Mobile';
    };
  }>()
);

export const loadListOfSlidersSuccess = createAction(
  '[Store Settings]  Load List Of Sliders Success',
  props<{
    sliders: any;
  }>()
);

export const loadListOfSlidersFailure = createAction(
  '[Store Settings]  Load List Of Sliders  Failure',
  props<{
    payload: {
      result: StoreActionResultModel;
    };
  }>()
);

/******************* Add Slider ***********************/
export const addSlider = createAction(
  '[Store Settings] Add Slider',
  props<{
    payload: {
      appId: string;
      dto: any;
    };
  }>()
);

export const addSliderSuccess = createAction(
  '[Store Settings]  Add Slider Success',
  props<{
    newlyAddedSlider: any;
  }>()
);

export const addSliderFailure = createAction(
  '[Store Settings]  Add Slider  Failure',
  props<{
    payload: {
      result: StoreActionResultModel;
    };
  }>()
);

/******************* Add Slide ***********************/
export const addSlide = createAction(
  '[Store Settings] Add Slide',
  props<{
    payload: {
      appId: string;
      sliderId: string;
      slide: ISlideModel;
    };
  }>()
);

export const addSlideSuccess = createAction(
  '[Store Settings]  Add Slide Success',
  props<{
    payload: {
      sliderWithLastChanges: any;
      result: StoreActionResultModel;
    };
  }>()
);

export const addSlideFailure = createAction(
  '[Store Settings]  Add Slide Failure',
  props<{
    payload: {
      result: StoreActionResultModel;
    };
  }>()
);

/******************* Update Exisiting Slide ***********************/
export const updateExisitingSlide = createAction(
  '[Store Settings] Update Exisiting Slide',
  props<{
    payload: {
      appId: string;
      sliderId: string;
      slide: ISlidesModel;
    };
  }>()
);

export const updateExisitingSlideSuccess = createAction(
  '[Store Settings]  Update Exisiting Slide Success',
  props<{
    payload: {
      sliderWithLastChanges: any;
      result: StoreActionResultModel;
    };
  }>()
);

export const updateExisitingSlideFailure = createAction(
  '[Store Settings]  Update Exisiting Slide Failure',
  props<{
    payload: {
      result: StoreActionResultModel;
    };
  }>()
);

/******************* Delete Slide ***********************/
export const deleteSlide = createAction(
  '[Store Settings] Delete Slide',
  props<{
    payload: {
      appId: string;
      sliderId: string;
      slideId: string;
    };
  }>()
);

export const deleteSlideSuccess = createAction(
  '[Store Settings]  Delete Slide Success',
  props<{
    payload: {
      slideId: string;
      result: StoreActionResultModel;
    };
  }>()
);

export const deleteSlideFailure = createAction(
  '[Store Settings]  Delete Slide Failure',
  props<{
    payload: {
      result: StoreActionResultModel;
    };
  }>()
);
