import {
  checkUserMembershipStatusInAppSumo,
  checkUserMembershipStatusInAppSumoFailure,
  checkUserMembershipStatusInAppSumoSuccess
} from './appsumo.actions';
import { GenericState, GenericStoreStatus } from '@shopiroller/core';
import { createReducer, on } from '@ngrx/store';

export const AppSumoFeatureKey = 'feature-appsumo';

export class AppSumoFeatureState implements GenericState<any> {
  currentUserIsAppSumoMember: boolean | null = null;
  status: GenericStoreStatus | any = 'pending';
  error: any = null;
}

const initialState: AppSumoFeatureState = {
  currentUserIsAppSumoMember: null,
  error: null,
  status: 'pending'
};

export const AppSumoFeatureReducer = createReducer(
  initialState,
  on(checkUserMembershipStatusInAppSumo, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(checkUserMembershipStatusInAppSumoSuccess, (state, { response }) => {
    return {
      ...state,
      currentUserIsAppSumoMember: response,
      status: 'success',
      error: null
    };
  }),
  on(checkUserMembershipStatusInAppSumoFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  }))
);
