import { END_POINTS_URL } from '@shopiroller/data';
import { HttpClient } from '@angular/common/http';
import { IConversation } from '../../data';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class ConversationService {
  constructor(private _http: HttpClient) {}

  public addConversation(
    appId: string,
    conversation: IConversation
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/conversations`;
    return this._http.post(URL, conversation);
  }

  public updateConversation(
    appId: string,
    id: String,
    conversation: IConversation
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/conversations/${id}`;
    return this._http.put(URL, conversation);
  }

  public deleteConversation(
    appId: string,
    conversation: IConversation
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/conversations`;
    return this._http.post(URL, conversation);
  }

  public getConversationList(appId: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/conversations?sortBy=Descending&sort=CreateDate`;
    return this._http.get(URL);
  }

  public getConversation(appId: string, id: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/conversations/${id}`;
    return this._http.get(URL);
  }

  public getWhatsAppConversation(appId: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/conversations?type=Whatsapp`;
    return this._http.get(URL);
  }
}
