import {
  SubscriptionFeaturesEnum,
  SubscriptionTypesEnum
} from '../../data/models';
import {
  SubscriptionFeatureState,
  subscriptionFeatureKey
} from './subscription.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const subscriptionState =
  createFeatureSelector<SubscriptionFeatureState>(subscriptionFeatureKey);

export const selectCurrentSubscriptionInfo = createSelector(
  subscriptionState,
  ({ currentPackage, packagesInfo, currentPackagePermissionList }) => {
    return {
      currentPackage,
      packagesInfo,
      currentPackagePermissionList
    };
  }
);

export const selectCurrentSubscriptionInformationOfApp = createSelector(
  subscriptionState,
  ({ currentPackage, currentPackagePermissionList }) => {
    return {
      currentPackage,
      currentPackagePermissionList
    };
  }
);

export const currentPackageIsBusinessPackage = createSelector(
  subscriptionState,
  ({ currentPackage }) => {
    return currentPackage.type == SubscriptionTypesEnum.Business;
  }
);

export const selectSubscriptionPackagesList = createSelector(
  subscriptionState,
  ({ packagesInfo }) => packagesInfo
);

export const selectCurrentSubscriptionPackagesInfo = createSelector(
  subscriptionState,
  ({ currentPackage }) => currentPackage
);

export const selectCurrentSubscriptionPermissionList = createSelector(
  subscriptionState,
  ({ currentPackagePermissionList }) => currentPackagePermissionList
);

export const selectFeaturePermissionInCurrentPackage = (
  featureName: SubscriptionFeaturesEnum
) =>
  createSelector(subscriptionState, ({ currentPackagePermissionList }) => {
    if (!currentPackagePermissionList) return false;
    if (featureName == SubscriptionFeaturesEnum.catalog_Variant_max_count)
      return currentPackagePermissionList[featureName] as unknown as number;
    return JSON.parse(currentPackagePermissionList[featureName]);
  });

export const selectSubscriptionStatus = createSelector(
  subscriptionState,
  ({ status }) => status
);
