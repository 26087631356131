import { clientFeatureKey, clientFeatureState } from './client.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const clientState =
  createFeatureSelector<clientFeatureState>(clientFeatureKey);

  export const selectClientLoadingStatus = createSelector(
  clientState,
  ({ status }) => {
    return status == 'loading' || status == 'pending';
  }
);

