import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translatePackageDescription',
  standalone: true
})
export class TranslatePackageDescriptionPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string): string {
    let translatePhrase = '';
    if (value.includes('"Hello" package to e-commerce')) {
      translatePhrase = 'SUBSCRIPTION.STARTER_PACKAGE_DESCRIPTION';
    }
    if (value.includes('The best to explore global trade')) {
      translatePhrase = 'SUBSCRIPTION.PROFESSIONAL_PACKAGE_DESCRIPTION';
    }
    if (value.includes('Best for scaling businesses')) {
      translatePhrase = 'SUBSCRIPTION.BUSINESS_PACKAGE_DESCRIPTION';
    }
    return this.translateService.instant(translatePhrase);
  }
}
