import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'httpAdder',
  standalone: true
})
export class HttpAdderPipe implements PipeTransform {
  transform(url: string): string {
    return url.startsWith('https://') || url.startsWith('http://')
      ? url
      : 'https://' + url;
  }
}
