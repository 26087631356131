<div
  class="dropdown"
  data-toggle="tooltip"
  data-theme="dark"
  title=""
  data-placement="top"
  (click)="upgradePackage()"
>
  <div
    class="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2 -mt-1px"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="true"
    [title]="ButtonTitle"
  >
    <span class="symbol w-106px symbol-light-success">
      <span
        class="bg-primary px-2 py-1 py-lg-3 text-white h-lg-35px h-25px symbol font-weight-bold font-size-sm"
      >
        <fa-icon [icon]="faBox" class="mr-2"></fa-icon> {{ PackageType }}
      </span>
    </span>
  </div>
</div>

<app-animated-logo *ngIf="isLoading == true"></app-animated-logo>
