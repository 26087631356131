import { Component, Input } from '@angular/core';

import { AutoUnsubscribe } from '@shopiroller/decorators';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SubSink } from 'subsink';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { selectCurrentSubscriptionPackagesInfo } from '../../data-access';
import { SubscriptionFeaturesTypes } from '../../data';
import { AnimatedLogoModule } from '@shopiroller/ui';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';

@AutoUnsubscribe()
@Component({
  selector: 'app-cart-upgrade-button',
  templateUrl: './cart-upgrade-button.component.html',
  styleUrls: ['./cart-upgrade-button.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FontAwesomeModule,
    RouterModule,
    AnimatedLogoModule
  ]
})
export class CartUpgradeButtonComponent {
  @Input()
  public ButtonText: string = '';

  @Input()
  public ButtonTitle: string = '';

  @Input()
  public featureName: SubscriptionFeaturesTypes;

  public faStar = faStar;

  public isLoading = false;

  private _subs = new SubSink();

  constructor(
    private _store: Store,
    private _router: Router,
    private _translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this._setButtonTitle();
  }

  public upgradePackage(): void {
    this._router.navigateByUrl('/store/subscription/packages');
  }

  private _setButtonTitle(): void {
    this._subs.sink = this._store
      .select(selectCurrentSubscriptionPackagesInfo)
      .subscribe((packageData) => {
        if (!packageData) return;
        const upgradePackageName = packageData.type.toUpperCase();

        this.ButtonTitle = this._translateService.instant(
          'SUBSCRIPTION.REQUIRES_SUBSCRIPTION',
          {
            planType: this._translateService.instant(
              `SUBSCRIPTION.${upgradePackageName}`
            )
          }
        );
      });
  }
}
