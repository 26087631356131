export type SubscriptionTypes = 'Starter' | 'Professional' | 'Business';

export enum SubscriptionTypesEnum {
  'Starter' = 'Starter',
  'Professional' = 'Professional',
  'Business' = 'Business',
}




