import { IAppConfiguration } from './app-configuration.interface';
import { Injectable } from '@angular/core';
import { IFeatureData, featuresNames } from '@shopiroller/data';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfigurationService {
  public configurations!: IAppConfiguration;

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  constructor() {}

  public load(): void {
    this.configurations = <IAppConfiguration>environment;
  }

  public get features(): IFeatureData[] {
    return this.configurations.features;
  }

  public getFeatureData(featureName: featuresNames): IFeatureData | undefined {
    return this.features.find((x) => x.name == featureName);
  }

  public isFeatureEnabled(featureName: featuresNames): boolean {
    return this.features.find((x) => x.name == featureName)?.isActive || false;
  }
}
