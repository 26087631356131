import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translatePackageName',
  standalone: true
})
export class TranslatePackageNamePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string): string {
    let translatePhrase = '';
    if (value.includes('Starter')) {
      translatePhrase = 'SUBSCRIPTION.STARTER';
    }
    if (value.includes('Professional')) {
      translatePhrase = 'SUBSCRIPTION.PROFESSIONAL';
    }
    if (value.includes('Business')) {
      translatePhrase = 'SUBSCRIPTION.BUSINESS';
    }

    return this.translateService.instant(translatePhrase);
  }
}
