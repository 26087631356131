import { AppSumoFeatureKey, AppSumoFeatureState } from './appsumo.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const appSumoState =
  createFeatureSelector<AppSumoFeatureState>(AppSumoFeatureKey);
export const selectUserAppSumoMembershipStatus = createSelector(
  appSumoState,
  ({ currentUserIsAppSumoMember }) => {
    return currentUserIsAppSumoMember;
  }
);
export const selectUserAppSumoMembershipLoadingStatus = createSelector(
  appSumoState,
  ({ status }) => status
);
