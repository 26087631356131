import {
  loadSubscriptionInfo
} from '../../data-access';

import { APP_INITIALIZER } from '@angular/core';
import { Store } from '@ngrx/store';

export const SUBSCRIPTION_APP_INITIALIZER_PROVIDER = [
  {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [Store],
    useFactory: (store: Store) => {
      return () => {
        return store.dispatch(loadSubscriptionInfo());
      };
    }
  }
];
