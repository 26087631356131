import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';

import { AppConfigurationService } from '@shopiroller/core';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { featuresList } from '@shopiroller/data';
import { map } from 'rxjs/internal/operators/map';
import { selectFeaturePermissionInCurrentPackage } from '../../data-access';

@Injectable({
  providedIn: 'root'
})
export class PackageSubscriptionGuard implements CanActivate {
  constructor(
    private _store: Store,
    private _router: Router,
    private _appConfigurationService: AppConfigurationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    try {
      const {
        data: { subscriptionFeature, feature }
      } = route;
      const subscriptionIsEnabled =
        this._appConfigurationService.isFeatureEnabled(
          featuresList.subscription
        );

      if (!subscriptionIsEnabled) {
        return of(true);
      }
      return this._store
        .select(selectFeaturePermissionInCurrentPackage(subscriptionFeature))
        .pipe(map((featureIsEnabled) => featureIsEnabled));
    } catch (error) {
      this._router.navigateByUrl('/stores/list');
      return of(false);
    }
  }
}
