import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { END_POINTS_URL } from '@shopiroller/data';
import { IInsertSaleChannel } from '../../data/models/i-insert-sale-channel.model';
import { IUpdateSaleChannel } from '../../data/models/i-update-sale-channel.model';
import { Injectable } from '@angular/core';
import { SaleChannelTypes } from '../../data/models/i-channel-type.model';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root',
})
export class SalesChannelService {
  public supportedSalesChannel$: Observable<string[]>;
  public supportedSalesChannelSubject: BehaviorSubject<string[]>;
  private readonly SALES_CHANNELS_BASE_URL = `${END_POINTS_URL.V2.SALES_CHANNELS_URL}`;

  constructor(private httpClient: HttpClient) {
    this.supportedSalesChannelSubject = new BehaviorSubject<string[]>([]);
    this.supportedSalesChannel$ =
      this.supportedSalesChannelSubject.asObservable();
  }

  public updateSupportedSalesChannel(salesChannels: string[]) {
    this.supportedSalesChannelSubject.next(salesChannels);
  }

  /**
   * @param {string} appId
   * @return {fetch all sales channels include channels of mobile, web,...}
   * @memberof SalesChannelService
   * related API: https://dev.ecommerce.applyze.com/api-docs/index.html?urls.primaryName=2.0#/SalesChannels/GetAllSalesChannels
   *
   */
  public getAllChannels(appId: string) {
    const URL = `${this.SALES_CHANNELS_BASE_URL}/${appId}/getall`;
    return this.httpClient.get(URL);
  }

  /**
   * @return {*}
   * @memberof SalesChannelService
   * related API: https://dev.ecommerce.applyze.com/api-docs/index.html?urls.primaryName=2.0#/SalesChannels/GetSingleSalesChannel
   */
  public getSingleChannel(appId: string, channelId: string) {
    const URL = `${this.SALES_CHANNELS_BASE_URL}/${appId}/getsingle`;
    const Options = {
      headers: new HttpHeaders({
        aliasKey: channelId,
      }),
    };
    return this.httpClient.get(URL, Options);
  }

  /**
   * @return {  list of supported channel for specific app  }
   * @memberof SalesChannelService
   * related API: https://dev.ecommerce.applyze.com/api-docs/index.html?urls.primaryName=2.0#/SalesChannels/GetSupportedSalesChannels
   */
  public getSupportedChannel(appId: string) {
    const URL = `${this.SALES_CHANNELS_BASE_URL}/${appId}/supportedChannels`;
    return this.httpClient.get(URL).pipe(
      map((result: any) => {
        const supportedSalesChannels = result.data as string[];
        this.updateSupportedSalesChannel(supportedSalesChannels);
        return supportedSalesChannels;
      })
    );
  }

  /**
   * @return {  list of mobile sales channel  }
   * @memberof SalesChannelService
   * related API: https://dev.ecommerce.applyze.com/api-docs/index.html?urls.primaryName=2.0#/SalesChannels/GetMobileChannels
   */
  public getMobileChannels(appId: string) {
    const URL = `${this.SALES_CHANNELS_BASE_URL}/${appId}/getMobileChannels`;
    return this.httpClient.get(URL).pipe(map((result: any) => result.data));
  }

  /**
   * @param {string} appId
   * @param {SaleChannelTypes} channelType
   * @return { list of supported sales channel even main sales channel of app}
   * related API: https://dev.ecommerce.applyze.com/api-docs/index.html?urls.primaryName=2.0#/SalesChannels/GetSalesChannelByChannelType
   */
  public getChannelListByChannelType(
    appId: string,
    channelType: SaleChannelTypes
  ) {
    const URL = `${this.SALES_CHANNELS_BASE_URL}/${appId}/getByChannelType?channelType=${channelType}`;
    return this.httpClient.get(URL).pipe(map((result: any) => result.data));
  }

  /**
   * active/deActive specific sales channels
   * @memberof SalesChannelService
   * related API: https://dev.ecommerce.applyze.com/api-docs/index.html?urls.primaryName=2.0#/SalesChannels/changeActivity
   */
  public changeActivityOfSaleChannel(appId: string, channelId: string) {
    const URL = `${this.SALES_CHANNELS_BASE_URL}/${appId}/changeActivity`;
    const headers = { aliasKey: channelId };
    return this.httpClient.put(URL, null, { headers });
  }

  /**
   * adds new sale channel
   * @memberof SalesChannelService
   * related API: https://dev.ecommerce.applyze.com/api-docs/index.html?urls.primaryName=2.0#/SalesChannels/InsertSalesChannel
   */
  public insertNewSaleChannel(appId: string, payload: IInsertSaleChannel) {
    const URL = `${this.SALES_CHANNELS_BASE_URL}/${appId}/insert`;
    return this.httpClient.post(URL, payload);
  }

  /**
   *  update existing sale channel
   * @memberof SalesChannelService
   * related API: https://dev.ecommerce.applyze.com/api-docs/index.html?urls.primaryName=2.0#/SalesChannels/UpdateSalesChannel
   */
  public updateSaleChannel(
    appId: string,
    channelId: string,
    payload: IUpdateSaleChannel
  ) {
    const URL = `${this.SALES_CHANNELS_BASE_URL}/${appId}/update`;
    const Options = {
      headers: new HttpHeaders({
        aliasKey: channelId,
      }),
    };
    return this.httpClient.patch(URL, payload,Options);
  }

  /**
   * @param {string} appId
   * @param {string} channelId
   * @memberof SalesChannelService
   * related API: https://dev.ecommerce.applyze.com/api-docs/index.html?urls.primaryName=2.0#/SalesChannels/DeleteSalesChannel
   */
  public deleteSaleChannel(appId: string, channelId: string) {
    const URL = `${this.SALES_CHANNELS_BASE_URL}/${appId}/delete`;
    const Options = {
      headers: new HttpHeaders({
        aliasKey: channelId,
      }),
    };
    return this.httpClient.delete(URL, Options);
  }
}
