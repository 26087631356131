import { Routes } from '@angular/router';
import { featuresList } from '@shopiroller/data';
import { PackageSubscriptionGuard, SubscriptionFeaturesEnum } from '@shopiroller/store/subscription';
import { FeatureGuard } from '@shopiroller/core';

export const STORE_SETTINGS_ROUTES: Routes = [
  {
    path: 'store-information',
    loadComponent: async () =>
      (
        await import(
          '../features/pages/store-information-page/store-information-page.component'
        )
      ).StoreInformationPageComponent
  },
  {
    path: 'email-notifications',
    loadComponent: async () =>
      (
        await import(
          '../features/pages/email-notification-page/email-notification-page.component'
        )
      ).EmailNotificationPageComponent
  },
  {
    path: 'legal-pages',
    loadComponent: async () =>
      (
        await import(
          '../features/pages/legal-pages-page/legal-pages-page.component'
        )
      ).LegalPagesPageComponent
  },
  {
    path: 'shipping-and-delivery',
    loadComponent: async () =>
      (
        await import(
          '../features/pages/shipping-delivery-page/shipping-delivery-page.component'
        )
      ).ShippingDeliveryPageComponent
  },
  {
    path: 'language-list',
    loadComponent: async () =>
      (
        await import(
          '../features/pages/language-list-page/language-list-page.component'
        )
      ).LanguageListPageComponent
  },
  {
    path: 'payment',
    loadComponent: async () =>
      (
        await import(
          '../features/pages/payment-providers-page/payment-providers-page.component'
        )
      ).PaymentProvidersPageComponent
  },
  {
    path: 'slide-management/:sliderType',
    loadComponent: async () =>
      (
        await import(
          '../features/pages/slide-management-page/slide-management-page.component'
        )
      ).SlideManagementPageComponent
  },
  {
    path: 'region-list',
    loadComponent: async () =>
      (
        await import(
          '../features/pages/region-list-page/region-list-page.component'
        )
      ).RegionListPageComponent,

    data: {
      feature: featuresList.regionAndLocalization,
      subscriptionFeature:
        SubscriptionFeaturesEnum.settings_LocationBasedRouting
    },
    canActivate: [FeatureGuard, PackageSubscriptionGuard]
  },
  {
    path: 'region-add',
    loadComponent: async () =>
      (
        await import(
          '../features/pages/region-management-page/region-management-page.component'
        )
      ).RegionManagementPageComponent,
    data: {
      feature: featuresList.regionAndLocalization,
      subscriptionFeature:
        SubscriptionFeaturesEnum.settings_LocationBasedRouting
    },
    canActivate: [FeatureGuard, PackageSubscriptionGuard]
  },
  {
    path: 'region-edit/:id',
    loadComponent: async () =>
      (
        await import(
          '../features/pages/region-management-page/region-management-page.component'
        )
      ).RegionManagementPageComponent,
    data: {
      feature: featuresList.regionAndLocalization,
      subscriptionFeature:
        SubscriptionFeaturesEnum.settings_LocationBasedRouting
    },
    canActivate: [FeatureGuard, PackageSubscriptionGuard]
  },
  {
    path: '**',
    redirectTo: 'store-information',
    pathMatch: 'full'
  }
];
