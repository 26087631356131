import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/internal/operators/map';
import { Injectable } from '@angular/core';
import {
  CreateWebHookFailure,
  CreateWebHookSuccess,
  DeleteWebHookFailure,
  DeleteWebHookSuccess,
  UpdateWebHookFailure,
  UpdateWebHookSuccess,
  checkWebhookActivenessFailure
} from '@shopiroller/store/webhook';
import { StoreActionsMessageTypesList } from './store-actions-message.model';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import {
  UpdateAppWebsettingFailure,
  UpdateAppWebsettingSuccess,
  addNewLanguageFailure,
  addNewLanguageSuccess,
  changeDefaultLanguageOfAppFailure,
  changeDefaultLanguageOfAppSuccess,
  createStoreFailure,
  deleteAppLanguageFailure,
  deleteAppLanguageSuccess
} from '@shopiroller/stores';
import {
  activateWhatsappFailure,
  activateWhatsappSuccess,
  updateCartReminderFailure,
  updateCartReminderSuccess,
  updateWhatsappFailure,
  updateWhatsappSuccess
} from '@shopiroller/store/apps';
import {
  addNewRegionFailure,
  addNewRegionSuccess,
  deleteRegionFailure,
  deleteRegionSuccess,
  updateExistingRegionSuccess,
  updateExistingRegionFailure,
  updatePaymentInformationFailure,
  updatePaymentInformationSuccess,
  updateShippingInformationFailure,
  updateShippingInformationSuccess,
  updateStoreInformationFailure,
  updateStoreInformationSuccess,
  loadListOfSlidersFailure,
  addSliderFailure,
  deleteSlideFailure,
  deleteSlideSuccess,
  addSlideSuccess,
  addSlideFailure,
  updateExisitingSlideSuccess,
  updateExisitingSlideFailure
} from 'src/app/modules/store-settings';
import {
  changeActivityOfSaleChannelFailure,
  changeActivityOfSaleChannelSuccess,
  connectMagentySaleChannelFailure,
  connectMagentySaleChannelSuccess,
  updateSaleChannelFailure,
  updateSaleChannelSuccess
} from '@shopiroller/store/sales-channel';

@Injectable({
  providedIn: 'root'
})
export class StoreActionsResultMessageEffect {
  private readonly _actionsForShowingMessage = [
    DeleteWebHookSuccess,
    DeleteWebHookFailure,
    UpdateWebHookSuccess,
    UpdateWebHookFailure,
    CreateWebHookSuccess,
    CreateWebHookFailure,
    checkWebhookActivenessFailure,
    UpdateAppWebsettingSuccess,
    UpdateAppWebsettingFailure,
    updateCartReminderSuccess,
    updateCartReminderFailure,
    updateWhatsappSuccess,
    updateWhatsappFailure,
    updateStoreInformationSuccess,
    updateStoreInformationFailure,
    updatePaymentInformationSuccess,
    updatePaymentInformationFailure,
    updateShippingInformationSuccess,
    updateShippingInformationFailure,
    addNewLanguageFailure,
    addNewLanguageSuccess,
    deleteAppLanguageSuccess,
    deleteAppLanguageFailure,
    changeDefaultLanguageOfAppSuccess,
    changeDefaultLanguageOfAppFailure,
    deleteRegionSuccess,
    deleteRegionFailure,
    addNewRegionSuccess,
    addNewRegionFailure,
    updateExistingRegionSuccess,
    updateExistingRegionFailure,
    loadListOfSlidersFailure,
    addSliderFailure,
    addSlideSuccess,
    addSlideFailure,
    updateExisitingSlideSuccess,
    updateExisitingSlideFailure,
    deleteSlideSuccess,
    deleteSlideFailure,
    changeActivityOfSaleChannelSuccess,
    changeActivityOfSaleChannelFailure,
    updateSaleChannelSuccess,
    updateSaleChannelFailure,
    activateWhatsappSuccess,
    activateWhatsappFailure,
    connectMagentySaleChannelSuccess,
    connectMagentySaleChannelFailure,
    createStoreFailure
  ];

  /**
   * Result actions are those actions that manage the result of an action, let's suppose we have an action called DeleteWebhook,
   * the possible results would be DeleteWebHookSuccess and DeleteWebHookFailure and when these actions are dispatched somewhere
   * in the project we are supposed to react to them, show a message to the user,
   * etc. with this effect we are going to listen to these types of actions and show a message to users
   */
  public listenToAllResultActions$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(...this._actionsForShowingMessage),
        map(({ payload }) => {
          if (payload.result.message == '') {
            return;
          }
          this.messageService.add({
            severity:
              payload.result.messageType == StoreActionsMessageTypesList.Success
                ? 'success'
                : 'error',
            summary:
              payload.result.messageType == StoreActionsMessageTypesList.Success
                ? this._translateService.instant('SHARED.MESSAGES.SUCCESS')
                : this._translateService.instant('SHARED.MESSAGES.ERROR'),
            detail: this._translateService.instant(payload.result.message)
          });
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    public messageService: MessageService,
    private _actions$: Actions,
    private _translateService: TranslateService
  ) {}
}
