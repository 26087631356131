import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AutoUnsubscribe } from '@shopiroller/decorators';
import { Store } from '@ngrx/store';
import { SubSink } from 'subsink';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { LanguageService } from 'src/app/modules/store-settings';
import { selectCurrentAppLanguageList } from '@shopiroller/stores';

@AutoUnsubscribe()
@Component({
  selector: 'app-translation-section',
  templateUrl: './translation-section.component.html',
  styleUrls: ['./translation-section.component.scss']
})
export class TranslationSectionComponent implements OnInit {
  faTimesCircle = faTimesCircle;
  selectedAppLanguages: any;
  languagesFetched = false;
  @Input()
  currentlySelectedLanguage: any;
  languageForAddingTranslation: any[] = []; // shows main list
  selectedLanguageForAddingTranslation: any[] = []; // shows chosen langs for translation
  @ViewChild('langsDropDown') langsDropDown!: ElementRef;
  @Output() languageListFetched = new EventEmitter<any[]>();
  @Output() selectedLanguageForAddingTranslationChanged = new EventEmitter<
    any[]
  >();
  @Output() newLanguageAdded = new EventEmitter<any>();
  @Output() languageRemoved = new EventEmitter<any[]>();
  @Output() languageSelectEvent = new EventEmitter<any>();
  @Input('showLanguageListAndTagsInDifferentLine')
  showLanguageListAndTagsInDifferentLine: boolean = false;

  private _subs = new SubSink();

  constructor(
    private _store: Store,
    private _languageService: LanguageService,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._getLanguages();
    this._listenToPreSelectedLangs();
  }

  handleChangeLanguage(event): void {
    const value = event.target.value;
    this.languageForAddingTranslation =
      this.languageForAddingTranslation.filter((x) => x.isO2 !== value);
    const chosenLanguage = this.selectedAppLanguages.supportedLanguages.find(
      (x) => x.isO2 === value
    );

    const choosenLanguageExists =
      this.selectedLanguageForAddingTranslation.some(
        (x) => x.isO2 === chosenLanguage.isO2
      );
    if (!choosenLanguageExists) {
      this.selectedLanguageForAddingTranslation.push(chosenLanguage);
    }

    this.newLanguageAdded.emit(chosenLanguage);
    this.selectedLanguageForAddingTranslationChanged.emit(
      this.selectedLanguageForAddingTranslation
    );
    this.currentlySelectedLanguage = chosenLanguage;
    this.langsDropDown.nativeElement.value = 'none';
  }

  removeSelectedLanguageForTranslation(removedLangIsO2): void {
    const removedLang = this.selectedAppLanguages.supportedLanguages.find(
      (x) => x.isO2 === removedLangIsO2
    );
    this.languageRemoved.emit(removedLang);
    this.languageForAddingTranslation.push(removedLang);
    this.selectedLanguageForAddingTranslation =
      this.selectedLanguageForAddingTranslation.filter(
        (x) => x.isO2 !== removedLangIsO2
      );
    this.selectedLanguageForAddingTranslationChanged.emit(
      this.selectedLanguageForAddingTranslation
    );
  }

  handleLanguageSelectEvent(event, lang): void {
    this.currentlySelectedLanguage = lang;
    this.languageSelectEvent.emit(lang);
  }

  private _getLanguages(): void {
    this._subs.sink = this._store
      .select(selectCurrentAppLanguageList)
      .subscribe((langs) => {
        if (!langs) return;
        this.languageListFetched.emit(langs);
        this.selectedAppLanguages = langs;
        this.currentlySelectedLanguage = langs.defaultLanguage;
        const filteredSupportedLanguages =
          this.selectedAppLanguages.supportedLanguages.filter(
            (x) => x.isO2 !== langs.defaultLanguage.isO2
          );
        this.selectedAppLanguages = {
          ...this.selectedAppLanguages,
          supportedLanguages: filteredSupportedLanguages
        };
        this.selectedAppLanguages.supportedLanguages =
          this.selectedAppLanguages.supportedLanguages.filter(
            (x) => x.isO2 !== langs.defaultLanguage.isO2
          );
        this.languageForAddingTranslation =
          this.selectedAppLanguages.supportedLanguages;
        this.languagesFetched = true;
      });
  }

  private _listenToPreSelectedLangs(): void {
    this.selectedLanguageForAddingTranslation = [];
    this._languageService.setPreSelectedLanguages(null);
    this._subs.sink = this._languageService.preSelectedLanguages$.subscribe(
      (lang: any) => {
        if (lang) {
          const languageExists = this.selectedLanguageForAddingTranslation.some(
            (x) => x.isO2 === lang.isO2
          );
          if (!languageExists) {
            this.selectedLanguageForAddingTranslation.push(lang);
          }
          this.languageForAddingTranslation =
            this.languageForAddingTranslation.filter(
              (x) => x.isO2 !== lang.isO2
            );
          this._cdr.detectChanges();
        }
      }
    );
  }
}
