<button
  (click)="upgradePackage()"
  class="btn btn-success"
  [title]="ButtonTitle"
  [disabled]="isLoading == true"
>
  <fa-icon [icon]="faStar" class="mr-2"></fa-icon>
  <span *ngIf="ButtonText != ''; else defaultButtonText">{{ ButtonText }}</span>
  <ng-template #defaultButtonText>
    {{ 'SUBSCRIPTION.UPGRADE_TO_GET_THE_APP' | translate }}
  </ng-template>
</button>
<app-animated-logo *ngIf="isLoading == true"></app-animated-logo>
