import { GenericState, GenericStoreStatus } from '@shopiroller/core';
import { loadClientList, loadClientListFailure } from './client.actions';
import { createReducer, on } from '@ngrx/store';

export const clientFeatureKey = 'feature-client';
export class clientFeatureState implements GenericState<any> {
  appId!: string;
  status: GenericStoreStatus | any = 'pending';
  error: any = null;
}
const initialState: clientFeatureState = {
  appId: '',
  error: null,
  status: 'pending'
};
export const clientFeatureReducer = createReducer(
  initialState,
  on(loadClientList, (state) => ({
    ...state,
    status: 'loading'
  })),
  on(loadClientListFailure, (state, { error }) => ({
    ...state,
    status: 'error',
    error: error
  }))
);
