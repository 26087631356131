import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  dashboardFeatureKey,
  dashboardFeatureState
} from './dashboard.reducer';

export const dashboardState =
  createFeatureSelector<dashboardFeatureState>(dashboardFeatureKey);

export const selectDashboardPageData = createSelector(
  dashboardState,
  ({ appId, lastCustomers, lastOrders, todayOrdersData, integrationSteps }) => {
    const integrationStepsCount = !integrationSteps
      ? 0
      : integrationSteps.length;
    const completedIntegrationStepCount = !integrationSteps
      ? 0
      : integrationSteps.filter((x) => x.isCompleted).length;
    const showIntegrationSteps = !integrationSteps
      ? false
      : !!integrationSteps.find((x) => x.isCompleted === false);

    return {
      appId,
      lastCustomers,
      lastOrders,
      todayOrdersData,
      integrationSteps: {
        steps: integrationSteps,
        integrationStepsCount: integrationStepsCount,
        completedIntegrationStepCount: completedIntegrationStepCount,
        showIntegrationSteps: showIntegrationSteps
      }
    };
  }
);

export const selectDashboardPageIsLoading = createSelector(
  dashboardState,
  ({ status }) => {
    return status == 'loading' || status == 'pending';
  }
);
