  <div
    [ngClass]="
      subHeaderClass != ''
        ? subHeaderClass
        : 'subheader py-2 py-lg-6 subheader-transparent'
    "
    id="kt_subheader"
  >
    <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      <ng-container *ngIf="!headerContentIsDynamic">
        <div
          class="d-flex align-items-center flex-wrap mr-1"
          [ngClass]="headerIsEmpty == true ? 'py-0' : 'py-10'"
        >
          <div class="d-flex align-items-baseline flex-wrap mr-5 flex-column">
            <h2 class="text-dark font-weight-bolder my-1 mr-5">
              {{ pageTitle }}
            </h2>
            <ul
              *ngIf="showBreadCrumb == true"
              class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm"
            >
              <li
                class="breadcrumb-item text-muted"
                *ngFor="let item of breadCrumbItems"
              >
                <a
                  *ngIf="item.href != ''; else disableItem"
                  class="text-muted"
                  [routerLink]="item.href"
                  routerLinkActive="router-link-active"
                  >{{ item.title | translate }}</a
                >
                <ng-template #disableItem>
                  <span class="text-muted">{{ item.title | translate }}</span>
                </ng-template>
              </li>
            </ul>
          </div>
        </div>
      </ng-container>

      <div
        #headerContainer
        class="d-flex align-items-center flex-wrap"
        [ngClass]="headerContentIsDynamic ? 'container pr-0 pl-0' : ''"
      >
        <!-- Header content will renders here -->
        <ng-content select="[header]"></ng-content>
      </div>
    </div>
  </div>


    <!--Main content will renders here -->
    <ng-content></ng-content>
    <!--Main content in a card panel will renders here -->
    <!-- <p-blockUI [blocked]="true" [autoZIndex]="true" *ngIf="showSpinner == true"> -->
    <app-animated-logo *ngIf="showSpinner == true"></app-animated-logo>
    <!-- </p-blockUI> -->
    <ng-container
      *ngIf="
        cardContentEnable == true &&
        dataIsFetched == true &&
        isCustomCard == false
      "
    >
        <div>
          <ng-content select="[middleArea]"></ng-content>
        </div>
        <div class="card card-custom gutter-b mt-2">
          <div class="card-body">
            <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
          </div>
        </div>
    </ng-container>
    <ng-container
      *ngIf="
        cardContentEnable == true &&
        dataIsFetched == true &&
        isCustomCard == true
      "
    >
      <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
    </ng-container>
    <ng-template #contentTemplate>
      <ng-content select="[cardContent]"></ng-content>
    </ng-template>
