import { BrandSlugSuggestion } from '../models/brand.model';
import { END_POINTS_URL } from '@shopiroller/data';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class BrandsService {
  constructor(private http: HttpClient) {}

  public getBrandsList(appId: string): Observable<any> {
    const url = `${END_POINTS_URL.V2.BRANDS_URL}/apps/${appId}`;
    return this.http.get(url);
  }

  public filterBrands(
    appId: string,
    brandName: string = '',
    page: number = 1,
    sortBy: string = 'Descending',
    sortField: 'Name' | 'CreateDate' | 'UpdateDate' = 'CreateDate'
  ): Observable<any> {
    const URL =
      brandName === ''
        ? `${END_POINTS_URL.V2.BRANDS_URL}/apps/${appId}/filter?page=${page}&perPage=10&sortBy=${sortBy}&sort=${sortField}`
        : `${END_POINTS_URL.V2.BRANDS_URL}/apps/${appId}/filter?name=${brandName}&page=${page}&perPage=10&sortBy=${sortBy}&sort=${sortField}`;
    return this.http.get(URL);
  }
  /**
   *
   * @param categoryName should pass a value when you are searching for category otherwise we dont need to pass anything to this parameter
   * @param sort Available values : CreateDate, UpdateDate
   * @param sortBy Available values : Ascending, Descending
   */
  public sortBrands(
    appId: string,
    brandName: string = '',
    sort: string,
    sortBy: string
  ): any {
    const url =
      brandName.trim() !== ''
        ? `${END_POINTS_URL.V2.BRANDS_URL}/apps/${appId}/filter?name=${brandName}&sortBy=${sortBy}&sort=${sort}`
        : `${END_POINTS_URL.V2.CATEGORIES_URL}/apps/${appId}/filter?sortBy=${sortBy}&sort=${sort}`;
    return this.http.get(url);
  }

  public searchBrandsByName(appId: string, brandName: string): any {
    const url = `${END_POINTS_URL.V2.BRANDS_URL}/apps/${appId}/filter?name=${brandName}&sortBy=Descending&sort=CreateDate`;
    return this.http.get(url);
  }

  public addNewBrand(appId: string, newBrand: any): Observable<boolean> {
    const url = `${END_POINTS_URL.V2.BRANDS_URL}/apps/${appId}`;
    return this.http
      .post(url, newBrand)
      .pipe(map((result: any) => result.success));
  }

  public updateBrand(appId: string, brand: any): Observable<boolean> {
    const url = `${END_POINTS_URL.V2.BRANDS_URL}/${brand.id}/apps/${appId}`;
    return this.http.put(url, brand, { observe: 'response' }).pipe(
      map((result: any) => {
        return result.status === 200 ||
          result.status === 201 ||
          result.status === 204
          ? true
          : false;
      })
    );
  }

  public activeBrand(appId: string, brandId: string): Observable<boolean> {
    const url = `${END_POINTS_URL.V2.BRANDS_URL}/${brandId}/active/apps/${appId}`;
    return this.http.put(url, null, { observe: 'response' }).pipe(
      map((result: any) => {
        return result.status === 200 ||
          result.status === 201 ||
          result.status === 204
          ? true
          : false;
      })
    );
  }

  public passiveBrand(appId: string, brandId: string): Observable<boolean> {
    const url = `${END_POINTS_URL.V2.BRANDS_URL}/${brandId}/passive/apps/${appId}`;
    return this.http.put(url, null, { observe: 'response' }).pipe(
      map((result: any) => {
        return result.status === 200 ||
          result.status === 201 ||
          result.status === 204
          ? true
          : false;
      })
    );
  }

  public deleteBrand(appId: string, brandId: string): Observable<boolean> {
    const url = `${END_POINTS_URL.V2.BRANDS_URL}/${brandId}/apps/${appId}`;
    return this.http.delete(url, { observe: 'response' }).pipe(
      map((result: any) => {
        return result.status === 200 ||
          result.status === 201 ||
          result.status === 204
          ? true
          : false;
      })
    );
  }

  public generateSlugSuggestion(appId: string, payload: BrandSlugSuggestion): Observable<string> {
    const url = `${END_POINTS_URL.V2.BRANDS_URL}/apps/${appId}/slugs`;
    return this.http
      .post(url, payload)
      .pipe(map((result: any) => result.data));
  }
}
