import { SubscriptionTypes } from '../types';

export interface SubscriptionPackageModel {
  name: SubscriptionTypes;
  image: string;
  description: string;
  features: string[];
  periodAndPrices: PackagePeriodAndPriceModel[];
}

export interface PackagePeriodAndPriceModel {
  period: 'year' | 'month';
  priceId: string;
  price: number;
  currency: string;
}
