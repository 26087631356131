import { END_POINTS_URL } from '@shopiroller/data';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { MultiLanguageSlugSuggestionModel } from '../../../data';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  constructor(private http: HttpClient) {}

  public getCategory(appId: string, categoryId: string): Observable<any> {
    const url = `${END_POINTS_URL.V2.CATEGORIES_URL}/apps/${appId}/categories/${categoryId}?includePassives=true`;
    return this.http.get(url);
  }

  public getSingleCategoryWithSubCategories(
    appId: string,
    categoryId: string
  ): any {
    const url = `${END_POINTS_URL.V2.CATEGORIES_URL}/${categoryId}`;
    return this.http.get(url);
  }

  public filterCategories(
    appId: string,
    categoryName: string = '',
    iso2LanguageName: string,
    parentCategoryId: string
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.CATEGORIES_URL}/apps/${appId}/categories?name=${categoryName}&iso2LanguageName=${iso2LanguageName}&parentCategoryId=${parentCategoryId}&page=1&perPage=100&sortBy=Ascending&sort=OrderIndex`;
    return this.http.get(URL);
  }

  public getCategoriesList(appId: string): Observable<any> {
    const url = `${END_POINTS_URL.V2.CATEGORIES_URL}/apps/${appId}`;
    return this.http.get(url);
  }
  public getMultiLanguageCategoriesList(appId: string): Observable<any> {
    const url = `${END_POINTS_URL.V2.CATEGORIES_URL}/apps/${appId}/categories?sortBy=Descending&sort=OrderIndex&perPage=100`;
    return this.http.get(url);
  }

  public searchCategoriesByName(
    appId: string,
    categoryName: string,
    selectedLangIso2: string
  ): Observable<any> {
    const url = `${END_POINTS_URL.V2.CATEGORIES_URL}/apps/${appId}/categories?name=${categoryName}&iso2LanguageName=${selectedLangIso2}&sortBy=Descending&sort=OrderIndex&perPage=100`;
    return this.http.get(url);
  }

  public filterDataByLanguage(appId: string, lang: string): Observable<any> {
    const url = `${END_POINTS_URL.V2.CATEGORIES_URL}/apps/${appId}/categories?iso2LanguageName=${lang}&sortBy=Descending&sort=OrderIndex&perPage=100`;
    return this.http.get(url);
  }

  public addNewCategory(appId: string, newCategory: any): any {
    const url = `${END_POINTS_URL.V2.CATEGORIES_URL}/apps/${appId}`;
    return this.http.post(url, newCategory);
  }

  public addNewCategoryWithParentCategory(
    appId: string,
    newCategory: any,
    parentCategoryId: string
  ): Observable<any> {
    const url = `${END_POINTS_URL.V2.CATEGORIES_URL}/apps/${appId}/categories/${parentCategoryId}`;
    return this.http.post(url, newCategory);
  }

  public updateCategory(appId: string, category: any): Observable<boolean> {
    const url = `${END_POINTS_URL.V2.CATEGORIES_URL}/apps/${appId}/categories/${category.categoryId}`;
    return this.http.patch(url, category, { observe: 'response' }).pipe(
      map((result: any) => {
        return result.status === 200 ||
          result.status === 201 ||
          result.status === 204
          ? true
          : false;
      })
    );
  }

  public deleteCategory(
    appId: string,
    categoryId: string
  ): Observable<boolean> {
    const url = `${END_POINTS_URL.V2.CATEGORIES_URL}/apps/${appId}/categories/${categoryId}`;
    return this.http.delete(url, { observe: 'response' }).pipe(
      map((result: any) => {
        return result.status === 200 ||
          result.status === 201 ||
          result.status === 204
          ? true
          : false;
      })
    );
  }
  public updateOrderIndex(appId: string, data: any): Observable<any> {
    const url = `${END_POINTS_URL.V2.CATEGORIES_URL}/apps/${appId}/move`;
    return this.http.put(url, data).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  public passiveCategory(
    appId: string,
    categoryId: string
  ): Observable<boolean> {
    const url = `${END_POINTS_URL.V2.CATEGORIES_URL}/apps/${appId}/categories/${categoryId}/passive`;
    return this.http.put(url, null, { observe: 'response' }).pipe(
      map((result: any) => {
        return result.status === 200 ||
          result.status === 201 ||
          result.status === 204
          ? true
          : false;
      })
    );
  }

  public activeCategory(
    appId: string,
    categoryId: string
  ): Observable<boolean> {
    const url = `${END_POINTS_URL.V2.CATEGORIES_URL}/apps/${appId}/categories/${categoryId}/active`;
    return this.http.put(url, null, { observe: 'response' }).pipe(
      map((result: any) => {
        return result.status === 200 ||
          result.status === 201 ||
          result.status === 204
          ? true
          : false;
      })
    );
  }

  public generateSlugSuggestion(
    appId: string,
    payload: MultiLanguageSlugSuggestionModel
  ): Observable<any> {
    const url = `${END_POINTS_URL.V2.CATEGORIES_URL}/apps/${appId}/slugs`;
    return this.http.post(url, payload).pipe(map((result: any) => result.data));
  }
}
