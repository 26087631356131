import { Routes } from '@angular/router';

export const CATALOG_ROUTES: Routes = [
  {
    path: 'products',
    loadChildren: async () =>
      (await import('../features/products/routes/products.routes')).PRODUCT_ROUTES
  },
  {
    path: 'brands',
    loadChildren: async () =>
      (await import('../features/brand/routes/brand.routes')).BRAND_ROUTES
  },
  {
    path: 'categories',
    loadChildren: async () =>
      (await import('../features/category/routes/category.routes')).CATEGORY_ROUTES
  },
  {
    path: 'showcase',
    loadChildren: async () =>
      (await import('../features/showcase/routes/showcase.routes')).SHOWCASE_ROUTES
  },
  {
    path: 'variant',
    loadChildren: async () =>
      (await import('../features/variant/routes/variant.routes')).VARIANT_ROUTES
  }
];
