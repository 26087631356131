import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

 import {
  MultiLanguageSlugSuggestionModel,
  SeoOptionsModel
} from '../../data/models/seo.model';
import { SubSink } from 'subsink';
import { Subject } from 'rxjs/internal/Subject';
import { finalize } from 'rxjs/internal/operators/finalize';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { GENERAL_VALUES } from '@shopiroller/data';
import { AnimatedLogoModule } from '@shopiroller/ui';
import { TranslateModule } from '@ngx-translate/core';
import { CategoriesService } from '../category';
import { ShowcaseService } from '../showcase';
import { AppSettingService } from '@shopiroller/core';

@Component({
  selector: 'app-multi-language-seo-container',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    AnimatedLogoModule
  ],
  templateUrl: './multi-language-seo-container.component.html',
  styleUrls: ['./multi-language-seo-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MultiLanguageSeoContainerComponent
  implements OnInit, OnDestroy, OnChanges
{
  isLoading = false;
  isFetchingSlugSuggestion = false;

  @Input()
  shouldSupportMultipleLanguages = false;

  @Input()
  previousData!: SeoOptionsModel;

  @Input()
  newNames: string[] = [];

  @Input()
  defaultLanguage: any;

  @Input()
  lastLanguageSelected: any;

  @Input()
  languageList: any[] = [];

  @Input()
  entityType!: 'category' | 'product' | 'showcase';

  @Input()
  slugService!: ShowcaseService | CategoriesService;

  @Output()
  onChange = new EventEmitter<SeoOptionsModel | null>();

  @Output()
  formValidationStatus = new EventEmitter<boolean>();

  seoOptionsForm!: FormGroup;

  private _subs = new SubSink();
  private _seoDataForEditMode: any = null;
  private _nameChangesSubject = new Subject<MultiLanguageSlugSuggestionModel>();

  constructor(
    private _fb: FormBuilder,
    private _appSettingService: AppSettingService
  ) {
    this._buildForm();
  }

  ngOnInit(): void {
    this._dispatchEvents();
    this._listenToChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const seoDataForEditMode = changes?.previousData?.currentValue;
    if (seoDataForEditMode != null) {
      this._seoDataForEditMode = seoDataForEditMode;
    }

    const currentLanguageList = changes?.languageList?.currentValue;
    const previousLanguageList = changes?.languageList?.previousValue;
    if (
      currentLanguageList?.length > 0 &&
      currentLanguageList != previousLanguageList
    ) {
      this.languageList = currentLanguageList;
    }

    if (this.languageList.length > 0) {
      this._addSeoOptionsForAllLanguages();
      if (this._seoDataForEditMode != null) {
        this._setSeoOptionsData(this._seoDataForEditMode);
      }
    }

    const lastLanguageSelected = changes?.lastLanguageSelected?.currentValue;
    if (lastLanguageSelected) {
      this.lastLanguageSelected = lastLanguageSelected;
    }
    const defaultLanguage = changes?.defaultLanguage?.currentValue;
    if (defaultLanguage) {
      this.defaultLanguage = defaultLanguage;
    }

    if (this._seoDataForEditMode == null) {
      const newNames = changes?.newNames;
      if (newNames && !newNames?.firstChange) {
        const currentName = newNames?.currentValue;
        const previousName = newNames?.previousValue;
        if (currentName && previousName && currentName != previousName) {
          const change: MultiLanguageSlugSuggestionModel = {
            name: currentName
          };
          this._nameChangesSubject.next(change);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  get seoOptions(): FormArray {
    return <FormArray>this.seoOptionsForm.get('options');
  }

  private _addSeoOptionsForAllLanguages(): void {
    if (this.seoOptions.controls.length == 0) {
      this.languageList.forEach((lang) => {
        const isO2 = lang.isO2;
        const form = this._fb.group({
          language: [isO2, Validators.required],
          slug: ['', Validators.required],
          pageTitle: ['', Validators.required],
          description: ['', Validators.required]
        });
        this.seoOptions.push(form);
      });
    }
  }

  private _setSeoOptionsData(previousSeoData): void {
    const seoOptionsValue: any[] = [];
    this.languageList.forEach((lang) => {
      const data = {
        description: previousSeoData.description[lang.isO2],
        language: lang.isO2,
        pageTitle: previousSeoData.pageTitle[lang.isO2],
        slug: previousSeoData.slug[lang.isO2]
      };
      seoOptionsValue.push(data);
    });
    this.seoOptions.patchValue(seoOptionsValue);
  }

  private _buildForm(): void {
    this.seoOptionsForm = this._fb.group({
      options: this._fb.array([])
    });
  }

  private _dispatchEvents(): void {
    if (this.previousData == null) {
      this.formValidationStatus.emit(false);
    }
    this._subs.sink = this.seoOptionsForm.valueChanges.subscribe((value) => {
      const formValueForAPI: any = {
        options: value.options.filter((x) => x.slug != '')
      };
      this.onChange.emit(formValueForAPI);
      const optionsThatShouldExamine = value.options.filter(
        (option) => option.slug != undefined
      ); // these options are those that their languages added

      const formIsValid = optionsThatShouldExamine.some(
        (x) =>
          Object.keys(x).length ==
          Object.values(x).filter((x: any) => x.trim() != '').length
      ); // prove all of the seo options such as slug,description & pageTitle have value
      this.formValidationStatus.emit(formIsValid);
    });
  }

  private _listenToChanges(): void {
    this._subs.sink = this._nameChangesSubject
      .pipe(debounceTime(GENERAL_VALUES.SEARCH_DELAY), distinctUntilChanged())
      .subscribe((change) => {
        this._fetchSlugSuggestion(change);
      });
  }

  private _fetchSlugSuggestion(
    payload: MultiLanguageSlugSuggestionModel
  ): void {
    const appId = this._appSettingService.getCurrentAppId();
    this.isFetchingSlugSuggestion = true;
    this.isLoading = true;
    if (this.entityType == 'category' || this.entityType == 'showcase') {
      const entityName = payload.name;
      Object.keys(entityName).forEach((key) => {
        if (entityName[key] == '') {
          delete entityName[key];
        }
      });
    }

    this._subs.sink = this.slugService
      .generateSlugSuggestion(appId, payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.isFetchingSlugSuggestion = false;
        })
      )
      .subscribe((suggestion: any) => {
        const newValue = this.seoOptions.value as Array<any>;
        Object.keys(suggestion).forEach((key) => {
          const idx = newValue.findIndex((x) => x.language == key);
          newValue[idx].slug = suggestion[key];
          this.seoOptions.setValue(newValue);
        });
      });
  }
}
