import { Routes } from '@angular/router';

import { FeatureGuard } from '@shopiroller/core';
import { featuresList } from '@shopiroller/data';
import { CollectionGuard } from '../utils/guards/collection.guard';

export const ORDERS_ROUTES: Routes = [
  {
    path: 'list',
    loadComponent: async () =>
      (await import('../features/order-list/order-list.component'))
        .OrderListComponent
  },
  {
    path: 'order-detail/:orderId',
    loadComponent: async () =>
      (await import('../features/order-detail/order-detail.component'))
        .OrderDetailComponent
  },
  {
    path: 'refund/:orderId',
    loadComponent: async () =>
      (await import('../features/refund/refund.component')).RefundComponent
  },
  {
    path: 'collection',
    data: {
      feature: featuresList.shoppay
    },
    canActivate: [FeatureGuard, CollectionGuard],
    loadComponent: async () =>
      (await import('../features/collection/collection.component'))
        .CollectionComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'list'
  }
];
