import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  storeSettingsFeatureKey,
  storeSettingsFeatureState
} from './store-settings.reducer';

export const storeSettingsState =
  createFeatureSelector<storeSettingsFeatureState>(storeSettingsFeatureKey);

export const selectStoreSettingsLoadingStatus = createSelector(
  storeSettingsState,
  ({ status }) => status
);

export const selectStoreAppId = createSelector(
  storeSettingsState,
  ({ appId }) => appId
);

export const selectStoreSettingsData = createSelector(
  storeSettingsState,
  ({ data }) => data
);

export const selectStoreSettingsDataWithAppId = createSelector(
  storeSettingsState,
  ({ appId, data }) => ({ appId, data })
);

export const selectStoreCountries = createSelector(
  storeSettingsState,
  ({ data }) => data?.countries
);

export const selectStoreRegions = createSelector(
  storeSettingsState,
  ({ data }) => data?.regions
);

export const selectStoreCurrencies = createSelector(
  storeSettingsState,
  ({ data }) => data?.currencies
);

export const selectStoreLanguages = createSelector(
  storeSettingsState,
  ({ data }) => data?.languages
);

export const selectStoreSectors = createSelector(
  storeSettingsState,
  ({ data }) => data?.sectors
);

export const selectStoreShippingInfo = createSelector(
  storeSettingsState,
  ({ data }) => data?.shipping
);

export const selectStoreInfo = createSelector(
  storeSettingsState,
  ({ data }) => data?.info
);

export const selectStoreSliders = createSelector(
  storeSettingsState,
  ({ data }) => data?.sliders
);

export const selectStoreSlidersWithAppId = createSelector(
  storeSettingsState,
  ({ appId, data }) => {
    return {
      appId,
      sliders: data?.sliders
    };
  }
);


export const selectSliderId = createSelector(
  storeSettingsState,
  ({ data }) => data?.sliders.id
);
