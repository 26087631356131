import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';

import { END_POINTS_URL } from '@shopiroller/data';
import { Injectable } from '@angular/core';
import { REST_REQUEST_RESULT } from '@shopiroller/data';
import { map } from 'rxjs/internal/operators/map';
import {
  AcceptOffersModel,
  GetOfferModel
} from '../../data/models/offers.model';

@Injectable({
  providedIn: 'root'
})
export class ShippingAndDeliveryService {
  constructor(private http: HttpClient) {}

  public getShippingAndDeliveryInfo(appId: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/settings/shipping`;
    return this.http.get(URL).pipe(map((result: any) => result.data));
  }

  public updateShippingAndDelivery(
    appId: string,
    data: any
  ): Observable<REST_REQUEST_RESULT> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/settings/shipping`;
    return this.http.put(URL, data, { observe: 'response' }).pipe(
      map((result) => {
        if (
          result.status === 200 ||
          result.status === 201 ||
          result.status === 204 ||
          result.status === 202
        ) {
          return REST_REQUEST_RESULT.SUCCESSFUL;
        } else {
          return REST_REQUEST_RESULT.FAILED;
        }
      })
    );
  }

  public updateShippingAndDeliveryPartially(
    appId: string,
    data: any
  ): Observable<REST_REQUEST_RESULT> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/settings/shipping`;
    return this.http.patch(URL, data, { observe: 'response' }).pipe(
      map((result) => {
        if (
          result.status === 200 ||
          result.status === 201 ||
          result.status === 204 ||
          result.status === 202
        ) {
          return REST_REQUEST_RESULT.SUCCESSFUL;
        } else {
          return REST_REQUEST_RESULT.FAILED;
        }
      })
    );
  }

  public getOffers(appId: string, payload: GetOfferModel): Observable<any> {
    const URL = `${END_POINTS_URL.V2.SHIPPING_URL}/apps/${appId}`;
    return this.http
      .post(URL, payload)
      .pipe(map((response: any) => response.data));
  }

  public acceptOffers(
    appId: string,
    payload: AcceptOffersModel
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.SHIPPING_URL}/apps/${appId}/accept`;
    return this.http.post(URL, payload);
  }
}
