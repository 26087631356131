/* eslint-disable no-unused-vars */

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { timer } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

import { UploadImageModel, UploadedImage } from './upload-image.model';
import { faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons';

 import { DomSanitizer } from '@angular/platform-browser';
import { FileUpload } from 'primeng/fileupload';
import { ImageUploaderService } from './image-uploader.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { retry } from 'rxjs/internal/operators/retry';
import { VALID_FILE_TYPES } from '@shopiroller/data';
import { AppSettingService } from '@shopiroller/core';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit {
  public appId = '';
  public isLoading = false;
  public faTrash = faTrash;
  public faTimes = faTimesCircle;
  public imageWidth = 'auto';
  public imageHeight = '130';
  public isAutoUpload = true;
  public maximumNumberOfFiles = 8;
  /**
   * used for holding single uploaded image
   */
  public uploadedImage: UploadImageModel | null = null;

  /**
   * used for holding multiple uploaded images
   */
  public uploadedImages: UploadedImage[] = [];

  /**
   * used for holding multiple all files
   */
  public uploadedFiles: any[] = [];
  public currentFiles: any[] = [];

  public selectedFeaturedImage: UploadedImage | null = null;

  /**
   * used for single mode when you want to show previously uploaded image
   */
  @Input() previouslyFeaturedImageURL = '';

  /**
   * used for single mode when you want to show previously uploaded image
   */
  @Input() previouslyUploadedImageURL = '';

  /**
   * used for multiple mode when you want to show previously uploaded images
   */
  @Input() previouslyUploadedImagesURL: string[] = [];

  /**
   * determines wheatear this component supports single mode or multiple modes
   */
  @Input() isMultipleImageUploader = false;

  /**
   * emit all uploaded images in multiple mode to  it's listener
   */
  @Output() multipleImageUploaded = new EventEmitter<string[]>();

  /**
   * emit uploaded image in single mode to  it's listener
   */
  @Output() imageUploaded = new EventEmitter<UploadImageModel | null>();

  @Output() featuredImageSelected = new EventEmitter<string>();

  /**
   * emit the deleted image Url to it's listener
   */
  @Output() imageDeleted = new EventEmitter<string>();

  @Output() previouslyUploadedImagesUpdated = new EventEmitter<string[]>();

  @ViewChild('imageUploader') imageUploader: FileUpload;

  public showUploadButton = true;
  public showCancelButton = true;
  public validImageTypes = VALID_FILE_TYPES.IMAGE;
  private featuredImageTooltip = '';
  private deletedImage;
  private _confirmationRequiredForDelete = false;

  constructor(
    private translateService: TranslateService,
    private appSettingService: AppSettingService,
    private messageService: MessageService,
    private imageUploaderService: ImageUploaderService,
    private confirmationService: ConfirmationService,
    private changeDetector: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) {
    this.appId = this.appSettingService.getCurrentAppId();
  }

  ngOnInit(): void {
    this.featuredImageTooltip = this.translateService.instant(
      'SHARED.FILE_UPLOADER.FEATURE_IMAGE_TOOLTIP'
    );
  }

  public uploadImage(event): void {
    const files: FileList = event.files;
    if (
      this.isMultipleImageUploader &&
      files.length + this.previouslyUploadedImagesURL.length >
        this.maximumNumberOfFiles
    ) {
      timer(200).subscribe((_) => {
        const uploadedRows = Array.from(
          document.querySelectorAll('.p-fileupload-files .p-fileupload-row')
        );
        const illegalRows =
          this.uploadedFiles.length == 0
            ? uploadedRows
            : uploadedRows.slice(this.uploadedFiles.length);
        if (
          illegalRows == uploadedRows &&
          this.previouslyUploadedImagesURL.length == 0
        ) {
          this.handleClearEvent(null);
        } else {
          illegalRows.forEach((element: any) => {
            element.remove();
            this.currentFiles = this.currentFiles.filter(
              (x) => !element.innerText.startsWith(x.name)
            );
            this.imageUploader._files = this.imageUploader._files.filter(
              (x) => !element.innerText.startsWith(x.name)
            );
          });
        }
      });
      const errMsg = this.translateService.instant(
        'SHARED.FILE_UPLOADER.MAXIMUM_COUNT_EXCEEDED',
        {
          limit: this.maximumNumberOfFiles
        }
      );
      this.showErrorMessage(errMsg);
      return;
    }
    this.uploadedImages = [];
    this.uploadedFiles = [];
    const uploadImageServiceArray$: Observable<any>[] = [];

    for (const file of Array.from(files)) {
      const ImageData: FormData = new FormData();
      ImageData.append('file', file);
      const containerName = environment.storageContainerName;
      const uploadImage$ = this.imageUploaderService
        .uploadedImage(this.appId, containerName, ImageData)
        .pipe(retry(2));
      this.uploadedFiles.push(file);
      uploadImageServiceArray$.push(uploadImage$);
    }

    this.isLoading = true;
    forkJoin(uploadImageServiceArray$).subscribe(
      (result: any) => {
        for (let i = 0; i < result.length; i++) {
          if (result[i].absoluteUri !== '') {
            let url: string = result[i].absolutePath;
            if (this.isMultipleImageUploader) {
              const uploadedImage = new UploadedImage(
                url,
                this.getImageId(files[i])
              );
              this.uploadedImages.push(uploadedImage);
            } else {
              this.uploadedImage = new UploadImageModel(
                url,
                this.getImageId(files[i])
              );
            }
          }
        }

        const uploadedRows = Array.from(
          document.querySelectorAll('.p-fileupload-files .p-fileupload-row')
        );
        if (
          uploadedRows.length > 0 &&
          this.selectedFeaturedImage == null &&
          this.previouslyFeaturedImageURL == ''
        ) {
          (uploadedRows[0] as HTMLElement).click();
        }

        this.isLoading = false;
        this.showUploadButton = false;
        this.showCancelButton = false;
        const currentUploadedImagesURL = this.uploadedImages.map((x) => x.url);
        if (this.previouslyUploadedImagesURL.length > 0) {
          currentUploadedImagesURL.push(...this.previouslyUploadedImagesURL);
        }
        this.isMultipleImageUploader
          ? this.multipleImageUploaded.emit(currentUploadedImagesURL)
          : this.imageUploaded.emit(this.uploadedImage);
        this.changeDetector.detectChanges();
      },
      (err: any) => {
        this.isLoading = false;
        let errMsg = this.translateService.instant(
          'SHARED.FILE_UPLOADER.IMAGE_NOT_UPLOADED'
        );
        if (err?.error?.errors?.file.length) {
          errMsg = err?.error.errors.file[0];
        }
        this.messageService.add({
          severity: 'error',
          detail: errMsg
        });
      }
    );
  }

  public handleSelectEvent(event): void {
    this.currentFiles = event.currentFiles;
    this.showCancelButton = true;
    this.showUploadButton = true;
  }

  public sanitize(url: string): any {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  /**
   * With this listener we are going to change featured image
   */
  @HostListener('click', ['$event'])
  public onClick(event): void {
    if (this._confirmationRequiredForDelete) {
      return;
    }

    const chooseImageButton = event.target.classList.contains(
      'p-fileupload-choose'
    );

    if (chooseImageButton) {
      return;
    }

    const isRemoveButton = Array.from(event.target.classList).some(
      (x) => x == 'pi-times'
    );

    if (isRemoveButton) {
      this.handleRemoveEvent(event);
    }

    if (this.isMultipleImageUploader) {
      const uploadRows = Array.from(
        document.querySelectorAll('.p-fileupload-row')
      );

      let fileUploadRow = Array.from(event.target.classList).some(
        (x) => x == 'p-fileupload-row'
      )
        ? event.target
        : this.findAncestor(event.target, 'p-fileupload-row');

      if (
        isRemoveButton &&
        Array.from(fileUploadRow.classList).includes('shadow') == false
      ) {
        return;
      }

      if (
        isRemoveButton &&
        Array.from(fileUploadRow.classList).includes('shadow') == true
      ) {
        fileUploadRow =
          fileUploadRow.id != uploadRows[0]?.id ? uploadRows[0] : uploadRows[1];
      }

      if (fileUploadRow != null) {
        const currentImageLength =
          this.uploadedImages.length + this.previouslyUploadedImagesURL.length;
        if (currentImageLength !== uploadRows.length && !this.isAutoUpload) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('SHARED.MESSAGES.ERROR'),
            detail: this.translateService.instant(
              'SHARED.FILE_UPLOADER.FEATURE_IMAGE_ERROR'
            )
          });
          return;
        }

        if (uploadRows.length > 0) {
          uploadRows.forEach((row) => {
            row.classList.remove('shadow');
            row.setAttribute('title', '');
          });
        }

        fileUploadRow.classList.add('shadow');
        fileUploadRow.setAttribute('title', this.featuredImageTooltip);
        const fileUploadRowInnerText = fileUploadRow.textContent
          .replace('\n', '_')
          .replace(' KB', '');
        const targetElementURL = event?.target.id;
        const currentSrc = event?.target.currentSrc;
        if (
          targetElementURL?.startsWith('http') ||
          currentSrc?.startsWith('http')
        ) {
          const url = targetElementURL + currentSrc;
          this.selectedFeaturedImage = new UploadedImage(url, '');
          this.featuredImageSelected.emit(url);
        } else {
          for (const image of this.imageUploader.files) {
            const txt = image.name + (image.size / 1024).toPrecision(4);
            if (txt === fileUploadRowInnerText.trim()) {
              const imageId = this.getImageId(image);
              this.selectedFeaturedImage =
                this.uploadedImages.find((x) => x.id === imageId) ?? null;
              this.featuredImageSelected.emit(this.selectedFeaturedImage?.url);
            }
          }
        }
      }
    }
  }

  private findAncestor(el, cls): any {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
  }

  /**
   * this method used for identifying uploaded images
   */
  public getImageId(file: any): string {
    return file.name + file.size + file.lastModified;
  }

  public handleRemoveEvent(event): void {
    if (this._confirmationRequiredForDelete) {
      return;
    }
    if (!this.isMultipleImageUploader) {
      this.uploadedImage = null;
      this.previouslyUploadedImageURL = '';
      this.imageDeleted.emit();
    } else {
      const elementId = event?.srcElement.id;
      const imageId =
        elementId != null || elementId != ''
          ? elementId
          : this.getImageId(event.file);

      if (this.deletedImage != null) {
        if (this.selectedFeaturedImage?.id === imageId) {
          this.featuredImageSelected.emit('');
        }
        this.uploadedImages = this.uploadedImages.filter(
          (x) => x.id !== imageId
        );
        const currentUploadedImagesURL = this.uploadedImages.map((x) => x.url);
        this.multipleImageUploaded.emit(currentUploadedImagesURL);
      }
    }
  }

  public removeImage(event, file): void {
    if (this._confirmationRequiredForDelete) {
      return;
    }
    const imageId = event.target.id;
    const imageExist =
      this.uploadedImages.filter((x) => x.id == imageId).length > 0;
    if (
      (this.isMultipleImageUploader && !imageExist) ||
      (!this.isMultipleImageUploader && this.uploadedImage == null)
    ) {
      return;
    }
    if (this.isMultipleImageUploader) {
      let uploadedRows = Array.from(
        document.querySelectorAll('.p-fileupload-files .p-fileupload-row')
      );
      const previousImages = Array.from(
        document.querySelectorAll(
          '.previous-images-container .p-fileupload-row'
        )
      );
      if (previousImages.length > 0) {
        uploadedRows.push(...previousImages);
      }

      this.selectedFeaturedImage = null;
      if (
        imageId == this.uploadedImages[0].id &&
        uploadedRows.length > 1 &&
        this.previouslyUploadedImagesURL.length == 0
      ) {
        (uploadedRows[1] as HTMLElement).click();
      }
      if (
        (imageId != this.uploadedImages[0].id &&
          uploadedRows.length > 1 &&
          this.previouslyFeaturedImageURL.length == 0) ||
        uploadedRows.length == 0
      ) {
        (uploadedRows[0] as HTMLElement).click();
      }
      this.deletedImage = this.uploadedImages.find((x) => x.id == imageId);
      this.uploadedImages = this.uploadedImages.filter((x) => x.id !== imageId);
      const uploadImageRow =
        document.getElementById(imageId)?.parentNode?.parentNode;
      if (uploadedRows.length - 1 == 0) {
        this.handleClearEvent(null);
      }
      uploadImageRow?.parentNode?.removeChild(uploadImageRow);
      this.imageUploader._files = this.imageUploader._files.filter(
        (x) => !x.name.startsWith(file?.name)
      );
    } else {
      this.uploadedImage = null;
      this.currentFiles = [];
      this.previouslyUploadedImageURL = '';
      this.imageDeleted.emit();
      const uploadImageRow =
        document.getElementById(imageId)?.parentNode?.parentNode;
      if (uploadImageRow?.parentNode?.children.length == 1) {
        this.handleClearEvent(null);
      }
      uploadImageRow?.parentNode?.removeChild(uploadImageRow);
    }
    this.changeDetector.detectChanges();
  }

  public handleClearEvent(event): void {
    this.uploadedImages = [];
    this.uploadedFiles = [];
    if (this.isMultipleImageUploader) {
      this.previouslyUploadedImagesURL = [];
      this.multipleImageUploaded.emit([]);
    } else {
      this.uploadedImage = null;
      this.currentFiles = [];
      this.previouslyUploadedImageURL = '';
      this.imageDeleted.emit();
    }
    this.imageUploader.clear();
  }

  public deleteUploadedImage(imageUrl?: string): void {
    this._confirmationRequiredForDelete = true;
    this.confirmationService.confirm({
      header: this.translateService.instant(
        'SHARED.FILE_UPLOADER.DELETE_TITLE'
      ),
      message: this.translateService.instant('SHARED.CONFIRM_DIALOG_TEXT'),
      accept: () => {
        if (!this.isMultipleImageUploader) {
          this.uploadedImage = null;
          this.previouslyUploadedImageURL = '';
          this.imageDeleted.emit();
        } else {
          this.previouslyUploadedImagesURL = this.previouslyUploadedImagesURL =
            this.previouslyUploadedImagesURL.filter((x) => x !== imageUrl);
          this.previouslyUploadedImagesUpdated.emit(
            this.previouslyUploadedImagesURL
          );
          const currentUploadedImages = this.uploadedImages.map((x) => x.url);
          currentUploadedImages.push(...this.previouslyUploadedImagesURL);
          this.multipleImageUploaded.emit(currentUploadedImages);
          if (currentUploadedImages.length == 0) {
            this.handleClearEvent(null);
          } else if (
            (imageUrl && this.selectedFeaturedImage?.url == imageUrl) ||
            this.previouslyFeaturedImageURL == imageUrl
          ) {
            this._pickFeatureImage(imageUrl);
          }
        }
        this._confirmationRequiredForDelete = false;
      },
      reject: () => {
        this._confirmationRequiredForDelete = false;
      }
    });
  }

  private showErrorMessage(msg = 'SHARED.MESSAGES.REQUEST_FAILED'): void {
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant('SHARED.MESSAGES.ERROR'),
      detail: this.translateService.instant(msg)
    });
  }

  private _pickFeatureImage(previousFeaturedImageURL: string): void {
    if (this.isMultipleImageUploader) {
      let uploadedRows = Array.from(
        document.querySelectorAll('.p-fileupload-row')
      );
      const currentFeatureImageRow = uploadedRows.find(
        (x) => x.id == previousFeaturedImageURL
      );
      currentFeatureImageRow?.remove();

      uploadedRows = uploadedRows.filter(
        (x) => x.id != previousFeaturedImageURL
      );

      if (uploadedRows.length > 0) {
        uploadedRows.forEach((row) => {
          row.classList.remove('shadow');
          row.setAttribute('title', '');
        });

        const newFeaturedRow = uploadedRows[0] as any;
        newFeaturedRow.classList.add('shadow');
        newFeaturedRow.setAttribute('title', this.featuredImageTooltip);

        const targetElementURL = newFeaturedRow.id;
        const currentSrc = newFeaturedRow?.currentSrc;
        if (
          targetElementURL?.startsWith('http') ||
          currentSrc?.startsWith('http')
        ) {
          const url = (targetElementURL + currentSrc).replace('undefined', '');
          this.selectedFeaturedImage = new UploadedImage(url, '');
          this.featuredImageSelected.emit(url);
        } else {
          for (const image of this.imageUploader.files) {
            const txt = image.name + (image.size / 1024).toPrecision(4);
            const fileUploadRowInnerText = newFeaturedRow.textContent
              .replace('\n', '_')
              .replace(' KB', '');
            if (txt === fileUploadRowInnerText.trim()) {
              const imageId = this.getImageId(image);
              this.selectedFeaturedImage =
                this.uploadedImages.find((x) => x.id === imageId) ?? null;
              this.featuredImageSelected.emit(this.selectedFeaturedImage?.url);
            }
          }
        }
      }
    }
  }
}
