import { Pipe, PipeTransform } from '@angular/core';

import { WebChannelDomainEnum } from '../../data/models/domain-types';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'domain',
  standalone: true
})
export class DomainPipe implements PipeTransform {
  transform(webSettingInfo: any): string {
    if (webSettingInfo == null) return '';

    if (webSettingInfo.hasOwnProperty('customDomain'))
      return webSettingInfo.customDomain;

    if (webSettingInfo.hasOwnProperty('subdomain'))
      return (
        'https://' + webSettingInfo.subdomain + `.${environment.panelBaseURL}`
      );

    return '';
  }
}
