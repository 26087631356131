import {
  ConfirmUserPhoneNumberModel,
  UserRegistrationResponseModel,
  VerifyPhoneNumberViaEmailModel
} from '../../models';

import { ConfirmEmailModel } from '../../models/confirm-email.model';
import { END_POINTS_URL } from '@shopiroller/data';
import { ExternalLoginModel } from '../../models/external-login.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginModel } from '../../models/login.model';
import { Observable } from 'rxjs/internal/Observable';
import { ResetPasswordModel } from '../../models/reset-password.model';
import { UserModel } from '../../models/user.model';
import { UserRegistrationModel } from '../../models/user-registration.model';
import { map } from 'rxjs/internal/operators/map';

const AUTH_API_URL = `${END_POINTS_URL.V2_1.AUTH_URL}`;

@Injectable({
  providedIn: 'root'
})
export class AuthHTTPService {
  constructor(private http: HttpClient) {}

  public login(data: LoginModel): Observable<UserModel> {
    const url = `${AUTH_API_URL}/login`;
    return this.http
      .post<LoginModel>(url, data)
      .pipe(map((response: any) => response.data as UserModel));
  }

  public externalLogin(data: ExternalLoginModel): Observable<any> {
    const url = `${AUTH_API_URL}/externalLogin`;
    return this.http.post<ExternalLoginModel>(url, data);
  }

  public register(
    callBackURL: string,
    user: UserRegistrationModel
  ): Observable<UserRegistrationResponseModel> {
    const url = `${AUTH_API_URL}/register?callbackUrl=${callBackURL}`;
    return this.http
      .post<UserRegistrationModel>(url, user)
      .pipe(map((response: any) => response as UserRegistrationResponseModel));
  }

  public refreshToken(
    refreshToken: string,
    oldAccessToken: string
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2_1.AUTH_URL}/token/refresh?refreshToken=${refreshToken}&oldToken=${oldAccessToken}`;
    return this.http.get<any>(URL);
  }

  public forgotPassword(email: string): Observable<any> {
    const callBackUrl = `${window.location.origin}/auth/change-password`;
    const url = `${AUTH_API_URL}/forgotPassword?Email=${email}&CallbackUrl=${callBackUrl}`;
    return this.http.get(url);
  }

  public logOut(): Observable<any> {
    const url = `${AUTH_API_URL}/logout`;
    return this.http.post(url, {});
  }

  public resetPassword(data: ResetPasswordModel): Observable<any> {
    const url = `${AUTH_API_URL}/resetPassword`;
    return this.http.post<ResetPasswordModel>(url, data);
  }

  public confirmEmail(data: ConfirmEmailModel): Observable<any> {
    const url = `${AUTH_API_URL}/activation/email/${data.email}`;
    return this.http.post<any>(url, {
      token: data.token
    });
  }

  public resendConfirmEmail(email: string): Observable<any> {
    const url = `${AUTH_API_URL}/activation/email/${email}/resend`;
    return this.http.post<any>(url, {});
  }

  public confirmUserPhoneNumber(
    payload: ConfirmUserPhoneNumberModel
  ): Observable<null> {
    const URL = `${AUTH_API_URL}/users/${payload.email}/${payload.phone}/${payload.code}`;
    return this.http.patch<any>(URL, {});
  }

  public verifyPhoneNumberViaEmail(payload: VerifyPhoneNumberViaEmailModel): Observable<void> {
    const URL = `${AUTH_API_URL}/users/${payload.email}/${payload.code}`;
    return this.http.patch<any>(URL, {});
  }
}
