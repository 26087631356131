import { SubscriptionTypes } from '../types';

export interface ISubscriptionProduct {
  id: string;
  object: string;
  active: boolean;
  attributes: [];
  created: number;
  default_price: string;
  description: string;
  images: string[];
  livemode: boolean;
  metadata: any;
  name: SubscriptionTypes;
  tax_code: any;
  type: string;
  updated: number;
}
