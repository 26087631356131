import * as SubscriptionActions from './subscription.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { of } from 'rxjs/internal/observable/of';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { catchError } from 'rxjs/internal/operators/catchError';
import { withLatestFrom } from 'rxjs/internal/operators/withLatestFrom';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { map } from 'rxjs/internal/operators/map';
import { delay } from 'rxjs/internal/operators/delay';
import {
  selectCurrentSubscriptionInfo,
  selectCurrentSubscriptionInformationOfApp,
  selectCurrentSubscriptionPackagesInfo,
  selectSubscriptionPackagesList
} from './subscription.selectors';

import { AppSettingService } from '@shopiroller/core';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SubscriptionService } from '../services';
import {
  businessPackageData,
  businessPackagePermissionListInfo
} from '../../data/business-package.data';
import { loadCurrentSubscriptionInformationOfAppFailure } from './subscription.actions';
import { selectCurrentAppIsAppSumoApp } from '@shopiroller/stores';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionFeatureEffects {
  public loadSubscriptionPackage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SubscriptionActions.loadSubscriptionPackagesList),
      withLatestFrom(this._store.select(selectSubscriptionPackagesList)),
      switchMap((actions) => {
        const previousData = actions[1];
        if (previousData != null) {
          return of(
            SubscriptionActions.loadSubscriptionPackagesListSuccess({
              response: previousData
            })
          );
        }
        return this._subscriptionService.getSubscriptionPackages().pipe(
          map(
            (response) =>
              SubscriptionActions.loadSubscriptionPackagesListSuccess({
                response: response
              }),
            catchError(() => EMPTY)
          )
        );
      })
    )
  );

  public loadSubscriptionPackageType$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SubscriptionActions.loadCurrentSubscriptionPackageType),
      withLatestFrom(this._store.select(selectCurrentSubscriptionPackagesInfo)),
      switchMap((actions) => {
        const previousData = actions[1];
        if (previousData != null) {
          return of(
            SubscriptionActions.loadCurrentSubscriptionPackageTypeSuccess({
              response: previousData
            })
          );
        }
        const appId = this._appSettingService.getCurrentAppId();
        return this._subscriptionService.getPackageType(appId).pipe(
          map(
            (response) =>
              SubscriptionActions.loadCurrentSubscriptionPackageTypeSuccess({
                response: response
              }),
            catchError(() => EMPTY)
          )
        );
      })
    )
  );

  public loadSubscriptionInfo$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SubscriptionActions.loadSubscriptionInfo),
      withLatestFrom(this._store.select(selectCurrentSubscriptionInfo)),
      switchMap((actions) => {
        const previousPackageType = actions[1].currentPackage;
        const previousPackagesInfo = actions[1].packagesInfo;
        const previousPermissionList = actions[1].currentPackagePermissionList;

        if (previousPackageType && previousPackagesInfo) {
          return of(
            SubscriptionActions.loadSubscriptionInfoSuccess({
              response: {
                currentPackage: previousPackageType,
                packagesInfo: previousPackagesInfo ?? null,
                currentPackagePermissionList: previousPermissionList
              }
            })
          );
        }
        const subscriptionPackageList$ =
          this._subscriptionService.getSubscriptionPackages();

        const requests$: Observable<any>[] = [];
        requests$.push(subscriptionPackageList$);

        const appId = this._appSettingService.getCurrentAppId();
        if (appId) {
          const currentPackageType$ =
            this._subscriptionService.getPackageType(appId);
          requests$.push(currentPackageType$);
          const currentPackagePermissionList$ =
            this._subscriptionService.getPermissionList(appId);
          requests$.push(currentPackagePermissionList$);
        }

        return forkJoin(requests$).pipe(
          map(
            (responses: any) => {
              const subscriptionPackageList = responses[0];
              if (appId) {
                const currentPackageType = responses[1];
                const currentPackagePermissionList = responses[2];
                return SubscriptionActions.loadSubscriptionInfoSuccess({
                  response: {
                    currentPackage: currentPackageType,
                    packagesInfo: subscriptionPackageList,
                    currentPackagePermissionList: currentPackagePermissionList
                  }
                });
              } else {
                return SubscriptionActions.loadSubscriptionInfoSuccess({
                  response: {
                    currentPackage: {
                      duration: 'month',
                      type: 'Starter'
                    },
                    packagesInfo: subscriptionPackageList,
                    currentPackagePermissionList: null
                  }
                });
              }
            },
            catchError(() => EMPTY)
          )
        );
      })
    )
  );

  public loadCurrentSubscriptionInformationOfApp$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SubscriptionActions.loadCurrentSubscriptionInformationOfApp),
      delay(500),
      withLatestFrom(
        this._store.select(selectCurrentSubscriptionInformationOfApp)
      ),
      withLatestFrom(this._store.select(selectCurrentAppIsAppSumoApp)),
      switchMap((actions) => {
        const currentUserIsAppSumoMember = actions[1];
        if (currentUserIsAppSumoMember) {
          const currentPackagePermissionList =
            businessPackagePermissionListInfo;
          const currentPackageData = businessPackageData;
          return of(
            SubscriptionActions.loadCurrentSubscriptionInformationOfAppSuccess({
              response: {
                currentPackage: currentPackageData,
                currentPackagePermissionList: currentPackagePermissionList
              }
            })
          );
        } else {
          const appId = this._appSettingService.getCurrentAppId();
          const currentPackageType$ =
            this._subscriptionService.getPackageType(appId);
          const currentPackagePermissionList$ =
            this._subscriptionService.getPermissionList(appId);
          return forkJoin([
            currentPackageType$,
            currentPackagePermissionList$
          ]).pipe(
            map((responses: any) => {
              const currentPackageType = responses[0];
              const currentPackagePermissionList = responses[1];
              return SubscriptionActions.loadCurrentSubscriptionInformationOfAppSuccess(
                {
                  response: {
                    currentPackage: currentPackageType,
                    currentPackagePermissionList: currentPackagePermissionList
                  }
                }
              );
            }),
            catchError((error) => {
              return of(
                loadCurrentSubscriptionInformationOfAppFailure({
                  error: error
                })
              );
            })
          );
        }
      })
    )
  );

  constructor(
    private readonly _store: Store,
    private readonly _actions$: Actions,
    private _appSettingService: AppSettingService,
    private readonly _subscriptionService: SubscriptionService
  ) {}
}
