import { GenericState, GenericStoreStatus } from '@shopiroller/core';
import { createReducer, on } from '@ngrx/store';
import { loginUserPanel, loginUserPanelFailure, loginUserPanelSuccess } from './auth.actions';

import { UserModel } from '../../models/user.model';

export const AuthFeatureKey = 'feature-auth';

export class AuthFeatureState implements GenericState<any> {
    currentUser!: UserModel | null;
    status: GenericStoreStatus = 'pending';
    error: any = null;
}

const initialState: AuthFeatureState = {
    currentUser: null,
    error: null,
    status: 'pending',
};

export const authFeatureReducer = createReducer(
    initialState,
    on(loginUserPanel, (state) => ({ ...state, status: 'loading' })),
    on(loginUserPanelSuccess, (state, { user }) => ({
        ...state,
        currentUser: user,
        status: 'success',
        error: null,
    })),
    on(loginUserPanelFailure, (state, { error }) => ({
        ...state,
        currentUser: null,
        status: 'error',
        error: error,
    }))
);
