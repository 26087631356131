import { map } from 'rxjs/internal/operators/map';
import { END_POINTS_URL } from '@shopiroller/data';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor(private http: HttpClient) {}

  public getSectorsList(): Observable<any> {
    const SECTORS_LIST_URL = END_POINTS_URL.V2.SECTORS_LIST_URL;
    return this.http.get(SECTORS_LIST_URL).pipe(
      map((result) => {
        return result;
      })
    );
  }

  public getCountriesList(): Observable<any> {
    const COUNTRIES_URL = `${END_POINTS_URL.V1.ADDRESSES_URL}/countries`;
    return this.http.get(COUNTRIES_URL).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  public getCurrenciesList(): Observable<any> {
    const CURRENCIES_LIST_URL = END_POINTS_URL.V2.CURRENCIES_LIST_URL;
    return this.http.get(CURRENCIES_LIST_URL).pipe(
      map((result: any) => {
        return result.data;
      })
    );
  }
}
