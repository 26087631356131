import { IPublicSetting, PublicSetting } from '../models';
import { AppDefaultSetting } from '.';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LOCAL_STORAGE_KEYS } from '@shopiroller/data';

@Injectable({
  providedIn: 'root'
})
export class AppSettingService {
  //Todo: Setting object  should replace  with better schema
  private defaultSetting = new PublicSetting(
    AppDefaultSetting.currentAppId,
    AppDefaultSetting.showAsideMenu,
    AppDefaultSetting.defaultLanguageOfPanel,
    AppDefaultSetting.currentAppUpgradedToAppSumo
  );

  constructor(private router: Router) {}

  initializeSettings(): void {
    this.getSettingsValues();
  }

  getSettingsValues(): PublicSetting {
    try {
      const config = localStorage.getItem(LOCAL_STORAGE_KEYS.APP_CONFIG);
      if (config != undefined || config != null) {
        return JSON.parse(config) as PublicSetting;
      }
      return this.defaultSetting;
    } catch {
      return this.defaultSetting;
    }
  }

  setSettingsValues(config: IPublicSetting): void {
    if (!config) {
      this._resetSettingsValues();
    }
    localStorage.setItem(LOCAL_STORAGE_KEYS.APP_CONFIG, JSON.stringify(config));
  }

  /**
   * @param {string} fieldName use SettingFields options for this argument
   */
  setSettingsPartially(fieldName: string, fieldValue: any): void {
    try {
      const config: any = this.getSettingsValues();
      config[fieldName] = fieldValue;
      this.setSettingsValues(config);
    } catch (error) {
      console.log(error);
    }
  }

  getCurrentAppId(): any {
    const currentAppId = this.getSettingsValues().currentAppId;
    if (currentAppId?.trim() === '') {
      this.router.navigate(['/stores/list']);
    } else {
      return currentAppId;
    }
  }

  getPanelLanguage(): string {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.USER_LANGUAGE) || 'en';
  }

  private _resetSettingsValues(): void {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.APP_CONFIG,
      JSON.stringify(this.defaultSetting)
    );
  }
}
