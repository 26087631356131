import { AppsFeatureEffects } from '@shopiroller/stores';
import { DashboardFeatureEffects } from '@shopiroller/store/dashboard';
import { OrderFeatureEffects } from '@shopiroller/store/orders';
import { SalesChannelFeatureEffects } from '@shopiroller/store/sales-channel';
import { SubscriptionFeatureEffects } from '@shopiroller/store/subscription';
import { UserProfileFeatureEffects } from '@shopiroller/user-profile';
import { WebHookFeatureEffects } from '@shopiroller/store/webhook';
import { StoreActionsResultMessageEffect } from './store-actions-result-message.effects';
import { AppSumoFeatureEffects } from '@shopiroller/appsumo';
import { StoreSettingsFeatureEffects } from 'src/app/modules/store-settings';
import { AppStoreFeatureEffects } from '@shopiroller/store/apps';

export const APP_EFFECTS = [
  UserProfileFeatureEffects,
  AppsFeatureEffects,
  AppSumoFeatureEffects,
  SubscriptionFeatureEffects,
  DashboardFeatureEffects,
  SalesChannelFeatureEffects,
  StoreSettingsFeatureEffects,
  OrderFeatureEffects,
  // DiscountFeatureEffects,
  // ClientFeatureEffects,
  WebHookFeatureEffects,
  StoreActionsResultMessageEffect,
  AppStoreFeatureEffects
];
