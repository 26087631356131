import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { END_POINTS_URL } from '@shopiroller/data';
import { ContentSuggestionModel } from '../../data/models';

@Injectable({
  providedIn: 'root'
})
export class ContractService {
  private readonly _baseContractsURL = `${END_POINTS_URL.V2.CONTRACTS_URL}`;
  constructor(private _http: HttpClient) {}

  public getDeliveryConditions(): Observable<any> {
    const URL = `${this._baseContractsURL}/getDeliveryConditions`;
    return this._http.get(URL).pipe(map((result: any) => result.data));
  }

  public getDeliveryConditionsForSpecificLanguage(
    payload: ContentSuggestionModel
  ): Observable<any> {
    const URL = `${this._baseContractsURL}/apps/${payload.appId}/deliveryConditions/languages/${payload.language}`;
    return this._http.get(URL).pipe(map((result: any) => result.data));
  }

  public getCancellationProcedure(): Observable<any> {
    const URL = `${this._baseContractsURL}/getCancellationProcedure`;
    return this._http.get(URL).pipe(map((result: any) => result.data));
  }

  publicgetCancellationProcedureForSpecificLanguage(
    payload: ContentSuggestionModel
  ): Observable<any> {
    const URL = `${this._baseContractsURL}/apps/${payload.appId}/cancellationProcedure/languages/${payload.language}`;
    return this._http.get(URL).pipe(map((result: any) => result.data));
  }

  public getDistanceSalesContract(): Observable<any> {
    const URL = `${this._baseContractsURL}/distanceSalesContract`;
    return this._http.get(URL).pipe(map((result: any) => result.data));
  }

  public getDistanceSalesContractForSpecificLanguage(
    payload: ContentSuggestionModel
  ): Observable<any> {
    const URL = `${this._baseContractsURL}/apps/${payload.appId}/distanceSalesContract/languages/${payload.language}`;
    return this._http.get(URL).pipe(map((result: any) => result.data));
  }
}
