import { APP_INITIALIZER } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppSettingService } from '@shopiroller/core';
import { LoadCurrentAppData } from '../data-access';

export const PROJECT_DATA_APP_INITIALIZER_PROVIDER = [
  {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [Store, AppSettingService],
    useFactory: (store: Store, appSettingService: AppSettingService) => {
      return () => {
        const appId = appSettingService.getCurrentAppId();
        return store.dispatch(
          LoadCurrentAppData({
            appId: appId
          })
        );
      };
    }
  }
];
