import { Routes } from '@angular/router';
import { AfterSubscriptionPaymentResolver } from '../utils';

export const SUBSCRIPTION_ROUTES: Routes = [
  {
    path: 'packages',
    loadComponent: async () =>
      (await import('../features/package-list/package-list.component'))
        .PackageListComponent
  },
  {
    path: 'upgrade/success',
    loadComponent: async () =>
      (await import('../ui/successful-upgrade/successful-upgrade.component'))
        .SuccessfulUpgradeComponent,
    resolve: {
      AfterSubscriptionPaymentResolver
    }
  },
  {
    path: 'upgrade/failed',
    loadComponent: async () =>
      (await import('../ui/failed-upgrade/failed-upgrade.component'))
        .FailedUpgradeComponent,
    resolve: {
      AfterSubscriptionPaymentResolver
    }
  },
  {
    path: '**',
    redirectTo: 'packages',
    pathMatch: 'full'
  }
];
