import {
  API_KEY_INTERCEPTOR_INITIALIZER_PROVIDER,
  APP_CONFIGURATION_APP_INITIALIZER_PROVIDER,
  APP_SETTING_APP_INITIALIZER_PROVIDER,
  HTTP_ERROR_INTERCEPTOR_INITIALIZER_PROVIDER,
  HIGHLIGHT_JS_APP_INITIALIZER_PROVIDER,
  SENTRY_APP_INITIALIZER_PROVIDER,
  SENTRY_ERROR_APP_INITIALIZER_PROVIDER,
  SENTRY_TRACE_SERVICE_APP_INITIALIZER_PROVIDER
} from '@shopiroller/core';

import { ACCESS_TOKEN_INTERCEPTOR_APP_INITIALIZER_PROVIDER } from './modules/auth/utils';
import { LOCALE_APP_INITIALIZER_PROVIDER } from '@shopiroller/i18n';
import { SUBSCRIPTION_APP_INITIALIZER_PROVIDER } from '@shopiroller/store/subscription';
import {
  PROJECT_DATA_APP_INITIALIZER_PROVIDER,
  TOP_5_PROJECT_LIST_APP_INITIALIZER_PROVIDER
} from '@shopiroller/stores';
import { MessageService } from 'primeng/api';

export const APP_PROVIDERS = [
  APP_SETTING_APP_INITIALIZER_PROVIDER,

  HIGHLIGHT_JS_APP_INITIALIZER_PROVIDER,

  API_KEY_INTERCEPTOR_INITIALIZER_PROVIDER,

  ACCESS_TOKEN_INTERCEPTOR_APP_INITIALIZER_PROVIDER,

  HTTP_ERROR_INTERCEPTOR_INITIALIZER_PROVIDER,

  LOCALE_APP_INITIALIZER_PROVIDER,

  APP_CONFIGURATION_APP_INITIALIZER_PROVIDER,

  SUBSCRIPTION_APP_INITIALIZER_PROVIDER,

  PROJECT_DATA_APP_INITIALIZER_PROVIDER,

  TOP_5_PROJECT_LIST_APP_INITIALIZER_PROVIDER,

  SENTRY_ERROR_APP_INITIALIZER_PROVIDER,

  SENTRY_TRACE_SERVICE_APP_INITIALIZER_PROVIDER,

  SENTRY_APP_INITIALIZER_PROVIDER,

  MessageService
];
