import { StoreActionResultModel } from '@shopiroller/core';
import {
  CreateHookModel,
  DeleteHookModel,
  GetAllHooksResponseModel,
  UpdateHookModel
} from '../../data/models';
import { createAction, props } from '@ngrx/store';

/******************* Load List Of Web Hook  ***********************/
export const loadListOfWebHooks = createAction(
  '[WebHook] Load List Of Web Hook',
  props<{ appId: string }>()
);

export const loadListOfWebHooksSuccess = createAction(
  '[WebHook] Load List Of Web Hook Success',
  props<{ response: GetAllHooksResponseModel }>()
);

export const loadListOfWebHooksFailure = createAction(
  '[WebHook] Load List Of Web Hook Failure',
  props<{ error: any }>()
);

/******************* Load List Of Web Hook Types  ***********************/
export const loadListOfWebHooksTypes = createAction(
  '[WebHook] Load List Of Web Hook Types'
);

export const loadListOfWebHooksTypesSuccess = createAction(
  '[WebHook] Load List Of Web Hook Types Success ',
  props<{ response: any }>()
);

export const loadListOfWebHooksTypesFailure = createAction(
  '[WebHook] Load List Of Web Hook Types Failure',
  props<{ error: any }>()
);

/******************* Create Web Hook  ***********************/
export const CreateWebHook = createAction(
  '[WebHook] Create Web Hook',
  props<{ payload: CreateHookModel }>()
);

export const CreateWebHookSuccess = createAction(
  '[WebHook] Create Web Hook Success',
  props<{
    payload: {
      newHook: any;
      result: StoreActionResultModel;
    };
  }>()
);

export const CreateWebHookFailure = createAction(
  '[WebHook] Create Web Hook Failure',
  props<{
    payload: {
      result: StoreActionResultModel;
    };
  }>()
);

/******************* Update Web Hook  ***********************/
export const UpdateWebHook = createAction(
  '[WebHook] Update Web Hook',
  props<{ payload: UpdateHookModel }>()
);

export const UpdateWebHookSuccess = createAction(
  '[WebHook] Update Web Hook Success',
  props<{
    payload: {
      updatedHook: any;
      result: StoreActionResultModel;
    };
  }>()
);

export const UpdateWebHookFailure = createAction(
  '[WebHook] Update Web Hook Failure',
  props<{
    payload: {
      result: StoreActionResultModel;
    };
  }>()
);

/******************* Delete Web Hook  ***********************/
export const DeleteWebHook = createAction(
  '[WebHook] Delete Web Hook',
  props<{ payload: DeleteHookModel }>()
);

export const DeleteWebHookSuccess = createAction(
  '[WebHook] Delete Web Hook Success',
  props<{
    payload: {
      deletedHookId: string;
      result: StoreActionResultModel;
    };
  }>()
);

export const DeleteWebHookFailure = createAction(
  '[WebHook] Delete Web Hook Failure',
  props<{
    payload: {
      result: StoreActionResultModel;
    };
  }>()
);

/******************* Check Webhook Activeness ***********************/
export const checkWebhookActiveness = createAction(
  '[WebHook] Check Webhook Activeness',
  props<{
    appId: string;
  }>()
);

export const checkWebhookActivenessSuccess = createAction(
  '[WebHook] Check Webhook Activeness Success ',
  props<{
    response: {
      appId: string;
      isActive: boolean;
    };
  }>()
);

export const checkWebhookActivenessFailure = createAction(
  '[WebHook] Check Webhook Activeness Failure',
  props<{
    payload: {
      result: StoreActionResultModel;
    };
  }>()
);
