import * as appActions from './app.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  AppListResultModel,
  GetAppListModel,
  SelectedAppDataModel
} from '../../models';
import { ApplicationsService, StoreService } from '../services';
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { of } from 'rxjs/internal/observable/of';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { withLatestFrom } from 'rxjs/internal/operators/withLatestFrom';
import {
  selectCurrentAppWholeData,
  selectListOfApps,
  selectPrimitiveStoreData
} from './app.selectors';
import { Injectable } from '@angular/core';
import {
  SalesChannelService,
  WebSettingService
} from '@shopiroller/store/sales-channel';
import { Store } from '@ngrx/store';
import {
  StoreActionResultModel,
  StoreActionsMessageTypesList
} from '@shopiroller/core';
import { TranslateService } from '@ngx-translate/core';
import { loadCurrentSubscriptionInformationOfApp } from '@shopiroller/store/subscription';
import {
  LanguageService,
  SharedService,
  PaymentService
} from 'src/app/modules/store-settings';
import { PrimitveStoreDataModel } from '../../models/store.model';
import { checkWebhookActivenessSuccess }from '@shopiroller/store/webhook'

@Injectable({
  providedIn: 'root'
})
export class AppsFeatureEffects {
  public updateAppWebSetting$ = createEffect(() =>
    this._actions$.pipe(
      ofType(appActions.UpdateAppWebsetting),
      switchMap((action) => {
        const appId = action.appId;
        const payload = action.payload;
        const updateType = action.updateType;
        return this._webSettingService
          .updateWebSettingsPartially(appId, payload)
          .pipe(
            map((response) => {
              const updatedWebsetting = response.data;
              const actionResult: StoreActionResultModel = {
                message: '',
                messageType: StoreActionsMessageTypesList.Success
              };

              if (updateType == 'guestShopping') {
                actionResult.message = updatedWebsetting.guestShopping
                  ? 'APP_STORE.GUEST_SHOPPING_ACTIVE_MESSAGE'
                  : 'APP_STORE.GUEST_SHOPPING_DE_ACTIVE_MESSAGE';
              }

              if (updateType == 'webHook') {
                actionResult.message = updatedWebsetting.webHook
                  ? 'WEB_HOOK.WEB_HOOK_ACTIVE_MESSAGE'
                  : 'WEB_HOOK.WEB_HOOK_DE_ACTIVE_MESSAGE';
                this._store.dispatch(
                  checkWebhookActivenessSuccess({
                    response: {
                      appId: appId,
                      isActive: updatedWebsetting.webHook
                    }
                  })
                );
              }

              if (updateType == 'googleTagManager') {
                actionResult.message =
                  'APP_STORE.GOOGLE_TAG_MANAGER_SUCCESSFUL_UPDATE';
              }

              if (updateType == 'googleAnalytics') {
                actionResult.message =
                  'APP_STORE.GOOGLE_ANALYTICS_SUCCESSFUL_UPDATE';
              }

              if (updateType == 'domain') {
                actionResult.message =
                  'SALES_CHANNEL.DOMAIN_UPDATED_SUCCESSFULLY';
              }

              if (updateType == 'metaTages') {
                actionResult.message =
                  'SALES_CHANNEL.META_TAGS_UPDATED_SUCCESSFULLY';
              }

              return appActions.UpdateAppWebsettingSuccess({
                payload: {
                  webSetting: updatedWebsetting,
                  result: actionResult
                }
              });
            }),
            catchError((error) => {
              let errMsg = this._translateService.instant(
                'SHARED.MESSAGES.REQUEST_FAILED'
              );
              if (updateType == 'domain') {
                if (error.error.key == 'DomainInappropriate') {
                  errMsg = this._translateService.instant(
                    'SALES_CHANNEL.INVALID_DOMAIN'
                  );
                }
              }
              return of(
                appActions.UpdateAppWebsettingFailure({
                  payload: {
                    result: {
                      message: errMsg,
                      messageType: StoreActionsMessageTypesList.Error
                    }
                  }
                })
              );
            })
          );
      })
    )
  );

  public getListOfApps$ = createEffect(() =>
    this._actions$.pipe(
      ofType(appActions.GetListOfApps),
      withLatestFrom(this._store.select(selectListOfApps)),
      switchMap((actions) => {
        return this._appService
          .appList(actions[0].payload.appName, actions[0].payload.pageIndex)
          .pipe(
            map(
              (response) =>
                appActions.GetListOfAppsSuccess({
                  response: response as AppListResultModel
                }),
              catchError(() => EMPTY)
            )
          );
      })
    )
  );

  public loadMoreApps$ = createEffect(() =>
    this._actions$.pipe(
      ofType(appActions.LoadMoreApps),
      switchMap((actions) => {
        return this._appService
          .appList(actions.payload.appName, actions.payload.pageIndex)
          .pipe(
            map(
              (response) =>
                appActions.LoadMoreAppsSuccess({
                  response: response as AppListResultModel
                }),
              catchError(() => EMPTY)
            )
          );
      })
    )
  );

  loadCurrentAppWholeData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(appActions.LoadCurrentAppData),
      withLatestFrom(this._store.select(selectCurrentAppWholeData)),
      switchMap((actions) => {
        const appId = actions[0].appId;
        const getLanguageListOfApp$ =
          this._languageService.getLanguageListOfApp(appId);
        const getPaymentDataOfApp$ = this._paymentService.getPaymentInfo(appId);
        const getAppData$ = this._appService.getSingleApplication(appId);
        const getListOfSupportedSalesChannels$ =
          this._saleChannelsService.getSupportedChannel(appId);
        const getWebSettingDataOfCurrentApp$ =
          this._webSettingService.getWebSettings(appId);

        const requests$: Observable<any>[] = [
          getLanguageListOfApp$,
          getPaymentDataOfApp$,
          getAppData$,
          getListOfSupportedSalesChannels$,
          getWebSettingDataOfCurrentApp$
        ];

        return forkJoin(requests$).pipe(
          map((responses: any) => {
            const appData: SelectedAppDataModel = {
              languageList: responses[0].data,
              paymentInfo: responses[1],
              appDetails: responses[2].data,
              supportedSalesChannelList: responses[3],
              webSettings: responses[4].data
            };
            this._store.dispatch(loadCurrentSubscriptionInformationOfApp());
            return appActions.LoadCurrentAppDataSuccess({
              response: appData
            });
          }),
          catchError((error: any) => {
            return of(
              appActions.LoadCurrentAppDataFailure({
                error: error
              })
            );
          })
        );
      })
    )
  );

  loadPrimitiveStoreInformationEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(appActions.loadPrimitiveStoreInformation),
      withLatestFrom(this._store.select(selectPrimitiveStoreData)),
      switchMap((actions) => {
        const previousPrimitiveStoreData = actions[1];
        if (
          previousPrimitiveStoreData.countryList.length > 0 &&
          previousPrimitiveStoreData.currencyList.length > 0 &&
          previousPrimitiveStoreData.languageList.length > 0 &&
          previousPrimitiveStoreData.sectorList.length > 0
        ) {
          return of(
            appActions.loadPrimitiveStoreInformationSuccess({
              response: previousPrimitiveStoreData
            })
          );
        }
        const languageList$ = this._languageService.getLanguageList();
        const currencyList$ = this._sharedService.getCurrenciesList();
        const countryList$ = this._sharedService.getCountriesList();
        const sectorList$ = this._sharedService.getSectorsList();
        const primitiveStoreDataRequests$: Observable<any>[] = [
          languageList$,
          currencyList$,
          countryList$,
          sectorList$
        ];
        return forkJoin(primitiveStoreDataRequests$).pipe(
          map((apiResponses: any) => {
            const storeInfo: PrimitveStoreDataModel = {
              languageList: apiResponses[0].data,
              currencyList: apiResponses[1],
              countryList: apiResponses[2],
              sectorList: apiResponses[3].data
            };
            return appActions.loadPrimitiveStoreInformationSuccess({
              response: storeInfo
            });
          }),
          catchError((error) =>
            of(
              appActions.loadPrimitiveStoreInformationFailure({
                error: error
              })
            )
          )
        );
      })
    )
  );

  public createStoreEffect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(appActions.createStore),
      switchMap((actions) => {
        return this._storeService.add(actions.payload).pipe(
          map((response) => {
            const getProjectListPayload: GetAppListModel = {
              appName: '',
              pageIndex: 1,
              perPage: 8
            };
            this._store.dispatch(
              appActions.GetListOfApps({ payload: getProjectListPayload })
            );
            return appActions.createStoreSuccess();
          }),
          catchError((error) =>
            of(
              appActions.createStoreFailure({
                payload: {
                  result: {
                    message: 'APPLICATIONS.STORE_CREATION_FAILED',
                    messageType: StoreActionsMessageTypesList.Error
                  }
                }
              })
            )
          )
        );
      })
    )
  );

  constructor(
    private readonly _store: Store,
    private readonly _actions$: Actions,
    private readonly _appService: ApplicationsService,
    private readonly _languageService: LanguageService,
    private readonly _paymentService: PaymentService,
    private readonly _saleChannelsService: SalesChannelService,
    private readonly _webSettingService: WebSettingService,
    private readonly _translateService: TranslateService,
    private readonly _sharedService: SharedService,
    private readonly _storeService: StoreService
  ) {}
}
