import { HttpClient, HttpHeaders } from '@angular/common/http';
import { END_POINTS_URL } from '@shopiroller/data';
import { IUserBanStatus } from '../../data/models';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  constructor(private http: HttpClient) {}

  public filterUsers(filterData: any): Observable<any> {
    const URL = `${END_POINTS_URL.V1.USERS}/filter`;
    return this.http.post(URL, filterData);
  }

  public getClientsOrderSummary(
    appId: string,
    buyerIds: string[]
  ): Observable<any> {
    let URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/orders/buyers?`;
    buyerIds.forEach((id) => {
      URL += `buyerIds=${id}&`;
    });
    URL.replace(URL[URL.length - 1], '');
    return this.http.get(URL);
  }

  public getSingleUser(appKey: string, userId: string): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'app-key': appKey,
        apiKey: environment.apiKey
      })
    };
    const URL = `${END_POINTS_URL.V1.USERS}/${userId}`;
    return this.http.get(URL, header);
  }

  public getBuyerSummary(
    appId: string,
    perPage: number = 10,
    currentPage: number = 1,
    name: string = '',
    email: string = '',
    orderBy: 'OrderBy' | 'OrderByDescending',
    orderByOption: 'Name' | 'RegistrationDate' = 'Name'
  ): Observable<any> {
    const URL = `${END_POINTS_URL.V2.APPS_URL}/${appId}/orders/buyersSummary?perPage=${perPage}&page=${currentPage}&name=${name}&email=${email}&orderBy=${orderBy}&orderByOption=${orderByOption}`;
    return this.http.get(URL);
  }

  public setBanStatusForUser(data: IUserBanStatus): Observable<any> {
    const URL = `${END_POINTS_URL.V1.USERS}/setBanStatus`;
    return this.http.post(URL, data);
  }
}
