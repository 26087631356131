import {
  CurrentSubscriptionPackageModel,
  ISubscriptionPayment,
  SubscriptionPackageModel,
  SubscriptionTypes
} from '../../data';

import { END_POINTS_URL } from '@shopiroller/data';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  constructor(private _http: HttpClient) {}

  public migrate(appId: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.SUBSCRIPTIONS_URL}/apps/${appId}/migrate`;
    return this._http.post(URL, {});
  }

  public getPackageType(
    appId: string
  ): Observable<CurrentSubscriptionPackageModel> {
    const URL = `${END_POINTS_URL.V2.SUBSCRIPTIONS_URL}/apps/${appId}`;
    return this._http.get(URL).pipe(
      map((result: any) => {
        const apiResult = result.data as any;
        let packageType =
          Object.keys(apiResult).length > 0
            ? Object.keys(apiResult)[0]
            : 'Starter';

        const packageDuration = Object.values(apiResult).length
          ? Object.values(apiResult)[0]
          : 'month';

        const response: CurrentSubscriptionPackageModel = {
          duration: packageDuration as any,
          type: packageType as SubscriptionTypes
        };
        return response;
      })
    );
  }

  public getPermissionList(appId: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.SUBSCRIPTIONS_URL}/apps/${appId}/permissions`;
    return this._http.get(URL).pipe(map((result: any) => result.data as any));
  }

  public getAllProducts(): Observable<any> {
    const URL = `${END_POINTS_URL.V2.SUBSCRIPTIONS_URL}/products`;
    return this._http.get(URL);
  }

  public pay(appId: string, payload: ISubscriptionPayment): Observable<any> {
    const URL = `${END_POINTS_URL.V2.SUBSCRIPTIONS_URL}/apps/${appId}/pay`;
    return this._http.post(URL, [payload]);
  }

  public getPriceList(productId: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.SUBSCRIPTIONS_URL}/products/${productId}/prices`;
    return this._http.get(URL);
  }

  public getUpgradeSubscriptionPageURL(appId: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.SUBSCRIPTIONS_URL}/apps/${appId}/portal`;
    return this._http.get(URL);
  }

  public getSubscriptionPackages(): Observable<SubscriptionPackageModel[]> {
    const URL = `${END_POINTS_URL.V2.SUBSCRIPTIONS_URL}/packages`;
    return this._http
      .get(URL)
      .pipe(map((result: any) => result.data as SubscriptionPackageModel[]));
  }
}
