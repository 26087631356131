import { Routes } from '@angular/router';

export const MOBILE_SALE_CHANNEL_ROUTES: Routes = [
  {
    path: '',
    loadComponent: async () =>
      (await import('../features/index/index.component')).IndexComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    loadComponent: async () =>
      (await import('../features/index/index.component')).IndexComponent
  }
];
