import { Routes } from '@angular/router';
import { AppSumoGuard } from '@shopiroller/appsumo';

export const USER_PROFILE_ROUTES: Routes = [
  {
    path: '',
    loadComponent: async () =>
      (await import('../features/shell/shell.component')).ShellComponent,
    children: [
      {
        path: 'account',
        loadComponent: async () =>
          (
            await import(
              '../features/account-management/account-management.component'
            )
          ).AccountManagementComponent
      },
      {
        path: 'change-password',
        loadComponent: async () =>
          (
            await import(
              '../features/change-password/change-password.component'
            )
          ).ChangePasswordComponent
      },
      {
        canActivate: [AppSumoGuard],
        path: 'manage-appsumo',
        loadComponent: async () =>
          (
            await import(
              '../features/manage-app-sumo/manage-app-sumo.component'
            )
          ).ManageAppSumoComponent
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'account'
      }
    ]
  }
];

export const USER_PROFILE_ROUTES_PATH = {
  ROOT: '/user-profile',
  ACCOUNT: '/user-profile/account',
  MANAGE_APPSUMO: '/user-profile/manage-appsumo',
  CHANGE_PASSWORD: '/user-profile/change-password'
};
