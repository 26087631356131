import { Component, Input, OnInit } from '@angular/core';

import { AutoUnsubscribe } from '@shopiroller/decorators';
import { SubSink } from 'subsink';
import { selectCurrentSubscriptionPackagesInfo } from '../../data-access';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { SubscriptionFeaturesTypes } from '../../data';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
@AutoUnsubscribe()
@Component({
  selector: 'app-menu-upgrade-button',
  templateUrl: './menu-upgrade-button.component.html',
  styleUrls: ['./menu-upgrade-button.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, FontAwesomeModule, RouterModule]
})
export class MenuUpgradeButtonComponent implements OnInit {
  @Input()
  public featureName: SubscriptionFeaturesTypes;
  public faStar = faStar;
  public title: string;

  private _subs = new SubSink();
  constructor(
    private _store: Store,
    private _translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this._subs.sink = this._store
      .select(selectCurrentSubscriptionPackagesInfo)
      .subscribe((packageData) => {
        if (!packageData) return;
        const upgradePackageName = packageData?.type.toUpperCase();

        this.title = this._translateService.instant(
          'SUBSCRIPTION.REQUIRES_SUBSCRIPTION',
          {
            planType: this._translateService.instant(
              `SUBSCRIPTION.${upgradePackageName}`
            )
          }
        );
      });
  }
}
