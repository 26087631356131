import { END_POINTS_URL } from '@shopiroller/data';
import { HttpClient } from '@angular/common/http';
import { IChangePassword } from '../../models/change-passowrd.model';
import { ICheckPassword } from '../../models/check-password.model';
import { ISendVerificationCode } from '../../models/send-verification-code.model';
import { IVerifyPhoneNumber } from '../../models/verify-phone-number.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import {
  GetUserInformationModel,
  UpdateUserProfileModel,
  UserProfileFullInformationModel,
  UserProfileModel
} from '../../models/user-profile.model';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  constructor(private http: HttpClient) {}

  public getCurrentUserProfile(): Observable<UserProfileModel> {
    const URL = `${END_POINTS_URL.V2_1.PROFILE_URL}/contact`;
    return this.http
      .get(URL)
      .pipe(map((response: any) => response.data[0] as UserProfileModel));
  }

  public getUserInformation(
    payload: GetUserInformationModel
  ): Observable<UserProfileFullInformationModel> {
    const URL = `${END_POINTS_URL.V2_1.USER_URL}/getInformation/${payload.email}`;
    return this.http
      .get(URL)
      .pipe(
        map((response: any) => response.data as UserProfileFullInformationModel)
      );
  }

  public changePassword(data: IChangePassword): Observable<any> {
    const url = `${END_POINTS_URL.V2_1.AUTH_URL}/changePassword`;
    return this.http.post(url, data);
  }

  public sendVerificationCode(data: ISendVerificationCode): Observable<any> {
    const url = `${END_POINTS_URL.V2_1.PROFILE_URL}/contact`;
    return this.http.post(url, data);
  }

  public verifyPhoneNumber(data: IVerifyPhoneNumber): Observable<any> {
    const url = `${END_POINTS_URL.V2_1.PROFILE_URL}/contact/${data.code}/${data.phoneNumber}`;
    return this.http.put(url, {});
  }

  public closeAccount(reason: string): Observable<any> {
    const url = `${END_POINTS_URL.V2_1.AUTH_URL}?deletionReason=${reason}`;
    return this.http.delete(url);
  }

  public checkUserPasswordValidity(data: ICheckPassword): Observable<any> {
    const url = `${END_POINTS_URL.V2_1.AUTH_URL}/checkPassword`;
    return this.http.post(url, data);
  }

  public updateUserProfile(data: UpdateUserProfileModel): Observable<any> {
    const url = `${END_POINTS_URL.V2_1.PROFILE_URL}`;
    return this.http.put(url, data);
  }
}
