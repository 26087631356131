import { Pipe, PipeTransform } from '@angular/core';

import { environment } from 'src/environments/environment';

@Pipe({
  name: 'fetchSubDomain',
  standalone: true
})
export class FetchSubDomainPipe implements PipeTransform {
  private _panelTopLevelDomain = environment.panelBaseURL;

  transform(subdomain: string): string {
    return subdomain + `.${this._panelTopLevelDomain}`;
  }
}
