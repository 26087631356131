import { Pipe, PipeTransform } from '@angular/core';

import { environment } from 'src/environments/environment';

@Pipe({
  name: 'slugLink',
  standalone: true
})
export class SlugLinkPipe implements PipeTransform {
  transform(slug: string): string {
    const shopilinkBaseURL = environment.shopilinkBaseURL;
    return slug.startsWith(`${shopilinkBaseURL}/`)
      ? slug
      : shopilinkBaseURL + '/' + slug;
  }
}
