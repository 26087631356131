import { HttpClient, HttpHeaders } from '@angular/common/http';

import { END_POINTS_URL } from '@shopiroller/data';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { IManageVariantModel } from '../models/variant.model';

@Injectable({
  providedIn: 'root',
})
export class VariantService {
  constructor(private http: HttpClient) {}

  public addNewVariant(
    appId: string,
    payload: IManageVariantModel
  ): Observable<any> {
    const url = `${END_POINTS_URL.V2.APPS_URL}/${appId}/variation-groups`;
    return this.http.post<IManageVariantModel>(url, payload);
  }

  public getAllVariants(
    appId: string,
    searchedName: string = '',
    currentPage: number,
    orderByType: 'OrderBy' | 'OrderByDescending' = 'OrderByDescending',
    orderByOption: 'CreateDate' | 'Name' = 'CreateDate',
    pageSize = 10,
    iso2LanguageName = ''
  ): Observable<any> {
    let url = `${END_POINTS_URL.V2.APPS_URL}/${appId}/variation-groups?name=${searchedName}&orderBy=${orderByType}&orderByOption=${orderByOption}&perPage=${pageSize}&page=${currentPage}`;
    if (iso2LanguageName != '') {
      url += `&iso2LanguageName=${iso2LanguageName}`;
    }
    return this.http.get(url);
  }

  public getSingleVariant(
    appId: string,
    variationGroupId: string
  ): Observable<any> {
    const url = `${END_POINTS_URL.V2.APPS_URL}/${appId}/variation-groups/${variationGroupId}`;
    return this.http.get(url);
  }

  public updateVariant(
    appId: string,
    variationGroupId: string,
    payload: IManageVariantModel
  ): Observable<any> {
    const url = `${END_POINTS_URL.V2.APPS_URL}/${appId}/variation-groups/${variationGroupId}`;
    return this.http.put<IManageVariantModel>(url, payload);
  }

  public deleteVariant(
    appId: string,
    variationGroupId: string
  ): Observable<any> {
    const url = `${END_POINTS_URL.V2.APPS_URL}/${appId}/variation-groups/${variationGroupId}`;
    return this.http.delete(url);
  }

  public getVariationGroup(
    appId: string,
    variationGroupId: string
  ): Observable<any> {
    const url = `${END_POINTS_URL.V2.APPS_URL}/${appId}/variation-groups/${variationGroupId}`;
    return this.http.get(url).pipe(map((result: any) => result.data));
  }
}
